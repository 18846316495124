import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlarmsComponent } from './alarms.component';
import { AlarmShelveFormComponent } from '@components/alarms/alarm-shelve-form/alarm-shelve-form.component';
import { AlarmTimerComponent } from '@components/alarms/alarm-timer/alarm-timer.component';
import { AlarmsTableViewComponent } from '@components/alarms/alarms-table-view/alarms-table-view.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/material.module';
import { SharedModule } from '@app/shared/shared.module';
import { BlockUIModule } from 'ng-block-ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlarmDescriptionComponent } from '@components/alarms/alarm-description/alarm-description.component';

@NgModule({
  declarations: [
    AlarmsComponent,
    AlarmShelveFormComponent,
    AlarmTimerComponent,
    AlarmsTableViewComponent,
    AlarmDescriptionComponent,
  ],
  imports: [
    RouterModule,
    MaterialModule,
    CommonModule,
    SharedModule,
    BlockUIModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    AlarmsComponent,
    AlarmShelveFormComponent,
    AlarmTimerComponent,
    AlarmsTableViewComponent,
    AlarmDescriptionComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AlarmsModule {}
