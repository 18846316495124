import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { AttributesInfo } from '@app/models/table-items';
import {
  alarmDescriptionColumns,
  alarmDescriptionData,
  displayedDescriptionColumnsInList,
} from '@app/utils/constants/alarm-constants';

@Component({
  selector: 'app-alarm-description',
  templateUrl: './alarm-description.component.html',
  styleUrl: './alarm-description.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class AlarmDescriptionComponent implements OnInit {
  @Output() closeEvent = new EventEmitter<void>();
  sortState: Sort = { active: 'type', direction: 'asc' };
  displayedColumns: string[] = displayedDescriptionColumnsInList;
  listOfTableColumns: AttributesInfo[] = alarmDescriptionColumns;
  data = alarmDescriptionData;
  title = 'Alarm Description';

  ngOnInit(): void {
    this.data = [...this.data.sort()];
  }

  onClose() {
    this.closeEvent.emit();
  }
}
