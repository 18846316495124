import { elexant5010i } from '@app/utils/configurator-meta/elexant5010i-meta';
import { updateBooleanAttributes } from '@utils/configurator-util';
import {
  enableAttributes,
  latchAttributes,
  modeAttributes,
  temperatureAttributes,
} from '@utils/constants/configurator-constants';
import { Configurable } from './form-fields';

export const elexant5010iConfigurablesForForms: { [key: string]: Configurable[] } = {
  ControlTemperatureELEXANT5010I: [
    elexant5010i.htc_0_control_temperature_setpoint as Configurable,
    elexant5010i.htc_0_high_limit_cutout_setpoint as Configurable,
    elexant5010i.htc_0_low_limit_cutout_deadband as Configurable,
    elexant5010i.htc_0_temperature_control_mode as Configurable,
    elexant5010i.htc_0_temperature_fail_mode as Configurable,
    elexant5010i.htc_0_temperature_fail_mode_percentage as Configurable,
  ],
  ControlTemperatureHighAlarmELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_control_temperature_high_mask as Configurable, enableAttributes),
    elexant5010i.htc_0_control_temperature_high_alarm_setpoint as Configurable,
    elexant5010i.htc_0_control_temperature_high_alarm_filter as Configurable,
  ],

  ControlTemperatureLowAlarmELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_control_temperature_low_mask as Configurable, enableAttributes),
    elexant5010i.htc_0_control_temperature_low_alarm_setpoint as Configurable,
    elexant5010i.htc_0_control_temperature_low_alarm_filter as Configurable,
  ],
  LowLimitCutoutELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_low_limit_cutout_mask as Configurable, enableAttributes),
    // Same label as that of low_limit_cutout_mask will lead to error while editing
    // updateBooleanAttributes(elexant5010i.htc_0_low_limit_cutout_latch as Configurable, latchAttributes),
    // elexant5010i.htc_0_low_limit_cutout_setpoint as Configurable,
  ],

  ControlModesELEXANT5010I: [
    elexant5010i.htc_0_switch_control_mode as Configurable,
    elexant5010i.htc_0_deadband as Configurable,
    elexant5010i.htc_0_proportional_band as Configurable,
    elexant5010i.htc_0_pasc_min_pipe_size as Configurable,
    elexant5010i.htc_0_pasc_min_ambient_temperature as Configurable,
    elexant5010i.htc_0_pasc_power_adjust as Configurable,
    elexant5010i.htc_0_proportional_ambient_cycle_time as Configurable,
  ],
  LocalRTD1ELEXANT5010I: [
    elexant5010i.temperature_0_type as Configurable,
    elexant5010i.temperature_0_lead_resistance as Configurable,
    elexant5010i.temperature_0_tag as Configurable,
    updateBooleanAttributes(elexant5010i.temperature_0_failure_mask as Configurable, enableAttributes),
  ],

  LocalRTD1HighAlarmELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.temperature_0_high_mask as Configurable, enableAttributes),
    elexant5010i.temperature_0_high_alarm_setpoint as Configurable,
    elexant5010i.temperature_0_high_alarm_filter as Configurable,
  ],

  LocalRTD1LowAlarmELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.temperature_0_low_mask as Configurable, enableAttributes),
    elexant5010i.temperature_0_low_alarm_setpoint as Configurable,
    elexant5010i.temperature_0_low_alarm_filter as Configurable,
  ],

  LocalRTD2ELEXANT5010I: [
    elexant5010i.temperature_1_type as Configurable,
    elexant5010i.temperature_1_lead_resistance as Configurable,
    elexant5010i.temperature_1_tag as Configurable,
    updateBooleanAttributes(elexant5010i.temperature_1_failure_mask as Configurable, enableAttributes),
  ],

  LocalRTD2HighAlarmELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.temperature_1_high_mask as Configurable, enableAttributes),
    elexant5010i.temperature_1_high_alarm_setpoint as Configurable,
    elexant5010i.temperature_1_high_alarm_filter as Configurable,
  ],

  LocalRTD2LowAlarmELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.temperature_0_low_mask as Configurable, enableAttributes),
    elexant5010i.temperature_1_low_alarm_setpoint as Configurable,
    elexant5010i.temperature_1_low_alarm_filter as Configurable,
  ],
  TemperatureSource1ELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_0_control_enabled as Configurable, modeAttributes),
    updateBooleanAttributes(
      elexant5010i.htc_0_temperature_source_0_high_cutout_enabled as Configurable,
      modeAttributes,
    ),
    elexant5010i.htc_0_temperature_source_0_path as Configurable,
  ],

  TemperatureSource1FailureELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_0_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_0_failure_latch as Configurable, latchAttributes),
  ],

  TemperatureSource2ELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_1_control_enabled as Configurable, modeAttributes),
    updateBooleanAttributes(
      elexant5010i.htc_0_temperature_source_1_high_cutout_enabled as Configurable,
      modeAttributes,
    ),
    elexant5010i.htc_0_temperature_source_1_path as Configurable,
  ],

  TemperatureSource2FailureELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_1_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_1_failure_latch as Configurable, latchAttributes),
  ],

  TemperatureSource3ELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_2_control_enabled as Configurable, enableAttributes),
    updateBooleanAttributes(
      elexant5010i.htc_0_temperature_source_2_high_cutout_enabled as Configurable,
      modeAttributes,
    ),
    elexant5010i.htc_0_temperature_source_2_path as Configurable,
  ],

  TemperatureSource3FailureELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_2_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_2_failure_latch as Configurable, latchAttributes),
  ],

  TemperatureSource4ELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_3_control_enabled as Configurable, modeAttributes),
    updateBooleanAttributes(
      elexant5010i.htc_0_temperature_source_3_high_cutout_enabled as Configurable,
      modeAttributes,
    ),
    elexant5010i.htc_0_temperature_source_3_path as Configurable,
  ],

  TemperatureSource4FailureELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_3_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_3_failure_latch as Configurable, latchAttributes),
  ],

  TemperatureSource5ELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_4_control_enabled as Configurable, modeAttributes),
    updateBooleanAttributes(
      elexant5010i.htc_0_temperature_source_4_high_cutout_enabled as Configurable,
      modeAttributes,
    ),
    elexant5010i.htc_0_temperature_source_4_path as Configurable,
  ],

  TemperatureSource5FailureELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_4_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_4_failure_latch as Configurable, latchAttributes),
  ],

  TemperatureSource6ELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_5_control_enabled as Configurable, modeAttributes),
    updateBooleanAttributes(
      elexant5010i.htc_0_temperature_source_5_high_cutout_enabled as Configurable,
      modeAttributes,
    ),
    elexant5010i.htc_0_temperature_source_5_path as Configurable,
  ],

  TemperatureSource6FailureELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_5_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_5_failure_latch as Configurable, latchAttributes),
  ],

  TemperatureSource7ELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_6_control_enabled as Configurable, modeAttributes),
    updateBooleanAttributes(
      elexant5010i.htc_0_temperature_source_6_high_cutout_enabled as Configurable,
      modeAttributes,
    ),
    elexant5010i.htc_0_temperature_source_6_path as Configurable,
  ],

  TemperatureSource7FailureELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_6_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_6_failure_latch as Configurable, latchAttributes),
  ],
  TemperatureSource8ELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_7_control_enabled as Configurable, modeAttributes),
    updateBooleanAttributes(
      elexant5010i.htc_0_temperature_source_7_high_cutout_enabled as Configurable,
      modeAttributes,
    ),
    elexant5010i.htc_0_temperature_source_7_path as Configurable,
  ],

  TemperatureSource8FailureELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_7_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_temperature_source_7_failure_latch as Configurable, latchAttributes),
  ],

  DisplayUnitsInELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.console_0_temperature_units as Configurable, temperatureAttributes),
  ],

  FailuresandTripAlarmsELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.device_reset_mask as Configurable, enableAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_load_shed_source_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_ground_fault_transformer_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_switch_0_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_high_limit_cutout_mask as Configurable, enableAttributes),
  ],

  ContactorCycleCountAlarmELEXANT5010I: [
    updateBooleanAttributes(
      elexant5010i.htc_0_contactor_cycle_count_limit_exceeded_latch as Configurable,
      latchAttributes,
    ),
    elexant5010i.htc_0_contactor_cycle_count_setpoint as Configurable,
    updateBooleanAttributes(
      elexant5010i.htc_0_contactor_cycle_count_limit_exceeded_mask as Configurable,
      enableAttributes,
    ),
  ],

  HeaterTimeAlarmELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_heater_time_limit_exceeded_latch as Configurable, latchAttributes),
    elexant5010i.htc_0_heater_time_setpoint as Configurable,
    updateBooleanAttributes(elexant5010i.htc_0_heater_time_limit_exceeded_mask as Configurable, enableAttributes),
  ],

  AlarmOutputELEXANT5010I: [
    elexant5010i.alarm_0_mode as Configurable,
    elexant5010i.alarm_0_toggle_time as Configurable,
  ],

  TemperatureAlarmsELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_control_temperature_high_latch as Configurable, modeAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_control_temperature_low_latch as Configurable, latchAttributes),
    elexant5010i.htc_0_non_latching_high_temperature_cutout_alarms as Configurable,
    elexant5010i.htc_0_non_latching_temperature_alarms as Configurable,
    updateBooleanAttributes(elexant5010i.temperature_0_failure_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.temperature_0_high_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.temperature_0_low_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.temperature_1_failure_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.temperature_1_low_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.temperature_1_high_latch as Configurable, latchAttributes),
  ],

  ControlTemperatureFailureAlarmELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_control_temperature_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_control_temperature_failure_latch as Configurable, latchAttributes),
  ],

  ElectricalAlarmsELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_load_current_high_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_load_current_low_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_ground_fault_current_high_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_line_voltage_high_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_tracing_resistance_0_high_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_tracing_resistance_0_low_latch as Configurable, latchAttributes),
  ],

  AlarmLatchSettingsElectricalAlarmsELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_load_current_high_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_load_current_low_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_ground_fault_current_high_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_line_voltage_high_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_line_voltage_low_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_tracing_resistance_0_high_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_tracing_resistance_0_low_latch as Configurable, latchAttributes),
  ],

  OtherAlarmsELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_switch_0_failure_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.limiter_0_communication_failure_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.limiter_0_trip_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_load_shed_source_failure_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_high_limit_cutout_latch as Configurable, latchAttributes),
  ],

  LimiterCommunicationFailureAlarmELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.limiter_0_temperature_sensor_failure_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.limiter_0_temperature_sensor_failure_mask as Configurable, enableAttributes),
  ],

  ConfigurationLostAlarmFactoryELEXANT5010I: [
    updateBooleanAttributes(
      elexant5010i.nvram_0_factory_configuration_data_lost_latch as Configurable,
      latchAttributes,
    ),
    updateBooleanAttributes(
      elexant5010i.nvram_0_factory_configuration_data_lost_mask as Configurable,
      enableAttributes,
    ),
  ],

  ConfigurationLostAlarmUserELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.nvram_0_user_configuration_data_lost_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant5010i.nvram_0_user_configuration_data_lost_mask as Configurable, enableAttributes),
  ],

  HeatingCableResistanceAlarmsELEXANT5010I: [elexant5010i.htc_0_tracing_resistance_0_nominal as Configurable],

  HeatingCableResistanceAlarmsHighAlarmELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_tracing_resistance_0_high_mask as Configurable, enableAttributes),
    elexant5010i.htc_0_tracing_resistance_0_high_deviation as Configurable,
    elexant5010i.htc_0_tracing_resistance_0_high_alarm_filter as Configurable,
  ],

  HeatingCableResistanceAlarmsLowAlarmELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_tracing_resistance_0_low_mask as Configurable, enableAttributes),
    elexant5010i.htc_0_tracing_resistance_0_low_deviation as Configurable,
    elexant5010i.htc_0_tracing_resistance_0_low_alarm_filter as Configurable,
  ],

  VoltageAlarmsHighAlarmELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_line_voltage_high_mask as Configurable, enableAttributes),
    elexant5010i.htc_0_voltage_high_alarm_setpoint as Configurable,
    elexant5010i.htc_0_voltage_high_alarm_filter as Configurable,
  ],

  VoltageAlarmsLowAlarmELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_line_voltage_low_mask as Configurable, enableAttributes),
    elexant5010i.htc_0_voltage_low_alarm_setpoint as Configurable,
    elexant5010i.htc_0_voltage_low_alarm_filter as Configurable,
  ],

  CurrentAlarmsHighAlarmELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_load_current_high_mask as Configurable, enableAttributes),
    elexant5010i.htc_0_load_current_high_alarm_setpoint as Configurable,
    elexant5010i.htc_0_load_current_high_alarm_filter as Configurable,
  ],

  CurrentAlarmsLowAlarmELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_load_current_low_mask as Configurable, enableAttributes),
    elexant5010i.htc_0_load_current_low_alarm_setpoint as Configurable,
    elexant5010i.htc_0_load_current_low_alarm_filter as Configurable,
  ],

  SafetyTemperatureLimiterAlarmsELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.limiter_0_communication_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(elexant5010i.limiter_0_trip_mask as Configurable, enableAttributes),
  ],

  GroundFaultCurrentELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_ground_fault_transformer_failure_latch as Configurable, latchAttributes),
    //Not available in DB
    // elexant5010i.htc_0_energize as Configurable,
  ],

  GroundFaultCurrentHighAlarmELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_ground_fault_current_high_mask as Configurable, enableAttributes),
    elexant5010i.htc_0_ground_fault_current_high_alarm_setpoint as Configurable,
    elexant5010i.htc_0_ground_fault_current_high_alarm_filter as Configurable,
  ],

  GroundFaultTripELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_ground_fault_trip_mask as Configurable, enableAttributes),
    elexant5010i.htc_0_ground_fault_current_trip_setpoint as Configurable,
    updateBooleanAttributes(elexant5010i.htc_0_ground_fault_trip_latch as Configurable, latchAttributes),
  ],

  SafetyTemperatureLimiterTripSetpointELEXANT5010I: [
    elexant5010i.limiter_0_cutout_setpoint as Configurable,
    // updateBooleanAttributes(elexant5010i.limiter_0_force_trip as Configurable, modeAttributes),
    // updateBooleanAttributes(
    //   elexant5010i.limiter_0_control_temperature_min_max_values_reset as Configurable,
    //   modeAttributes
    // ),
    // updateBooleanAttributes(elexant5010i.limiter_0_reset as Configurable, modeAttributes),
  ],

  LoadSheddingELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.htc_0_load_shed_enabled as Configurable, modeAttributes),
    updateBooleanAttributes(elexant5010i.htc_0_load_shed_fail_safe_enabled as Configurable, modeAttributes),
    elexant5010i.htc_0_load_shed_communications_timeout as Configurable,
  ],

  AutoCycleELEXANT5010I: [elexant5010i.htc_0_auto_cycle_interval as Configurable],

  CommunicationSettingsELEXANT5010I: [
    elexant5010i.serial_0_baud_rate as Configurable,
    elexant5010i.serial_0_data_bits as Configurable,
    elexant5010i.serial_0_frame_type as Configurable,
    elexant5010i.serial_0_modbus_address as Configurable,
    elexant5010i.serial_0_parity as Configurable,
    elexant5010i.serial_0_stop_bits as Configurable,
    elexant5010i.serial_0_tx_delay as Configurable,
  ],

  CommunicationFailureELEXANT5010I: [
    updateBooleanAttributes(elexant5010i.agent_communication_failure_mask as Configurable, enableAttributes),
    elexant5010i.agent_communication_failure_limit as Configurable,
  ],

  DeviceSettingsELEXANT5010I: [
    // updateBooleanAttributes(elexant5010i.device_load_user_configuration_defaults as Configurable, modeAttributes),
    updateBooleanAttributes(elexant5010i.device_reset_latch as Configurable, latchAttributes),
    elexant5010i.device_tag as Configurable,
    {
      article: 'device_type',
      label: 'Device type',
      unit: 'None',
      type: 'constant',
      disabled: true,
      group: 'Misc Settings\\Device Information',
    } as Configurable,
    {
      article: 'device_firmware_version',
      label: 'Firmware version',
      unit: 'None',
      type: 'constant',
      disabled: true,
      group: 'Misc Settings\\Device Information',
    } as Configurable,
    {
      article: 'device_serial',
      label: 'Serial number',
      unit: 'None',
      type: 'constant',
      disabled: true,
      group: 'Misc Settings\\Device Information',
    } as Configurable,
  ],
};
