<mat-expansion-panel class="panel-style" [expanded]="positionIndex === 0">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <span class="font-med-bold">{{ formHeader }}</span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <form [formGroup]="configurationFormGroup">
    <div class="display-config-attributes" [ngClass]="isEditable ? 'col-flex' : 'row-flex'">
      @for (field of formFields; track $index) {
        <div [ngClass]="isEditable ? 'form-field row-flex' : 'attribute-container col-flex flex-between'">
          <span class="font-med" [ngClass]="isEditable ? 'field-label' : 'cl-grey'" id="{{ field.article }}Title">{{
            field.label | titlecase
          }}</span>
          <div [ngClass]="{ 'field-input': isEditable }">
            <ng-container
              [ngTemplateOutlet]="
                isEditable
                  ? field.type === 'choice'
                    ? dropDownTemplate
                    : field.type === 'range' || field.type === 'int'
                      ? numberFieldTemplate
                      : otherFieldTemplate
                  : infoTemplate
              "></ng-container>
          </div>

          <ng-template #dropDownTemplate>
            <mat-form-field appearance="outline">
              <mat-select
                [id]="field.article"
                [formControlName]="field.article"
                class="form-field-font"
                matTooltip="{{ getToolTip(field) }}"
                [placeholder]="'0000'">
                @for (option of field.options; track $index) {
                  <mat-option
                    [id]="field.article + option.displayText | camelcase"
                    [value]="option.value"
                    [disabled]="field.article === 'digital_input_0_path' && option.key === 0"
                    matTooltip="{{ option.displayText }}">
                    {{ option.displayText }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </ng-template>

          <ng-template #numberFieldTemplate>
            <mat-form-field appearance="outline">
              <input
                matInput
                [formControlName]="field.article"
                matTooltip="{{ getToolTip(field) }}"
                [id]="field.article"
                type="number" />
              <span id="{{ field.article }}Unit" matTextSuffix>{{ field.unit }}</span>
            </mat-form-field>
            @if (
              configurationFormGroup.controls[field.article].errors?.required ||
              configurationFormGroup.controls[field.article].errors?.min ||
              configurationFormGroup.controls[field.article].errors?.max
            ) {
              <mat-error id="{{ field.article }}Error"
                >Numerical value must be between {{ field.min }} and {{ field.max }}</mat-error
              >
            }
          </ng-template>

          <ng-template #otherFieldTemplate>
            <mat-form-field appearance="outline">
              <input
                matInput
                [formControlName]="field.article"
                matTooltip="{{ getToolTip(field) }}"
                [id]="field.article"
                (keypress)="onKeyPress($event, field.unit)"
                type="text" />
              <span id="{{ field.article }}Unit" matTextSuffix>{{ field.unit }}</span>
            </mat-form-field>
            @if (
              configurationFormGroup.controls[field.article].errors?.required ||
              configurationFormGroup.controls[field.article].errors?.minlength ||
              configurationFormGroup.controls[field.article].errors?.maxlength
            ) {
              <mat-error id="{{ field.article }}Error"
                >Text length must be between {{ field.min }} and {{ field.max }}</mat-error
              >
            } @else if (configurationFormGroup.controls[field.article].errors?.notUnique) {
              <mat-error id="{{ field.article }}Error">Enter unique Temperature Tag</mat-error>
            }
          </ng-template>

          <ng-template #infoTemplate>
            <span class="font-med"
              >{{ field.type === 'choice' ? getDisplayText(field) : configurationFormGroup.get(field.article)?.value }}
              @if (field.unit && field.unit !== 'None') {
                {{ field.unit }}
              }
            </span>
          </ng-template>
        </div>
      }
    </div>
  </form>
</mat-expansion-panel>
