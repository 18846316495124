import { htc910 } from '@app/utils/configurator-meta/htc910-meta';
import { updateBooleanAttributes } from '@app/utils/configurator-util';
import {
  modeAttributes,
  latchAttributes,
  enableAttributes,
  timeAttributes,
  externalInputSourceAttributes,
} from '@app/utils/constants/configurator-constants';
import { Configurable } from './form-fields';

export const htc910ConfigurablesForForms: { [key: string]: Configurable[] } = {
  ControlTemperatureHTC910: [
    htc910.htc_0_control_temperature_setpoint as Configurable,
    htc910.htc_0_temperature_control_mode as Configurable,
    updateBooleanAttributes(htc910.htc_0_temperature_fail_mode as Configurable, modeAttributes),
  ],
  ControlModesHTC910: [
    htc910.htc_0_switch_control_mode as Configurable,
    htc910.htc_0_deadband as Configurable,
    htc910.htc_0_proportional_band as Configurable,
    htc910.htc_0_proportional_ambient_cycle_time as Configurable,
  ],
  TemperatureSensorsHTC910: [
    htc910.temperature_0_type as Configurable,
    htc910.temperature_0_lead_resistance as Configurable,
    htc910.temperature_1_type as Configurable,
    htc910.temperature_1_lead_resistance as Configurable,
  ],
  MiscellaneousHTC910: [
    htc910.console_0_control_temperature_setpoint_upper_bound as Configurable,
    htc910.console_0_control_temperature_setpoint_lower_bound as Configurable,
    updateBooleanAttributes(htc910.htc_0_non_latching_temperature_alarms as Configurable, latchAttributes),
  ],
  HighTS1TemperatureHTC910: [
    updateBooleanAttributes(htc910.temperature_0_high_mask as Configurable, enableAttributes),
    htc910.temperature_0_high_alarm_setpoint as Configurable,
    htc910.temperature_0_high_alarm_filter as Configurable,
  ],
  LowTS1TemperatureHTC910: [
    updateBooleanAttributes(htc910.temperature_0_low_mask as Configurable, enableAttributes),
    htc910.temperature_0_low_alarm_setpoint as Configurable,
    htc910.temperature_0_low_alarm_filter as Configurable,
  ],
  TS1FailureHTC910: [updateBooleanAttributes(htc910.temperature_0_failure_mask as Configurable, enableAttributes)],
  HighLimitTemperatureCutOut1HTC910: [
    updateBooleanAttributes(htc910.temperature_0_high_cutout_enabled as Configurable, enableAttributes),
  ],
  HighTS2TemperatureHTC910: [
    updateBooleanAttributes(htc910.temperature_1_high_mask as Configurable, enableAttributes),
    htc910.temperature_1_high_alarm_setpoint as Configurable,
  ],
  LowTS2TemperatureHTC910: [
    updateBooleanAttributes(htc910.temperature_1_low_mask as Configurable, enableAttributes),
    htc910.temperature_1_low_alarm_setpoint as Configurable,
  ],
  TS2FailureHTC910: [updateBooleanAttributes(htc910.temperature_1_failure_mask as Configurable, enableAttributes)],
  HighLimitTemperatureCutOut2HTC910: [
    updateBooleanAttributes(htc910.temperature_1_high_cutout_enabled as Configurable, enableAttributes),
  ],
  TSControlHTC910: [
    updateBooleanAttributes(htc910.htc_0_control_temperature_failure_mask as Configurable, enableAttributes),
  ],
  HTCResetHTC910: [updateBooleanAttributes(htc910.device_reset_mask as Configurable, enableAttributes)],
  EEROMDataFailureHTC910: [
    updateBooleanAttributes(htc910.nvram_0_total_failure_mask as Configurable, enableAttributes),
  ],
  SwitchLimitingHTC910: [
    updateBooleanAttributes(htc910.htc_0_switch_0_limiting_mask as Configurable, enableAttributes),
  ],
  ContactorCountHTC910: [
    updateBooleanAttributes(htc910.htc_0_contactor_cycle_count_limit_exceeded_mask as Configurable, enableAttributes),
    htc910.htc_0_contactor_cycle_count_setpoint as Configurable,
  ],
  FlashAlarmOutputHTC910: [updateBooleanAttributes(htc910.alarm_0_mode as Configurable, modeAttributes)],
  HighLoadCurrentHTC910: [
    updateBooleanAttributes(htc910.htc_0_load_current_high_mask as Configurable, enableAttributes),
    htc910.htc_0_load_current_high_alarm_setpoint as Configurable,
    htc910.htc_0_load_current_high_alarm_filter as Configurable,
  ],
  LowLoadCurrentHTC910: [
    updateBooleanAttributes(htc910.htc_0_load_current_low_mask as Configurable, enableAttributes),
    htc910.htc_0_load_current_low_alarm_setpoint as Configurable,
  ],
  HighGroundFaultCurrentHTC910: [
    updateBooleanAttributes(htc910.htc_0_ground_fault_current_high_mask as Configurable, enableAttributes),
    htc910.htc_0_ground_fault_current_high_alarm_setpoint as Configurable,
  ],
  GroundFaultTripCurrentHTC910: [
    updateBooleanAttributes(htc910.htc_0_ground_fault_trip_mask as Configurable, enableAttributes),
  ],
  HighVoltageHTC910: [
    updateBooleanAttributes(htc910.htc_0_line_voltage_high_mask as Configurable, enableAttributes),
    htc910.htc_0_voltage_high_alarm_setpoint as Configurable,
    htc910.htc_0_voltage_high_alarm_filter as Configurable,
  ],
  LowVoltageHTC910: [
    updateBooleanAttributes(htc910.htc_0_line_voltage_low_mask as Configurable, enableAttributes),
    htc910.htc_0_voltage_low_alarm_setpoint as Configurable,
    htc910.htc_0_voltage_low_alarm_filter as Configurable,
  ],
  HighResistanceHTC910: [
    updateBooleanAttributes(htc910.htc_0_tracing_resistance_0_high_mask as Configurable, enableAttributes),
    htc910.htc_0_tracing_resistance_0_high_deviation as Configurable,
    htc910.htc_0_tracing_resistance_0_high_alarm_filter as Configurable,
  ],
  LowResistanceHTC910: [
    updateBooleanAttributes(htc910.htc_0_tracing_resistance_0_low_mask as Configurable, enableAttributes),
    htc910.htc_0_tracing_resistance_0_low_deviation as Configurable,
    htc910.htc_0_tracing_resistance_0_low_alarm_filter as Configurable,
  ],
  NominalTracingResistanceHTC910: [htc910.htc_0_tracing_resistance_0_nominal as Configurable],
  SwitchRatingHTC910: [htc910.htc_0_ssr_rating as Configurable],
  CBCurrentHTC910: [htc910.htc_0_circuit_breaker_0_current_rating as Configurable],
  MaximumPowerHTC910: [htc910.htc_0_maximum_power_setpoint as Configurable],
  VoltageTurnsRatioHTC910: [htc910.htc_0_voltage_turns_ratio as Configurable],
  CurrentTurnsRatioHTC910: [htc910.htc_0_current_turns_ratio as Configurable],
  LoadSheddingEnableHTC910: [updateBooleanAttributes(htc910.htc_0_load_shed_enabled as Configurable, enableAttributes)],
  FailSafeModeHTC910: [
    updateBooleanAttributes(htc910.htc_0_load_shed_fail_safe_enabled as Configurable, enableAttributes),
  ],
  LoadSheddingInput1HTC910: [
    updateBooleanAttributes(htc910.htc_0_load_shed_zone_0_enabled as Configurable, enableAttributes),
  ],
  LoadSheddingInput2HTC910: [
    updateBooleanAttributes(htc910.htc_0_load_shed_zone_1_enabled as Configurable, enableAttributes),
  ],
  LoadSheddingInput3HTC910: [
    updateBooleanAttributes(htc910.htc_0_load_shed_zone_2_enabled as Configurable, enableAttributes),
  ],
  LoadSheddingInput4HTC910: [
    updateBooleanAttributes(htc910.htc_0_load_shed_zone_3_enabled as Configurable, enableAttributes),
  ],
  ExternalInputSettingsHTC910: [
    htc910.htc_0_external_input_configuration as Configurable,
    updateBooleanAttributes(htc910.htc_0_override_source as Configurable, externalInputSourceAttributes),
  ],
  AutoCycleHTC910: [
    updateBooleanAttributes(htc910.htc_0_auto_cycle_enabled as Configurable, enableAttributes),
    htc910.htc_0_auto_cycle_interval as Configurable,
    updateBooleanAttributes(htc910.htc_0_auto_cycle_interval_unit as Configurable, timeAttributes),
  ],
};
