import { Configurable } from '@models/configurator-models/form-fields';
import { elexant40x0 } from '@utils/configurator-meta/elexant40x0-meta';
import { updateBooleanAttributes } from '@utils/configurator-util';
import { enableAttributes, latchAttributes, modeAttributes } from '@utils/constants/configurator-constants';

export const elexant40x0ConfigurablesForForms: { [key: string]: Configurable[] } = {
  'ControlTemperature4010I/4020I': [
    elexant40x0.htc_0_temperature_control_mode as Configurable,
    elexant40x0.htc_0_temperature_fail_mode as Configurable,
    elexant40x0.htc_0_control_temperature_setpoint as Configurable,
    elexant40x0.htc_0_temperature_fail_mode_percentage as Configurable,
    updateBooleanAttributes(elexant40x0.htc_0_control_temperature_failure_latch as Configurable, latchAttributes),
    elexant40x0.htc_0_low_limit_cutout_deadband as Configurable,
  ],
  'ControlTemperatureHighAlarm4010I/4020I': [
    updateBooleanAttributes(elexant40x0.htc_0_control_temperature_high_mask as Configurable, modeAttributes),
    elexant40x0.htc_0_control_temperature_high_alarm_setpoint as Configurable,
    elexant40x0.htc_0_control_temperature_high_alarm_filter as Configurable,
    updateBooleanAttributes(elexant40x0.htc_0_control_temperature_high_latch as Configurable, latchAttributes),
  ],
  'ControlTemperatureLowAlarm4010I/4020I': [
    updateBooleanAttributes(elexant40x0.htc_0_control_temperature_low_mask as Configurable, modeAttributes),
    elexant40x0.htc_0_control_temperature_low_alarm_setpoint as Configurable,
    elexant40x0.htc_0_control_temperature_low_alarm_filter as Configurable,
    updateBooleanAttributes(elexant40x0.htc_0_control_temperature_low_latch as Configurable, latchAttributes),
  ],
  'HighLimitCutout4010I/4020I': [
    updateBooleanAttributes(elexant40x0.htc_0_high_limit_cutout_mask as Configurable, modeAttributes),
    elexant40x0.htc_0_high_limit_cutout_setpoint as Configurable,
    updateBooleanAttributes(elexant40x0.htc_0_high_limit_cutout_latch as Configurable, latchAttributes),
  ],
  'LowLimitCutout4010I/4020I': [
    updateBooleanAttributes(elexant40x0.htc_0_low_limit_cutout_mask as Configurable, modeAttributes),
    elexant40x0.htc_0_low_limit_cutout_setpoint as Configurable,
    updateBooleanAttributes(elexant40x0.htc_0_low_limit_cutout_latch as Configurable, latchAttributes),
  ],
  'ControlModes4010I/4020I': [
    elexant40x0.htc_0_output_switch_type as Configurable,
    elexant40x0.htc_0_switch_control_mode as Configurable,
    elexant40x0.htc_0_deadband as Configurable,
    updateBooleanAttributes(elexant40x0.htc_0_contactor_polarity_under_ssr_active as Configurable, modeAttributes),
  ],
  'TS14010I/4020I': [
    elexant40x0.temperature_0_type as Configurable,
    elexant40x0.temperature_0_lead_resistance as Configurable,
    elexant40x0.temperature_0_tag as Configurable,
    elexant40x0.temperature_0_high_alarm_setpoint as Configurable,
    elexant40x0.temperature_0_high_alarm_filter as Configurable,
    elexant40x0.temperature_0_low_alarm_setpoint as Configurable,
    elexant40x0.temperature_0_low_alarm_filter as Configurable,
  ],
  'TS1FailureAlarm4010I/4020I': [
    updateBooleanAttributes(elexant40x0.temperature_0_failure_mask as Configurable, modeAttributes),
    updateBooleanAttributes(elexant40x0.temperature_0_failure_latch as Configurable, latchAttributes),
  ],
  'TS1HighAlarm4010I/4020I': [
    updateBooleanAttributes(elexant40x0.temperature_0_high_mask as Configurable, modeAttributes),
    updateBooleanAttributes(elexant40x0.temperature_0_high_latch as Configurable, latchAttributes),
  ],
  'TS1LowAlarm4010I/4020I': [
    updateBooleanAttributes(elexant40x0.temperature_0_low_mask as Configurable, modeAttributes),
    updateBooleanAttributes(elexant40x0.temperature_0_low_latch as Configurable, latchAttributes),
  ],
  'TS24010I/4020I': [
    elexant40x0.temperature_1_type as Configurable,
    elexant40x0.temperature_1_lead_resistance as Configurable,
    elexant40x0.temperature_1_tag as Configurable,
  ],
  'TS2FailureAlarm4010I/4020I': [
    updateBooleanAttributes(elexant40x0.temperature_1_failure_mask as Configurable, modeAttributes),
    updateBooleanAttributes(elexant40x0.temperature_1_failure_latch as Configurable, latchAttributes),
  ],
  'TS2HighAlarm4010I/4020I': [
    updateBooleanAttributes(elexant40x0.temperature_1_high_mask as Configurable, modeAttributes),
    updateBooleanAttributes(elexant40x0.temperature_1_high_latch as Configurable, latchAttributes),
  ],
  'TS2LowAlarm4010I/4020I': [
    updateBooleanAttributes(elexant40x0.temperature_1_low_mask as Configurable, modeAttributes),
    updateBooleanAttributes(elexant40x0.temperature_1_low_latch as Configurable, latchAttributes),
  ],
  'TS34010I/4020I': [
    elexant40x0.temperature_2_type as Configurable,
    elexant40x0.temperature_2_lead_resistance as Configurable,
    elexant40x0.temperature_2_tag as Configurable,
    elexant40x0.temperature_2_high_alarm_setpoint as Configurable,
    elexant40x0.temperature_2_high_alarm_filter as Configurable,
    elexant40x0.temperature_2_low_alarm_setpoint as Configurable,
    elexant40x0.temperature_2_low_alarm_filter as Configurable,
  ],
  'TS3FailureAlarm4010I/4020I': [
    updateBooleanAttributes(elexant40x0.temperature_2_failure_mask as Configurable, modeAttributes),
    updateBooleanAttributes(elexant40x0.temperature_2_failure_latch as Configurable, latchAttributes),
  ],
  'TS3HighAlarm4010I/4020I': [
    updateBooleanAttributes(elexant40x0.temperature_2_high_mask as Configurable, modeAttributes),
    updateBooleanAttributes(elexant40x0.temperature_2_high_latch as Configurable, latchAttributes),
  ],
  'TS3LowAlarm4010I/4020I': [
    updateBooleanAttributes(elexant40x0.temperature_2_low_mask as Configurable, modeAttributes),
    updateBooleanAttributes(elexant40x0.temperature_2_low_latch as Configurable, latchAttributes),
  ],
  'AlarmOutput4010I/4020I': [elexant40x0.alarm_0_mode as Configurable, elexant40x0.alarm_0_toggle_time as Configurable],
  'DeviceResetAlarm4010I/4020I': [
    updateBooleanAttributes(elexant40x0.device_reset_mask as Configurable, enableAttributes),
  ],
  'LimiterTrippedAlarm4010I/4020I': [
    updateBooleanAttributes(elexant40x0.limiter_0_trip_mask as Configurable, modeAttributes),
    updateBooleanAttributes(elexant40x0.limiter_0_trip_latch as Configurable, latchAttributes),
  ],
  'LimiterTemperatureSensorFailureAlarmEnable4010I/4020I': [
    updateBooleanAttributes(elexant40x0.limiter_0_temperature_sensor_failure_mask as Configurable, modeAttributes),
    updateBooleanAttributes(elexant40x0.limiter_0_temperature_sensor_failure_latch as Configurable, latchAttributes),
  ],
  'ContactorCycleCountAlarm4010I/4020I': [
    updateBooleanAttributes(
      elexant40x0.htc_0_contactor_cycle_count_limit_exceeded_mask as Configurable,
      modeAttributes,
    ),
    elexant40x0.htc_0_contactor_cycle_count_setpoint as Configurable,
  ],
  'HeaterTimeAlarm4010I/4020I': [
    updateBooleanAttributes(elexant40x0.htc_0_heater_time_limit_exceeded_mask as Configurable, modeAttributes),
    elexant40x0.htc_0_heater_time_setpoint as Configurable,
  ],
  'HighTraceCurrentL14010I/4020I': [
    updateBooleanAttributes(elexant40x0.htc_0_line_current_0_high_mask as Configurable, modeAttributes),
    updateBooleanAttributes(elexant40x0.htc_0_line_current_0_high_latch as Configurable, latchAttributes),
    elexant40x0.htc_0_line_current_0_high_alarm_setpoint as Configurable,
    elexant40x0.htc_0_line_current_0_high_alarm_filter as Configurable,
  ],

  'LowTraceCurrentL14010I/4020I': [
    updateBooleanAttributes(elexant40x0.htc_0_line_current_0_low_mask as Configurable, modeAttributes),
    updateBooleanAttributes(elexant40x0.htc_0_line_current_0_low_latch as Configurable, latchAttributes),
    elexant40x0.htc_0_line_current_0_low_alarm_setpoint as Configurable,
    elexant40x0.htc_0_line_current_0_low_alarm_filter as Configurable,
  ],
  'HighGroundFaultCurrentAlarm4010I/4020I': [
    updateBooleanAttributes(elexant40x0.htc_0_ground_fault_current_high_mask as Configurable, modeAttributes),
    updateBooleanAttributes(elexant40x0.htc_0_ground_fault_current_high_latch as Configurable, latchAttributes),
  ],

  'HighTraceResistanceL14010I/4020I': [
    updateBooleanAttributes(elexant40x0.htc_0_tracing_resistance_0_high_mask as Configurable, modeAttributes),
    updateBooleanAttributes(elexant40x0.htc_0_tracing_resistance_0_high_latch as Configurable, latchAttributes),
    elexant40x0.htc_0_tracing_resistance_0_high_deviation as Configurable,
    elexant40x0.htc_0_tracing_resistance_0_high_alarm_filter as Configurable,
  ],

  'LowTraceResistanceL14010I/4020I': [
    updateBooleanAttributes(elexant40x0.htc_0_tracing_resistance_0_low_mask as Configurable, modeAttributes),
    updateBooleanAttributes(elexant40x0.htc_0_tracing_resistance_0_low_latch as Configurable, latchAttributes),
    elexant40x0.htc_0_tracing_resistance_0_low_deviation as Configurable,
    elexant40x0.htc_0_tracing_resistance_0_low_alarm_filter as Configurable,
  ],
  'HighTraceVoltageAlarm4010I/4020I': [
    updateBooleanAttributes(elexant40x0.htc_0_line_voltage_high_mask as Configurable, modeAttributes),
    updateBooleanAttributes(elexant40x0.htc_0_line_voltage_high_latch as Configurable, latchAttributes),
  ],
  'LowTraceVoltageAlarm4010I/4020I': [
    updateBooleanAttributes(elexant40x0.htc_0_line_voltage_low_mask as Configurable, modeAttributes),
    updateBooleanAttributes(elexant40x0.htc_0_line_voltage_low_latch as Configurable, latchAttributes),
  ],
  'CircuitBreakerandOutputSwitchAlarms4010I/4020I': [
    updateBooleanAttributes(elexant40x0.htc_0_circuit_breaker_0_limiting_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant40x0.htc_0_output_0_limiting_latch as Configurable, latchAttributes),
    updateBooleanAttributes(elexant40x0.htc_0_switch_0_limiting_latch as Configurable, latchAttributes),
  ],
  'GFCurrents4010I/4020I': [
    elexant40x0.htc_0_ground_fault_current_high_alarm_setpoint as Configurable,
    elexant40x0.htc_0_ground_fault_current_high_alarm_filter as Configurable,
    updateBooleanAttributes(elexant40x0.htc_0_ground_fault_trip_mask as Configurable, modeAttributes),
    elexant40x0.htc_0_ground_fault_current_trip_setpoint as Configurable,
    elexant40x0.htc_0_ground_fault_current_zero_offset as Configurable,
  ],
  'NominalTraceResistanceL14010I/4020I': [elexant40x0.htc_0_tracing_resistance_0_nominal as Configurable],
  'FailureandLimitingAlarms4010I/4020I': [
    updateBooleanAttributes(elexant40x0.htc_0_output_0_limiting_mask as Configurable, modeAttributes),
    updateBooleanAttributes(elexant40x0.htc_0_circuit_breaker_0_limiting_mask as Configurable, modeAttributes),
    updateBooleanAttributes(elexant40x0.htc_0_switch_0_limiting_mask as Configurable, modeAttributes),
  ],
  'General4010I/4020I': [
    elexant40x0.htc_0_output_limit_mode as Configurable,
    elexant40x0.htc_0_power_0_maximum_limit_setpoint as Configurable,
    elexant40x0.htc_0_circuit_breaker_0_type as Configurable,
    elexant40x0.htc_0_circuit_breaker_0_current_rating as Configurable,
    elexant40x0.htc_0_ssr_rating as Configurable,
    elexant40x0.htc_0_heater_configuration as Configurable,
    elexant40x0.htc_0_current_turns_ratio as Configurable,
    elexant40x0.htc_0_voltage_turns_ratio as Configurable,
    elexant40x0.htc_0_ground_fault_turns_ratio as Configurable,
    elexant40x0.htc_0_balanced_load as Configurable,
  ],
  'VoltageSetup4010I/4020I': [
    elexant40x0.htc_0_voltage_high_alarm_setpoint as Configurable,
    elexant40x0.htc_0_voltage_high_alarm_filter as Configurable,
    elexant40x0.htc_0_voltage_low_alarm_setpoint as Configurable,
    elexant40x0.htc_0_voltage_low_alarm_filter as Configurable,
    elexant40x0.htc_0_voltage_measurement_configuration as Configurable,
  ],
  'LoadShedding4010I/4020I': [
    updateBooleanAttributes(elexant40x0.htc_0_load_shed_enabled as Configurable, modeAttributes),
    updateBooleanAttributes(elexant40x0.htc_0_load_shed_fail_safe_enabled as Configurable, modeAttributes),
  ],
  'DigitalInput4010I/4020I': [
    elexant40x0.digital_input_source_0_mode as Configurable,
    elexant40x0.digital_input_source_0_polarity as Configurable,
  ],
  'AutoCycle4010I/4020I': [
    updateBooleanAttributes(elexant40x0.htc_0_auto_cycle_enabled as Configurable, modeAttributes),
    elexant40x0.htc_0_auto_cycle_interval as Configurable,
  ],
  'LimiterSettings4010I/4020I': [
    // attribute not present in db
    // updateBooleanAttributes(elexant40x0.limiter_0_intelligent_limiting_active as Configurable, modeAttributes),
    elexant40x0.limiter_0_tag as Configurable,
    elexant40x0.limiter_0_cutout_setpoint as Configurable,
  ],
  'Serial4010I/4020I': [
    elexant40x0.serial_0_modbus_address as Configurable,
    elexant40x0.serial_0_baud_rate as Configurable,
    elexant40x0.serial_0_parity as Configurable,
    elexant40x0.serial_0_stop_bits as Configurable,
    elexant40x0.serial_0_tx_delay as Configurable,
  ],
  'Ethernet4010I/4020I': [
    // attribute not present in db
    // updateBooleanAttributes(elexant40x0.eth_0_use_dhcp as Configurable, modeAttributes),
    elexant40x0.eth_0_configured_ip_address as Configurable,
    elexant40x0.eth_0_configured_net_mask as Configurable,
    elexant40x0.eth_0_configured_default_gateway as Configurable,
    elexant40x0.eth_0_modbus_tcp_port as Configurable,
  ],
};
