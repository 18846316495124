import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Config, ConfigAttributes, ConfiguratorData, LinkedDevice } from '@app/models/configurator';
import { ConfigFormOutlet } from '@app/models/configurator-models/configurator-outline';
import { ConfirmChangeNode } from '@app/models/configurator-models/confirm-change';
import { Configurable, Context, Options } from '@app/models/configurator-models/form-fields';
import { DeviceUIModel } from '@app/models/device';
import { DigitalInputSourceOptionsHTC3, FormControlTypes } from '@app/utils/enums/configuration-enums';
import { DeviceType } from '@app/utils/enums/device-enums';
import { MeasurementUnits, TemperatureUnits } from '@app/utils/enums/unit-enums';
import { temperature_tag_pattern } from '@utils/patterns';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConversionService } from './conversion.service';
import { UserUtilService } from './user-util.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class ConfiguratorService {
  digitalInputPathLabel = 'Digital Input Local Source CAN ID';
  peerDevicesData!: DeviceUIModel[];
  private configurationData = new BehaviorSubject<ConfigAttributes>({} as ConfigAttributes);
  private configFormOutlet = new BehaviorSubject<{ [key: string]: ConfigFormOutlet }>(
    {} as { [key: string]: ConfigFormOutlet },
  );
  private configFormChanges = new BehaviorSubject<ConfirmChangeNode[]>([] as ConfirmChangeNode[]);
  private configuratorData!: ConfiguratorData;
  private data!: Config;
  private device!: DeviceUIModel;
  preferenceUnit!: string;
  availableTempTag: { [key: string]: string } = {};
  constructor(
    private conversionService: ConversionService,
    private utilsService: UtilsService,
    private userUtilsService: UserUtilService,
  ) {}

  createFormGroup(controls: Configurable[], configuratorData: ConfiguratorData, isActualConfig: boolean) {
    const configData: any = configuratorData.data.attributes!;
    const group: any = {};
    this.preferenceUnit = this.userUtilsService.getCurrentUserPreference().unit;
    let defaultFormControlValue: any;
    let isFieldDisabled = !isActualConfig;
    const context: Context = {
      fwVersion: Number(configuratorData.deviceFirmwareVersion),
      deviceType: configuratorData.deviceType!,
      temperaturePreferenceUnit: this.preferenceUnit,
    };

    if (controls) {
      let validators: (control: AbstractControl<any, any>) => ValidationErrors | null;
      Object.values(controls).forEach(control => {
        if (control.validator) validators = control.validator(context);
        if (control.type === FormControlTypes.Range) {
          defaultFormControlValue = control.unit
            ? this.conversionService.setPrecision(String(configData[control.article as keyof typeof configData]))
            : configData[control.article as keyof typeof configData];

          if (control.unit === MeasurementUnits.Celsius || control.unit === MeasurementUnits.Fahrenheit) {
            if (this.preferenceUnit === TemperatureUnits.Fahrenheit) {
              defaultFormControlValue = this.conversionService.temperatureUnitConversion(
                Number(defaultFormControlValue),
                TemperatureUnits.Fahrenheit,
                true,
              );
              control.unit = MeasurementUnits.Fahrenheit;
            } else {
              control.unit = MeasurementUnits.Celsius;
            }
          } else if (
            control.unit === MeasurementUnits.DeltaCelsius ||
            control.unit === MeasurementUnits.DeltaFahrenheit
          ) {
            if (this.preferenceUnit !== TemperatureUnits.Celsius) {
              defaultFormControlValue = this.conversionService.deltaTemperatureUnitConversion(
                Number(defaultFormControlValue),
                TemperatureUnits.Fahrenheit,
                true,
              );
              control.unit = MeasurementUnits.DeltaFahrenheit;
            } else {
              control.unit = MeasurementUnits.DeltaCelsius;
            }
          }
        } else if (control.label && control.label.includes('Usage')) {
          let usageAttributeValue = '';
          control.childConfigurables?.forEach(element => {
            usageAttributeValue =
              configData[element.article as keyof typeof configData] === true
                ? usageAttributeValue + '1'
                : usageAttributeValue + '0';
          });
          defaultFormControlValue = usageAttributeValue;
        } else if (control.type === FormControlTypes.Constant) {
          defaultFormControlValue =
            configuratorData[this.utilsService.camelCaseConverter(control.article) as keyof typeof configuratorData];
          isFieldDisabled = control.disabled!;
        } else if (control.article === 'digital_input_0_path') {
          control.type = FormControlTypes.Choice;
          control.label = this.digitalInputPathLabel;
          control.options = this.getDigitalInputSourceCANIDOptions(this.getPeerDevicesByType(DeviceType.Ngc40io));
          defaultFormControlValue = this.getDISourceCANIDSelectedValue(
            control,
            configData[control.article as keyof typeof configData],
          );
        } else if (control.article === 'digital_input_source') {
          defaultFormControlValue = this.getDigitalInputSourceSelectedOption(configData.digital_input_0_path, control);
        } else {
          defaultFormControlValue = configData[control.article as keyof typeof configData];
        }
        group[control.article] = new FormControl<string | boolean | number>(
          {
            value: defaultFormControlValue,
            disabled: isFieldDisabled,
          },
          {
            nonNullable: true,
            validators,
          },
        );
        if (temperature_tag_pattern.test(control.article)) {
          new FormGroup(group).controls[control.article].addValidators(this.temperatureTagValidator(control.article));
        }
      });
    }
    return new FormGroup(group);
  }

  getConfigurationData(): Observable<ConfigAttributes> {
    return this.configurationData;
  }

  setConfigurationData(data: any): void {
    const initialData = this.configurationData.getValue();
    const updatedData = { ...initialData, ...data };
    this.configurationData.next(updatedData);
    this.setAvailableTemperatureTag(updatedData);
  }

  clearConfigurationData(): void {
    this.configurationData.next({} as ConfigAttributes);
  }

  setPeerDevices(data: DeviceUIModel[]): void {
    this.peerDevicesData = data;
  }

  getPeerDevicesByType(deviceType: string): LinkedDevice[] {
    const linkedDevices: LinkedDevice[] = [];
    this.peerDevicesData.forEach(device => {
      if (device.deviceType === deviceType)
        linkedDevices.push({
          deviceTag: device.deviceTag!,
          deviceSerial: device.serialNumber!,
        });
    });
    return linkedDevices;
  }

  getDigitalInputSourceCANIDOptions(linkedDevices: LinkedDevice[]): Options[] {
    const options: Options[] = [];
    options.push({
      key: 0,
      value: null,
      displayText: '0000',
    });
    linkedDevices.forEach((device, index) => {
      options.push({
        key: index + 1,
        value: `can0:${device.deviceSerial}/digital_input_0`,
        displayText: `${device.deviceSerial} - ${device.deviceTag}`,
      });
    });
    return options;
  }

  getDISourceCANIDSelectedValue(control: Configurable, field: any): any {
    return String(field).includes('can0')
      ? control.options?.find(option => option.value === field)?.value
      : control.options![0].value;
  }

  getDigitalInputSourceSelectedOption(digitalInputPath: string | null | undefined, control: Configurable): any {
    return digitalInputPath
      ? digitalInputPath.includes('can0')
        ? control.options?.find(option => option.value === DigitalInputSourceOptionsHTC3.CanNetworkIO)?.value
        : control.options?.find(option => option.value === DigitalInputSourceOptionsHTC3.Local)?.value
      : control.options?.find(option => option.value === DigitalInputSourceOptionsHTC3.NotUsed)?.value;
  }

  setConfigFormOutlet(formOutlet: { [key: string]: ConfigFormOutlet }) {
    this.configFormOutlet.next(formOutlet);
  }

  getConfigFormOutlet(): Observable<{ [key: string]: ConfigFormOutlet }> {
    return this.configFormOutlet;
  }

  getConfigChangeData(): ConfirmChangeNode[] {
    return this.configFormChanges.getValue();
  }

  addChangedData(name: string, valueChange: ConfirmChangeNode) {
    const changedConfigFormValues = this.getConfigChangeData();
    let parentHeader = changedConfigFormValues.find(parent => parent.name === name);
    if (parentHeader) {
      let indexOfExistingRecord = parentHeader.childrens?.findIndex(formLabel => formLabel.name === valueChange.name);
      indexOfExistingRecord !== -1 ? parentHeader.childrens?.splice(indexOfExistingRecord!, 1) : '';
      parentHeader.childrens?.push(valueChange);
    } else {
      parentHeader = { name: name, childrens: [valueChange] };
      changedConfigFormValues.push(parentHeader);
    }
    this.configFormChanges.next(changedConfigFormValues);
  }

  deleteChangedValue(parentHeaderName: string, attributeName: string) {
    const changedConfigFormValues = this.getConfigChangeData();
    let headerIndex = changedConfigFormValues.findIndex(
      changedAttributes => changedAttributes.name === parentHeaderName,
    );
    if (headerIndex !== -1) {
      changedConfigFormValues[headerIndex].childrens = changedConfigFormValues![headerIndex].childrens?.filter(ele => {
        return ele.name !== attributeName;
      });
      if (!changedConfigFormValues[headerIndex].childrens?.length) {
        changedConfigFormValues.splice(headerIndex, 1);
      }
    }
    this.configFormChanges.next(changedConfigFormValues);
  }

  setIntialConfigData(configuratorData: ConfiguratorData, data: Config, device: DeviceUIModel) {
    [this.configuratorData, this.data, this.device] = [configuratorData, data, device];
    if (this.availableTempTag) this.availableTempTag = cloneDeep({});
    this.setAvailableTemperatureTag(data);
  }

  getIntialConfigData(): [ConfiguratorData, Config, DeviceUIModel] {
    return [this.configuratorData, this.data, this.device];
  }

  setAvailableTemperatureTag(initialConfig: Config) {
    let i = 0;
    const configData: any = initialConfig.attributes ? initialConfig.attributes : initialConfig;
    while (configData[`temperature_${i}_tag`] !== undefined) {
      this.availableTempTag[`temperature_${i}_tag`] = configData[`temperature_${i}_tag`];
      i++;
    }
  }

  /* istanbul ignore next */
  temperatureTagValidator(article: string): ValidatorFn {
    return (control: AbstractControl) => {
      this.availableTempTag[article] = control.value;
      const containsError = Object.keys(this.availableTempTag).some(temperatureTag => {
        let notUnique = false;
        if (article !== temperatureTag && control.value === this.availableTempTag[temperatureTag]) {
          notUnique = true;
        }
        return notUnique;
      });
      return containsError ? { notUnique: containsError } : null;
    };
  }

  clearAllConfigRelatedData() {
    this.configuratorData = {} as ConfiguratorData;
    this.data = {} as Config;
    this.device = {} as DeviceUIModel;
    this.configFormChanges.next([] as ConfirmChangeNode[]);
    this.configFormOutlet.next({});
  }
}
