import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit {
  title = 'res-ui';
  destroyRef = inject(DestroyRef);
  showApprovalPage: undefined | boolean = undefined;
  showTermsPage: undefined | boolean = undefined;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.getCurrentRoute();
  }

  getCurrentRoute(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.showApprovalPage = event.url.includes('approval');
          this.showTermsPage = event.url.includes('terms');
        }
      });
  }
}
