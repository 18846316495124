import { AbstractControl, Validators } from '@angular/forms';
import { Configurable, Context } from '@models/configurator-models/form-fields';

const setControlState = (control: AbstractControl, condition: boolean, ...articles: string[]) => {
  articles.forEach(article => {
    if (condition) {
      control.parent?.get(article)?.enable();
    } else {
      control.parent?.get(article)?.disable();
      control.parent?.get(article)?.reset();
    }
  });
};

export const elexant3500i = {
  agent_communication_failure_limit: {
    article: 'agent_communication_failure_limit',
    defaultValue: '1h',
    group: 'Regs/Config/CommunicationParameters/AgentCommunicationFailureLimit',
    label: 'Agent communication failure limit',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  agent_communication_failure_mask: {
    article: 'agent_communication_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/AgentCommunicationFailure',
    label: 'Agent communication failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  alarm_0_mode: {
    article: 'alarm_0_mode',
    defaultValue: 'NORMAL_OPERATION',
    group: 'Regs/Config/ControllerSetupParameters/AlarmOutputMode',
    label: 'Alarm output mode',
    options: [
      { key: 0, value: 'NORMAL_OPERATION', displayText: 'NORMAL OPERATION' },
      { key: 1, value: 'TOGGLE', displayText: 'TOGGLE' },
      { key: 2, value: 'FLASH', displayText: 'FLASH' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  device_reset_latch: {
    article: 'device_reset_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/DeviceReset',
    label: 'Device reset latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  device_reset_mask: {
    article: 'device_reset_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/DeviceReset',
    label: 'Device reset mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  device_tag: {
    article: 'device_tag',
    defaultValue: 'ELEXANT3500I-{serial number}',
    group: 'Regs/Config/ControllerSetupParameters/Tag',
    label: 'Tag',
    type: 'string16',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        [this.min, this.max] = [1, 40];
        control.setValidators([Validators.maxLength(this.max), Validators.minLength(this.min)]);
      };
    },
  } as Configurable,
  htc_0_auto_cycle_interval: {
    article: 'htc_0_auto_cycle_interval',
    defaultValue: '8h',
    group: 'Regs/Config/ControllerSetupParameters/AutoCycleInterval',
    label: 'Auto cycle interval',
    type: 'range',
    unit: 'h',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [0, 750];
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_clear_alarm_on_enable: {
    article: 'htc_0_clear_alarm_on_enable',
    defaultValue: false,
    group: 'Regs/Config/ControllerSetupParameterBits/ClearAlarmOnEnable',
    label: 'Clear alarm on enable',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_contactor_cycle_count_limit_exceeded_latch: {
    article: 'htc_0_contactor_cycle_count_limit_exceeded_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/ContactorCycleCount',
    label: 'Contactor cycle count latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_contactor_cycle_count_limit_exceeded_mask: {
    article: 'htc_0_contactor_cycle_count_limit_exceeded_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/ContactorCycleCount',
    label: 'Contactor cycle count mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, elexant3500i.htc_0_contactor_cycle_count_setpoint.article);
      };
    },
  } as Configurable,
  htc_0_contactor_cycle_count_setpoint: {
    article: 'htc_0_contactor_cycle_count_setpoint',
    defaultValue: 2000000,
    group: 'Regs/Config/ACAnalogParameters/ContactorCycleCountAlarmLimitSetpoint',
    label: 'Contactor cycle count alarm limit setpoint',
    type: 'int',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [0, 2000000];
        return control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_control_temperature_failure_latch: {
    article: 'htc_0_control_temperature_failure_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/ControlTemperatureFailure',
    label: 'Control temperature failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_control_temperature_failure_mask: {
    article: 'htc_0_control_temperature_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/ControlTemperatureFailure',
    label: 'Control temperature failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_control_temperature_high_alarm_filter: {
    article: 'htc_0_control_temperature_high_alarm_filter',
    defaultValue: '5s',
    group: 'Regs/Config/TemperatureSetup/ControlTemperatureHighAlarmFilter',
    label: 'Control temperature high alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [5, 5];
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
        // if (this.min === this.max) control.disable();
      };
    },
  } as Configurable,
  htc_0_control_temperature_high_alarm_setpoint: {
    article: 'htc_0_control_temperature_high_alarm_setpoint',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSetup/ControlTemperatureHighAlarmSetpoint',
    label: 'Control temperature high alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.max = 600.0;
        const sumOfTempSetpoint =
          control.parent?.get(elexant3500i.htc_0_control_temperature_setpoint.article)?.value +
          control.parent?.get(elexant3500i.htc_0_deadband.article)?.value;
        const highAlarmValue = control.parent?.get(
          elexant3500i.htc_0_control_temperature_high_alarm_setpoint.article,
        )?.value;
        this.min = sumOfTempSetpoint > highAlarmValue ? sumOfTempSetpoint : -55.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          this.min = (this.min! * 9) / 5;
          this.max = (this.max * 9) / 5;
        }
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_control_temperature_high_latch: {
    article: 'htc_0_control_temperature_high_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/ControlTemperatureHigh',
    label: 'Control temperature high latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_control_temperature_high_mask: {
    article: 'htc_0_control_temperature_high_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/ControlTemperatureHigh',
    label: 'Control temperature high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(
          control,
          control.value,
          elexant3500i.htc_0_control_temperature_high_alarm_filter.article,
          elexant3500i.htc_0_control_temperature_high_alarm_setpoint.article,
        );
      };
    },
  } as Configurable,
  htc_0_control_temperature_low_alarm_filter: {
    article: 'htc_0_control_temperature_low_alarm_filter',
    defaultValue: '5s',
    group: 'Regs/Config/TemperatureSetup/ControlTemperatureLowAlarmFilter',
    label: 'Control temperature low alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [5, 5];
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_control_temperature_low_alarm_setpoint: {
    article: 'htc_0_control_temperature_low_alarm_setpoint',
    defaultValue: '5.0°C',
    group: 'Regs/Config/TemperatureSetup/ControlTemperatureLowAlarmSetpoint',
    label: 'Control temperature low alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = -60.0;
        const diffOfTempSetpoint =
          control.parent?.get(elexant3500i.htc_0_control_temperature_setpoint.article)?.value -
          control.parent?.get(elexant3500i.htc_0_deadband.article)?.value;
        const lowAlarmValue = control.parent?.get(
          elexant3500i.htc_0_control_temperature_low_alarm_setpoint.article,
        )?.value;
        this.max = diffOfTempSetpoint < lowAlarmValue ? diffOfTempSetpoint : 545.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          this.min = (this.min * 9) / 5;
          this.max = (this.max * 9) / 5;
        }
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_control_temperature_low_latch: {
    article: 'htc_0_control_temperature_low_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/ControlTemperatureLow',
    label: 'Control temperature low latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_control_temperature_low_mask: {
    article: 'htc_0_control_temperature_low_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/ControlTemperatureLow',
    label: 'Control temperature low mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(
          control,
          control.value,
          elexant3500i.htc_0_control_temperature_low_alarm_filter.article,
          elexant3500i.htc_0_control_temperature_low_alarm_setpoint.article,
        );
      };
    },
  } as Configurable,
  htc_0_control_temperature_setpoint: {
    article: 'htc_0_control_temperature_setpoint',
    defaultValue: '20.0°C',
    group: 'Regs/Config/ControlParameters/ControlTemperatureSetpoint',
    label: 'Control temperature setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [-55.0, 550.0];
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          [this.min, this.max] = [-67.0, 1022.0];
        }
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_deadband: {
    article: 'htc_0_deadband',
    defaultValue: '3.0Δ°C',
    group: 'Regs/Config/ControlParameters/Deadband',
    label: 'Deadband',
    type: 'range',
    unit: 'Δ°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [3.0, 3.0];
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          [this.min, this.max] = [5.4, 5.4];
        }
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_energize: {
    article: 'htc_0_energize',
    defaultValue: '0s',
    group: 'Regs/Config/ControlParameters/TestTracing',
    label: 'Test tracing',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [0, 300];
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_ground_fault_current_high_alarm_setpoint: {
    article: 'htc_0_ground_fault_current_high_alarm_setpoint',
    defaultValue: '20.0mA',
    group: 'Regs/Config/ACAnalogParameters/HighGFCurrentAlarmSetpoint',
    label: 'High gf current alarm setpoint',
    type: 'range',
    unit: 'mA',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [10.0, 250.0];
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_ground_fault_current_high_latch: {
    article: 'htc_0_ground_fault_current_high_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/HighGroundFaultCurrent',
    label: 'High ground fault current latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_ground_fault_current_high_mask: {
    article: 'htc_0_ground_fault_current_high_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/HighGroundFaultCurrent',
    label: 'High ground fault current mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, elexant3500i.htc_0_ground_fault_current_high_alarm_setpoint.article);
      };
    },
  } as Configurable,
  htc_0_ground_fault_current_trip_setpoint: {
    article: 'htc_0_ground_fault_current_trip_setpoint',
    defaultValue: '30.0mA',
    group: 'Regs/Config/ACAnalogParameters/GroundFaultTripCurrentSetpoint',
    label: 'Ground fault trip current setpoint',
    type: 'range',
    unit: 'mA',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [10.0, 250.0];
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_ground_fault_transformer_failure_latch: {
    article: 'htc_0_ground_fault_transformer_failure_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/GroundFaultCTFailure',
    label: 'Ground fault ct failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_ground_fault_transformer_failure_mask: {
    article: 'htc_0_ground_fault_transformer_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/GroundFaultCTFailure',
    label: 'Ground fault ct failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_ground_fault_trip_latch: {
    article: 'htc_0_ground_fault_trip_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/GroundFaultTrip',
    label: 'Ground fault trip latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_ground_fault_trip_mask: {
    article: 'htc_0_ground_fault_trip_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/GroundFaultTrip',
    label: 'Ground fault trip mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, elexant3500i.htc_0_ground_fault_current_trip_setpoint.article);
      };
    },
  } as Configurable,
  htc_0_heater_time_limit_exceeded_latch: {
    article: 'htc_0_heater_time_limit_exceeded_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/HeaterTime',
    label: 'Heater time latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_heater_time_limit_exceeded_mask: {
    article: 'htc_0_heater_time_limit_exceeded_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/HeaterTime',
    label: 'Heater time mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, elexant3500i.htc_0_heater_time_setpoint.article);
      };
    },
  } as Configurable,
  htc_0_heater_time_setpoint: {
    article: 'htc_0_heater_time_setpoint',
    defaultValue: '1000000h',
    group: 'Regs/Config/ACAnalogParameters/HeaterOnTimeAlarmSetpoint',
    label: 'Heater on time alarm setpoint',
    type: 'range',
    unit: 'h',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [1, 1000000];
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_high_limit_cutout_latch: {
    article: 'htc_0_high_limit_cutout_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/HighLimitTemperatureCutout',
    label: 'High limit temperature cutout latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_high_limit_cutout_mask: {
    article: 'htc_0_high_limit_cutout_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/HighLimitTemperatureCutout',
    label: 'High limit temperature cutout mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, elexant3500i.htc_0_high_limit_cutout_setpoint.article);
      };
    },
  } as Configurable,
  htc_0_high_limit_cutout_setpoint: {
    article: 'htc_0_high_limit_cutout_setpoint',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSetup/HighLimitTemperatureCutout',
    label: 'High limit temperature cutout',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.max = 600.0;
        const sumOfTempSetpoint =
          control.parent?.get(elexant3500i.htc_0_control_temperature_setpoint.article)?.value +
          control.parent?.get(elexant3500i.htc_0_deadband.article)?.value;
        this.min = sumOfTempSetpoint ? sumOfTempSetpoint : -55.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          this.min = (this.min! * 9) / 5;
          this.max = (this.max * 9) / 5;
        }
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_load_current_low_alarm_filter: {
    article: 'htc_0_load_current_low_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/ACAnalogParameters/LowLoadCurrentAlarmFilter',
    label: 'Low load current alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [0, 12];
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_load_current_low_alarm_setpoint: {
    article: 'htc_0_load_current_low_alarm_setpoint',
    defaultValue: '1.00A',
    group: 'Regs/Config/ACAnalogParameters/LowLoadCurrentAlarmSetpoint',
    label: 'Low load current alarm setpoint',
    type: 'range',
    unit: 'A',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [0.1, 30.0];
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_load_current_low_latch: {
    article: 'htc_0_load_current_low_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/LowLoadCurrent',
    label: 'Low load current latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_load_current_low_mask: {
    article: 'htc_0_load_current_low_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/LowLoadCurrent',
    label: 'Low load current mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(
          control,
          control.value,
          elexant3500i.htc_0_load_current_low_alarm_filter.article,
          elexant3500i.htc_0_load_current_low_alarm_setpoint.article,
        );
      };
    },
  } as Configurable,
  htc_0_non_latching_high_temperature_cutout_alarms: {
    article: 'htc_0_non_latching_high_temperature_cutout_alarms',
    defaultValue: 'NON_LATCHING',
    group: 'Regs/Config/TemperatureSetup/NonLatchingHighTemperatureCutoutAlarms',
    label: 'Non latching high temperature cutout alarms',
    options: [
      { key: 0, value: 'LATCHING', displayText: 'Latching' },
      { key: 1, value: 'NON_LATCHING', displayText: 'Non-Latching' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_non_latching_temperature_alarms: {
    article: 'htc_0_non_latching_temperature_alarms',
    defaultValue: 'LATCHING',
    group: 'Regs/Config/TemperatureSetup/NonLatchingTemperatureAlarms',
    label: 'Non latching temperature alarms',
    options: [
      { key: 0, value: 'LATCHING', displayText: 'Latching' },
      { key: 1, value: 'NON_LATCHING', displayText: 'Non-Latching' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_pasc_min_ambient_temperature: {
    article: 'htc_0_pasc_min_ambient_temperature',
    defaultValue: '-40.0°C',
    group: 'Regs/Config/ControlParameters/PASCMinAmbientTemp',
    label: 'Pasc min ambient temp',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [-73.0, 51.0];
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          [this.min, this.max] = [-99.4, 123.8];
        }
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_pasc_min_pipe_size: {
    article: 'htc_0_pasc_min_pipe_size',
    defaultValue: 'SIZE_1_POINT_3_CM',
    group: 'Regs/Config/ControlParameters/PASCMinPipeSize',
    label: 'Pasc min pipe size',
    options: [
      { key: 0, value: 'SIZE_1_POINT_3_CM', displayText: 'SIZE 1 POINT 3 CM' },
      { key: 1, value: 'SIZE_2_POINT_5_CM', displayText: 'SIZE 2 POINT 5 CM' },
      { key: 2, value: 'SIZE_GE_5_POINT_1_CM', displayText: 'SIZE GE 5 POINT 1 CM' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_switch_0_failure_latch: {
    article: 'htc_0_switch_0_failure_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/SwitchFailure',
    label: 'Switch failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_switch_0_failure_mask: {
    article: 'htc_0_switch_0_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/SwitchFailure',
    label: 'Switch failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_switch_control_mode: {
    article: 'htc_0_switch_control_mode',
    defaultValue: 'DEADBAND',
    group: 'Regs/Config/ControlParameters/SwitchControlMode',
    label: 'Switch control mode',
    options: [
      { key: 0, value: 'DEADBAND', displayText: 'DEADBAND' },
      { key: 1, value: 'PROPORTIONAL_AMBIENT_CONTACTOR', displayText: 'PROPORTIONAL AMBIENT CONTACTOR' },
      { key: 2, value: 'PASC_CONTACTOR', displayText: 'PASC CONTACTOR' },
      { key: 3, value: 'ALWAYS_ON', displayText: 'ALWAYS ON' },
      { key: 4, value: 'ALWAYS_OFF', displayText: 'ALWAYS OFF' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(
          control,
          control.value === 'DEADBAND' || control.value === 'PASC_CONTACTOR',
          elexant3500i.htc_0_deadband.article,
        );
        setControlState(
          control,
          control.value === 'PASC_CONTACTOR',
          elexant3500i.htc_0_pasc_min_ambient_temperature.article,
          elexant3500i.htc_0_pasc_min_pipe_size.article,
        );
      };
    },
  } as Configurable,
  htc_0_temperature_control_mode: {
    article: 'htc_0_temperature_control_mode',
    defaultValue: 'LOWEST',
    group: 'Regs/Config/TemperatureSetup/TemperatureControlMode',
    label: 'Temperature control mode',
    options: [
      { key: 0, value: 'NA', displayText: 'NA' },
      { key: 9, value: 'AVERAGE', displayText: 'AVERAGE' },
      { key: 10, value: 'LOWEST', displayText: 'LOWEST' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_fail_mode: {
    article: 'htc_0_temperature_fail_mode',
    defaultValue: 'FAIL_OFF',
    group: 'Regs/Config/TemperatureSetup/TemperatureFailMode',
    label: 'Temperature fail mode',
    options: [
      { key: 0, value: 'FAIL_OFF', displayText: 'FAIL OFF' },
      { key: 1, value: 'FAIL_ON', displayText: 'FAIL ON' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_0_control_enabled: {
    article: 'htc_0_temperature_source_0_control_enabled',
    defaultValue: false,
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSConfig/ControlEnable',
    label: 'Control enable',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_0_high_cutout_enabled: {
    article: 'htc_0_temperature_source_0_high_cutout_enabled',
    defaultValue: false,
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSConfig/CutoutEnable',
    label: 'Cutout enable',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_0_path: {
    article: 'htc_0_temperature_source_0_path',
    defaultValue: 'None',
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSRoute',
    label: 'Ts route',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_1_control_enabled: {
    article: 'htc_0_temperature_source_1_control_enabled',
    defaultValue: false,
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSConfig/ControlEnable',
    label: 'Control enable',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_1_high_cutout_enabled: {
    article: 'htc_0_temperature_source_1_high_cutout_enabled',
    defaultValue: false,
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSConfig/CutoutEnable',
    label: 'Cutout enable',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_1_path: {
    article: 'htc_0_temperature_source_1_path',
    defaultValue: 'None',
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSRoute',
    label: 'Ts route',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  limiter_0_cutout_setpoint: {
    article: 'limiter_0_cutout_setpoint',
    defaultValue: '300°C',
    group: 'Regs/Config/LimiterParametersRegs/LimiterCutoutTemperatureSetpoint',
    label: 'Limiter cutout temperature setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [-50, 600];
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          [this.min, this.max] = [-58, 1112];
        }
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  limiter_0_tag: {
    article: 'limiter_0_tag',
    defaultValue: 'Elexant 3500i-TS-{Serial Number}',
    group: 'Regs/Config/LimiterParametersRegs/LimiterLinkedDeviceTag',
    label: 'Limiter linked device tag',
    type: 'string16',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        [this.min, this.max] = [1, 40];
        control.setValidators([Validators.maxLength(this.max), Validators.minLength(this.min)]);
      };
    },
  } as Configurable,
  limiter_0_temperature_sensor_failure_latch: {
    article: 'limiter_0_temperature_sensor_failure_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/LimiterTemperatureSensorFailure',
    label: 'Limiter temperature sensor failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  limiter_0_temperature_sensor_failure_mask: {
    article: 'limiter_0_temperature_sensor_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/LimiterTemperatureSensorFailure',
    label: 'Limiter temperature sensor failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  limiter_0_trip_latch: {
    article: 'limiter_0_trip_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/LimiterTripAlarm',
    label: 'Limiter trip alarm latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  limiter_0_trip_mask: {
    article: 'limiter_0_trip_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/LimiterTripAlarm',
    label: 'Limiter trip alarm mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  nvram_0_factory_configuration_data_lost_latch: {
    article: 'nvram_0_factory_configuration_data_lost_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/FactoryConfigurationLost',
    label: 'Factory configuration lost latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  nvram_0_factory_configuration_data_lost_mask: {
    article: 'nvram_0_factory_configuration_data_lost_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/FactoryConfigurationLost',
    label: 'Factory configuration lost mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  nvram_0_user_configuration_data_lost_latch: {
    article: 'nvram_0_user_configuration_data_lost_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/UserConfigurationLost',
    label: 'User configuration lost latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  nvram_0_user_configuration_data_lost_mask: {
    article: 'nvram_0_user_configuration_data_lost_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/UserConfigurationLost',
    label: 'User configuration lost mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  serial_0_baud_rate: {
    article: 'serial_0_baud_rate',
    defaultValue: 9600,
    group: 'Regs/Config/CommunicationParameters/BaudRate',
    label: 'Baud rate',
    type: 'int',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        control.value == 9600 || control.value == 19200 || control.value == 38400 || control.value == 57600;
      };
    },
  } as Configurable,
  serial_0_data_bits: {
    article: 'serial_0_data_bits',
    defaultValue: 8,
    group: 'Regs/Config/CommunicationParameters/DataBits',
    label: 'Data bits',
    type: 'int',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [7, 8];
        return control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  serial_0_frame_type: {
    article: 'serial_0_frame_type',
    defaultValue: 'MODBUS_RTU',
    group: 'Regs/Config/CommunicationParameters/FrameType',
    label: 'Frame type',
    options: [
      { key: 0, value: 'MODBUS_ASCII', displayText: 'MODBUS ASCII' },
      { key: 1, value: 'MODBUS_RTU', displayText: 'MODBUS RTU' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  serial_0_modbus_address: {
    article: 'serial_0_modbus_address',
    defaultValue: 1,
    group: 'Regs/Config/CommunicationParameters/ModbusAddress',
    label: 'Modbus address',
    type: 'int',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [1, 247];
        return control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  serial_0_parity: {
    article: 'serial_0_parity',
    defaultValue: 'None',
    group: 'Regs/Config/CommunicationParameters/Parity',
    label: 'Parity',
    options: [
      { key: 0, value: 'NONE', displayText: 'NONE' },
      { key: 1, value: 'ODD', displayText: 'ODD' },
      { key: 2, value: 'EVEN', displayText: 'EVEN' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  serial_0_stop_bits: {
    article: 'serial_0_stop_bits',
    defaultValue: 2,
    group: 'Regs/Config/CommunicationParameters/StopBits',
    label: 'Stop bits',
    type: 'int',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [1, 2];
        return control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  serial_0_tx_delay: {
    article: 'serial_0_tx_delay',
    defaultValue: '20ms',
    group: 'Regs/Config/CommunicationParameters/TxDelay',
    label: 'Tx delay',
    type: 'range',
    unit: 'ms',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [0, 50];
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_0_failure_latch: {
    article: 'temperature_0_failure_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/TemperatureSensorFailure/elem',
    label: 'Temperature 0 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_0_failure_mask: {
    article: 'temperature_0_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/TemperatureSensorFailure/elem',
    label: 'Temperature 0 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, elexant3500i.temperature_0_high_alarm_setpoint.article);
      };
    },
  } as Configurable,
  temperature_0_high_alarm_filter: {
    article: 'temperature_0_high_alarm_filter',
    defaultValue: '5s',
    group: 'Regs/Config/TemperatureSetup/HighTSAlarmFilter/elem',
    label: 'Temperature 0 high alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [5, 5];
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_0_high_alarm_setpoint: {
    article: 'temperature_0_high_alarm_setpoint',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSetup/HighTSAlarmSetpoint/elem',
    label: 'Temperature 0 high alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.max = 545.0;
        const cntrlTempSetpoint = control.parent?.get(elexant3500i.htc_0_control_temperature_setpoint.article)?.value;
        this.min = cntrlTempSetpoint ? cntrlTempSetpoint : -60.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          this.min = (this.min! * 9) / 5;
          this.max = (this.max * 9) / 5;
        }
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_0_high_latch: {
    article: 'temperature_0_high_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/TemperatureSensorHigh/elem',
    label: 'Temperature 0 high latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_0_high_mask: {
    article: 'temperature_0_high_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/TemperatureSensorHigh/elem',
    label: 'Temperature 0 high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(
          control,
          control.value,
          elexant3500i.temperature_0_high_alarm_filter.article,
          elexant3500i.temperature_0_high_alarm_setpoint.article,
        );
      };
    },
  } as Configurable,
  temperature_0_low_alarm_filter: {
    article: 'temperature_0_low_alarm_filter',
    defaultValue: '5s',
    group: 'Regs/Config/TemperatureSetup/LowTSAlarmFilter/elem',
    label: 'Temperature 0 low alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [5, 5];
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_0_low_alarm_setpoint: {
    article: 'temperature_0_low_alarm_setpoint',
    defaultValue: '-10.0°C',
    group: 'Regs/Config/TemperatureSetup/LowTSAlarmSetpoint/elem',
    label: 'Temperature 0 low alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = -60.0;
        const cntrlTempSetpoint = control.parent?.get(elexant3500i.htc_0_control_temperature_setpoint.article)?.value;
        this.max = cntrlTempSetpoint ? cntrlTempSetpoint : 600.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          this.min = (this.min * 9) / 5;
          this.max = (this.max! * 9) / 5;
        }
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  temperature_0_low_latch: {
    article: 'temperature_0_low_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/TemperatureSensorLow/elem',
    label: 'Temperature 0 low latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_0_low_mask: {
    article: 'temperature_0_low_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/TemperatureSensorLow/elem',
    label: 'Temperature 0 low mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(
          control,
          control.value,
          elexant3500i.temperature_0_low_alarm_filter.article,
          elexant3500i.temperature_0_low_alarm_setpoint.article,
        );
      };
    },
  } as Configurable,
  temperature_0_tag: {
    article: 'temperature_0_tag',
    defaultValue: 'Elexant 3500i-TS{index}-{Serial Number}',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorTag/elem',
    label: 'Temperature 0 tag',
    type: 'string16',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        [this.min, this.max] = [1, 40];
        control.setValidators([Validators.maxLength(this.max), Validators.minLength(this.min)]);
      };
    },
  } as Configurable,
  temperature_0_type: {
    article: 'temperature_0_type',
    defaultValue: 'THREE_WIRE_100_OHM_PLATINUM',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorType/elem',
    label: 'Temperature 0 type',
    options: [
      { key: 0, value: 'NOT_USED', displayText: 'NOT USED' },
      { key: 1, value: 'THREE_WIRE_100_OHM_PLATINUM', displayText: 'THREE WIRE 100 OHM PLATINUM' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_1_failure_latch: {
    article: 'temperature_1_failure_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/TemperatureSensorFailure/elem',
    label: 'Temperature 1 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_1_failure_mask: {
    article: 'temperature_1_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/TemperatureSensorFailure/elem',
    label: 'Temperature 1 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, elexant3500i.temperature_1_high_alarm_setpoint.article);
      };
    },
  } as Configurable,
  temperature_1_high_alarm_filter: {
    article: 'temperature_1_high_alarm_filter',
    defaultValue: '5s',
    group: 'Regs/Config/TemperatureSetup/HighTSAlarmFilter/elem',
    label: 'Temperature 1 high alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [5, 5];
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_1_high_alarm_setpoint: {
    article: 'temperature_1_high_alarm_setpoint',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSetup/HighTSAlarmSetpoint/elem',
    label: 'Temperature 1 high alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.max = 545.0;
        const cntrlTempSetpoint = control.parent?.get(elexant3500i.htc_0_control_temperature_setpoint.article)?.value;
        this.min = cntrlTempSetpoint ? cntrlTempSetpoint : -60.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          this.min = (this.min! * 9) / 5;
          this.max = (this.max * 9) / 5;
        }
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_1_high_latch: {
    article: 'temperature_1_high_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/TemperatureSensorHigh/elem',
    label: 'Temperature 1 high latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_1_high_mask: {
    article: 'temperature_1_high_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/TemperatureSensorHigh/elem',
    label: 'Temperature 1 high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(
          control,
          control.value,
          elexant3500i.temperature_1_high_alarm_filter.article,
          elexant3500i.temperature_1_high_alarm_setpoint.article,
        );
      };
    },
  } as Configurable,
  temperature_1_low_alarm_filter: {
    article: 'temperature_1_low_alarm_filter',
    defaultValue: '5s',
    group: 'Regs/Config/TemperatureSetup/LowTSAlarmFilter/elem',
    label: 'Temperature 1 low alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        [this.min, this.max] = [5, 5];
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_1_low_alarm_setpoint: {
    article: 'temperature_1_low_alarm_setpoint',
    defaultValue: '-10.0°C',
    group: 'Regs/Config/TemperatureSetup/LowTSAlarmSetpoint/elem',
    label: 'Temperature 1 low alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = -60.0;
        const cntrlTempSetpoint = control.parent?.get(elexant3500i.htc_0_control_temperature_setpoint.article)?.value;
        this.max = cntrlTempSetpoint ? cntrlTempSetpoint : 600.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          this.min = (this.min * 9) / 5;
          this.max = (this.max! * 9) / 5;
        }
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  temperature_1_low_latch: {
    article: 'temperature_1_low_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/TemperatureSensorLow/elem',
    label: 'Temperature 1 low latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_1_low_mask: {
    article: 'temperature_1_low_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/TemperatureSensorLow/elem',
    label: 'Temperature 1 low mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(
          control,
          control.value,
          elexant3500i.temperature_1_low_alarm_filter.article,
          elexant3500i.temperature_1_low_alarm_setpoint.article,
        );
      };
    },
  } as Configurable,
  temperature_1_tag: {
    article: 'temperature_1_tag',
    defaultValue: 'Elexant 3500i-TS{index}-{Serial Number}',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorTag/elem',
    label: 'Temperature 1 tag',
    type: 'string16',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        [this.min, this.max] = [1, 40];
        control.setValidators([Validators.maxLength(this.max), Validators.minLength(this.min)]);
      };
    },
  } as Configurable,
  temperature_1_type: {
    article: 'temperature_1_type',
    defaultValue: 'THREE_WIRE_100_OHM_PLATINUM',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorType/elem',
    label: 'Temperature 1 type',
    options: [
      { key: 0, value: 'NOT_USED', displayText: 'NOT USED' },
      { key: 1, value: 'THREE_WIRE_100_OHM_PLATINUM', displayText: 'THREE WIRE 100 OHM PLATINUM' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
};
