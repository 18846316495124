import { Configurable } from './form-fields';
import { updateBooleanAttributes } from '@app/utils/configurator-util';
import {
  enableAttributes,
  latchAttributes,
  modeAttributes,
  priorityAttributes,
  temperatureAttributes,
} from '@app/utils/constants/configurator-constants';
import { cm2000 } from '@app/utils/configurator-meta/cm2000-meta';

export const cm2000ConfigurablesForForms: { [key: string]: Configurable[] } = {
  ControlTemperatureCM2000: [
    cm2000.htc_0_control_temperature_setpoint as Configurable,
    cm2000.htc_0_temperature_control_mode as Configurable,
    // 14/05/2024 : Attribute present in back end but lacks clarity as it is not mapped properly in ETL
    // cm2000.htc_0_atc_master as Configurable,
  ],
  ControlModesCM2000: [
    // 14/05/2024 : Attribute present in back end but lacks clarity about the values to show in drop down
    // cm2000.htc_0_switch_control_mode as Configurable,
    cm2000.htc_0_deadband as Configurable,
  ],
  MiscellaneousCM2000: [
    updateBooleanAttributes(cm2000.htc_0_non_latching_temperature_alarms as Configurable, latchAttributes),
    updateBooleanAttributes(cm2000.console_0_temperature_units as Configurable, temperatureAttributes),
  ],
  HighTS1TemperatureCM2000: [
    updateBooleanAttributes(cm2000.temperature_0_high_mask as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.temperature_0_high_priority as Configurable, priorityAttributes),
    cm2000.temperature_0_high_alarm_setpoint as Configurable,
  ],
  LowTS1TemperatureCM2000: [
    updateBooleanAttributes(cm2000.temperature_0_low_mask as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.temperature_0_low_priority as Configurable, priorityAttributes),
    cm2000.temperature_0_low_alarm_setpoint as Configurable,
  ],
  TS1FailureCM2000: [
    updateBooleanAttributes(cm2000.temperature_0_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.temperature_0_failure_priority as Configurable, priorityAttributes),
  ],
  HighTS2TemperatureCM2000: [
    updateBooleanAttributes(cm2000.temperature_1_high_mask as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.temperature_1_high_priority as Configurable, priorityAttributes),
    cm2000.temperature_1_high_alarm_setpoint as Configurable,
  ],
  LowTS2TemperatureCM2000: [
    updateBooleanAttributes(cm2000.temperature_1_low_mask as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.temperature_1_low_priority as Configurable, priorityAttributes),
    cm2000.temperature_1_low_alarm_setpoint as Configurable,
  ],
  TS2FailureCM2000: [
    updateBooleanAttributes(cm2000.temperature_1_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.temperature_1_failure_priority as Configurable, priorityAttributes),
  ],
  TSControlFailureCM2000: [
    updateBooleanAttributes(cm2000.htc_0_control_temperature_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.htc_0_control_temperature_failure_priority as Configurable, priorityAttributes),
  ],
  CommunicationFailureCM2000: [
    updateBooleanAttributes(cm2000.htc_0_communication_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.htc_0_communication_failure_priority as Configurable, priorityAttributes),
  ],
  OverCurrentTripCM2000: [
    updateBooleanAttributes(cm2000.htc_0_line_current_0_over_mask as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.htc_0_line_current_0_over_priority as Configurable, priorityAttributes),
  ],
  SwitchFailureCM2000: [
    updateBooleanAttributes(cm2000.htc_0_switch_0_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.htc_0_switch_0_failure_priority as Configurable, priorityAttributes),
  ],
  HTCResetCM2000: [
    updateBooleanAttributes(cm2000.device_reset_mask as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.device_reset_priority as Configurable, priorityAttributes),
  ],
  EEROMDataFailureCM2000: [
    updateBooleanAttributes(cm2000.nvram_0_total_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.nvram_0_total_failure_priority as Configurable, priorityAttributes),
  ],
  CBLimitingCM2000: [
    updateBooleanAttributes(cm2000.htc_0_circuit_breaker_0_limiting_mask as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.htc_0_circuit_breaker_0_limiting_priority as Configurable, priorityAttributes),
  ],
  OutputLimitingCM2000: [
    updateBooleanAttributes(cm2000.htc_0_power_limiting_mask as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.htc_0_power_limiting_priority as Configurable, priorityAttributes),
  ],
  SwitchLimitingCM2000: [
    updateBooleanAttributes(cm2000.htc_0_switch_0_limiting_mask as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.htc_0_switch_0_limiting_priority as Configurable, priorityAttributes),
  ],
  AlarmPrioritiesandOutputCM2000: [
    updateBooleanAttributes(
      updateBooleanAttributes(cm2000.htc_0_local_alarm_priority_enabled as Configurable, enableAttributes),
      priorityAttributes,
    ),
    updateBooleanAttributes(cm2000.alarm_0_normal_state as Configurable, modeAttributes),
    updateBooleanAttributes(cm2000.alarm_0_mode as Configurable, modeAttributes),
  ],
  HighLoadCurrentCM2000: [
    updateBooleanAttributes(cm2000.htc_0_load_current_high_mask as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.htc_0_load_current_high_priority as Configurable, priorityAttributes),
    cm2000.htc_0_load_current_high_alarm_setpoint as Configurable,
  ],
  LowLoadCurrentCM2000: [
    updateBooleanAttributes(cm2000.htc_0_load_current_low_mask as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.htc_0_load_current_low_priority as Configurable, priorityAttributes),
    cm2000.htc_0_load_current_low_alarm_setpoint as Configurable,
  ],
  HighGroundFaultCurrentCM2000: [
    updateBooleanAttributes(cm2000.htc_0_ground_fault_current_high_mask as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.htc_0_ground_fault_current_high_priority as Configurable, priorityAttributes),
    cm2000.htc_0_ground_fault_current_high_alarm_setpoint as Configurable,
  ],
  GroundFaultTripCurrentCM2000: [
    updateBooleanAttributes(cm2000.htc_0_ground_fault_trip_mask as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.htc_0_ground_fault_trip_priority as Configurable, priorityAttributes),
    cm2000.htc_0_ground_fault_current_trip_setpoint as Configurable,
  ],
  HighVoltageCM2000: [
    updateBooleanAttributes(cm2000.htc_0_line_voltage_high_mask as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.htc_0_line_voltage_high_priority as Configurable, priorityAttributes),
    cm2000.htc_0_voltage_high_alarm_setpoint as Configurable,
  ],
  LowVoltageCM2000: [
    updateBooleanAttributes(cm2000.htc_0_line_voltage_low_mask as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.htc_0_line_voltage_low_priority as Configurable, priorityAttributes),
    cm2000.htc_0_voltage_low_alarm_setpoint as Configurable,
  ],
  ElectricalSetupCM2000: [
    cm2000.htc_0_ssr_rating as Configurable,
    cm2000.htc_0_circuit_breaker_0_current_rating as Configurable,
    cm2000.htc_0_power_limiting_mask as Configurable,
    cm2000.htc_0_maximum_power_setpoint as Configurable,
    cm2000.htc_0_voltage_turns_ratio as Configurable,
  ],
  LoadSheddingCM2000: [
    updateBooleanAttributes(cm2000.htc_0_load_shed_enabled as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.htc_0_load_shed_fail_safe_enabled as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.htc_0_load_shed_zone_0_enabled as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.htc_0_load_shed_zone_1_enabled as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.htc_0_load_shed_zone_2_enabled as Configurable, enableAttributes),
    updateBooleanAttributes(cm2000.htc_0_load_shed_zone_3_enabled as Configurable, enableAttributes),
  ],
  AutocycleCM2000: [
    updateBooleanAttributes(cm2000.htc_0_auto_cycle_enabled as Configurable, enableAttributes),
    cm2000.htc_0_auto_cycle_interval as Configurable,
    updateBooleanAttributes(cm2000.htc_0_auto_cycle_unit as Configurable, temperatureAttributes),
  ],
};
