import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AlarmUIModel } from '@app/models/alarm';
import { DataArrayModel, DeviceUIModel } from '@app/models/device';
import { AttributesInfo } from '@app/models/table-items';
import { DataStoreService } from '@app/services/data-store.service';
import { alarmListColumns, displayedAlarmColumnsInList } from '@app/utils/constants/alarm-constants';
import { DashboardStoreService } from '@services/dashboard-store.service';
import { ExportCsvService } from '@services/export-csv.service';
import { Page } from '@utils/enums/page-enum';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-alarms',
  templateUrl: './alarms.component.html',
  styleUrl: './alarms.component.css',
})
export class AlarmsComponent implements OnInit {
  @BlockUI() blockUI!: NgBlockUI;
  pageTitle = Page.Alarms;
  destroyRef = inject(DestroyRef);
  alarmsData!: AlarmUIModel[];
  listOfTableColumns: AttributesInfo[] = alarmListColumns;
  displayedColumns: string[] = displayedAlarmColumnsInList;
  isFilterVisible = false;
  isFilterOptionHidden = false;
  filteredAlarmsData: AlarmUIModel[] = [];
  devicesData: DeviceUIModel[] = [];
  searchString!: string;

  constructor(
    private dataStoreService: DataStoreService,
    private dashboardStoreService: DashboardStoreService,
    private exportCsvService: ExportCsvService,
  ) {}

  ngOnInit(): void {
    this.isFilterVisible = this.dashboardStoreService.getFilter() ? true : false;
    this.getAlarmsDataFromDataStore();
  }

  filterAlarmsData(searchString: string) {
    this.searchString = searchString;
    if (this.alarmsData)
      this.filteredAlarmsData = this.alarmsData.filter(alarm => {
        return alarm.deviceTag!.toLowerCase().includes(searchString);
      });
  }

  exportTableData(): void {
    this.exportCsvService.exportAlarmListButton.next(true);
  }

  toggleFilter(): void {
    this.isFilterVisible = !this.isFilterVisible;
  }

  getAlarmsDataFromDataStore(): void {
    this.dataStoreService
      .getAlarmsData()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (alarmsData: DataArrayModel<AlarmUIModel>) => {
          if (alarmsData.isLoading) {
            this.blockUI.start('Loading Alarms...');
          } else if (!alarmsData.isLoading && alarmsData.data.length) {
            this.alarmsData = alarmsData.data;
            if (this.searchString) this.filterAlarmsData(this.searchString);
            else this.filteredAlarmsData = this.alarmsData;
            this.blockUI.stop();
          }
        },
        error: (err: unknown) => {
          console.error('Error getting devices from Redis', err);
          this.blockUI.stop();
        },
      });
  }
}
