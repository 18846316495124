import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { Configurable, Context } from '@models/configurator-models/form-fields';

const setControlState = (control: AbstractControl, condition: boolean, ...articles: string[]) => {
  articles.forEach(article => {
    if (condition) {
      control.parent?.get(article)?.enable();
    } else {
      control.parent?.get(article)?.disable();
      control.parent?.get(article)?.reset();
    }
  });
};

export const ngc40io = {
  agent_communication_failure_limit: {
    article: 'agent_communication_failure_limit',
    defaultValue: '1h',
    group: 'Regs/Config/ControllerSetupParameters/AgentCommunicationFailureLimit',
    label: 'Agent communication failure limit',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  agent_communication_failure_mask: {
    article: 'agent_communication_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/AgentCommunicationFailure',
    label: 'Agent communication failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_0_mode: {
    article: 'alarm_0_mode',
    defaultValue: 'NORMAL_OPERATION',
    group: 'Regs/Config/ControllerSetupParameters/AlarmOutputMode',
    label: 'Alarm output mode',
    options: [
      { key: 0, value: 'NORMAL_OPERATION', displayText: 'NORMAL OPERATION' },
      { key: 1, value: 'TOGGLE', displayText: 'TOGGLE' },
      { key: 2, value: 'FLASH', displayText: 'FLASH' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value === 'TOGGLE', ngc40io.alarm_0_toggle_time.article);
      };
    },
  },
  alarm_0_toggle_time: {
    article: 'alarm_0_toggle_time',
    defaultValue: '60s',
    group: 'Regs/Config/ControllerSetupParameters/AlarmOutputToggleTime',
    label: 'Alarm output toggle time',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 240;

        control.setValidators([Validators.required, Validators.max(this.max), Validators.min(this.min)]);
      };
    },
  } as Configurable,
  alarm_source_0_failure_latch: {
    article: 'alarm_source_0_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 0 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_0_failure_mask: {
    article: 'alarm_source_0_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 0 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_0_path.article);
      };
    },
  },
  alarm_source_0_path: {
    article: 'alarm_source_0_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 0 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_10_failure_latch: {
    article: 'alarm_source_10_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 10 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_10_failure_mask: {
    article: 'alarm_source_10_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 10 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_10_path.article);
      };
    },
  },
  alarm_source_10_path: {
    article: 'alarm_source_10_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 10 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_11_failure_latch: {
    article: 'alarm_source_11_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 11 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_11_failure_mask: {
    article: 'alarm_source_11_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 11 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_11_path.article);
      };
    },
  },
  alarm_source_11_path: {
    article: 'alarm_source_11_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 11 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_12_failure_latch: {
    article: 'alarm_source_12_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 12 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_12_failure_mask: {
    article: 'alarm_source_12_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 12 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_12_path.article);
      };
    },
  },
  alarm_source_12_path: {
    article: 'alarm_source_12_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 12 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_13_failure_latch: {
    article: 'alarm_source_13_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 13 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_13_failure_mask: {
    article: 'alarm_source_13_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 13 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_13_path.article);
      };
    },
  },
  alarm_source_13_path: {
    article: 'alarm_source_13_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 13 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_14_failure_latch: {
    article: 'alarm_source_14_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 14 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_14_failure_mask: {
    article: 'alarm_source_14_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 14 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_14_path.article);
      };
    },
  },
  alarm_source_14_path: {
    article: 'alarm_source_14_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 14 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_15_failure_latch: {
    article: 'alarm_source_15_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 15 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_15_failure_mask: {
    article: 'alarm_source_15_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 15 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_15_path.article);
      };
    },
  },
  alarm_source_15_path: {
    article: 'alarm_source_15_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 15 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_16_failure_latch: {
    article: 'alarm_source_16_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 16 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_16_failure_mask: {
    article: 'alarm_source_16_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 16 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_16_path.article);
      };
    },
  },
  alarm_source_16_path: {
    article: 'alarm_source_16_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 16 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_17_failure_latch: {
    article: 'alarm_source_17_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 17 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_17_failure_mask: {
    article: 'alarm_source_17_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 17 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_17_path.article);
      };
    },
  },
  alarm_source_17_path: {
    article: 'alarm_source_17_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 17 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_18_failure_latch: {
    article: 'alarm_source_18_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 18 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_18_failure_mask: {
    article: 'alarm_source_18_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 18 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_18_path.article);
      };
    },
  },
  alarm_source_18_path: {
    article: 'alarm_source_18_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 18 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_19_failure_latch: {
    article: 'alarm_source_19_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 19 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_19_failure_mask: {
    article: 'alarm_source_19_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 19 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_19_path.article);
      };
    },
  },
  alarm_source_19_path: {
    article: 'alarm_source_19_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 19 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_1_failure_latch: {
    article: 'alarm_source_1_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 1 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_1_failure_mask: {
    article: 'alarm_source_1_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 1 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_1_path.article);
      };
    },
  },
  alarm_source_1_path: {
    article: 'alarm_source_1_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 1 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_20_failure_latch: {
    article: 'alarm_source_20_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 20 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_20_failure_mask: {
    article: 'alarm_source_20_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 20 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_20_path.article);
      };
    },
  },
  alarm_source_20_path: {
    article: 'alarm_source_20_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 20 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_21_failure_latch: {
    article: 'alarm_source_21_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 21 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_21_failure_mask: {
    article: 'alarm_source_21_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 21 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_21_path.article);
      };
    },
  },
  alarm_source_21_path: {
    article: 'alarm_source_21_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 21 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_22_failure_latch: {
    article: 'alarm_source_22_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 22 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_22_failure_mask: {
    article: 'alarm_source_22_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 22 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_22_path.article);
      };
    },
  },
  alarm_source_22_path: {
    article: 'alarm_source_22_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 22 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_23_failure_latch: {
    article: 'alarm_source_23_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 23 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_23_failure_mask: {
    article: 'alarm_source_23_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 23 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_23_path.article);
      };
    },
  },
  alarm_source_23_path: {
    article: 'alarm_source_23_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 23 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_24_failure_latch: {
    article: 'alarm_source_24_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 24 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_24_failure_mask: {
    article: 'alarm_source_24_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 24 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_24_path.article);
      };
    },
  },
  alarm_source_24_path: {
    article: 'alarm_source_24_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 24 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_25_failure_latch: {
    article: 'alarm_source_25_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 25 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_25_failure_mask: {
    article: 'alarm_source_25_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 25 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_25_path.article);
      };
    },
  },
  alarm_source_25_path: {
    article: 'alarm_source_25_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 25 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_26_failure_latch: {
    article: 'alarm_source_26_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 26 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_26_failure_mask: {
    article: 'alarm_source_26_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 26 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_26_path.article);
      };
    },
  },
  alarm_source_26_path: {
    article: 'alarm_source_26_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 26 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_27_failure_latch: {
    article: 'alarm_source_27_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 27 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_27_failure_mask: {
    article: 'alarm_source_27_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 27 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_27_path.article);
      };
    },
  },
  alarm_source_27_path: {
    article: 'alarm_source_27_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 27 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_28_failure_latch: {
    article: 'alarm_source_28_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 28 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_28_failure_mask: {
    article: 'alarm_source_28_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 28 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_28_path.article);
      };
    },
  },
  alarm_source_28_path: {
    article: 'alarm_source_28_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 28 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_29_failure_latch: {
    article: 'alarm_source_29_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 29 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_29_failure_mask: {
    article: 'alarm_source_29_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 29 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_29_path.article);
      };
    },
  },
  alarm_source_29_path: {
    article: 'alarm_source_29_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 29 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_2_failure_latch: {
    article: 'alarm_source_2_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 2 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_2_failure_mask: {
    article: 'alarm_source_2_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 2 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_2_path.article);
      };
    },
  },
  alarm_source_2_path: {
    article: 'alarm_source_2_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 2 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_30_failure_latch: {
    article: 'alarm_source_30_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 30 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_30_failure_mask: {
    article: 'alarm_source_30_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 30 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_30_path.article);
      };
    },
  },
  alarm_source_30_path: {
    article: 'alarm_source_30_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 30 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_31_failure_latch: {
    article: 'alarm_source_31_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 31 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_31_failure_mask: {
    article: 'alarm_source_31_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 31 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_31_path.article);
      };
    },
  },
  alarm_source_31_path: {
    article: 'alarm_source_31_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 31 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_32_failure_latch: {
    article: 'alarm_source_32_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 32 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_32_failure_mask: {
    article: 'alarm_source_32_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 32 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_32_path.article);
      };
    },
  },
  alarm_source_32_path: {
    article: 'alarm_source_32_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 32 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_33_failure_latch: {
    article: 'alarm_source_33_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 33 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_33_failure_mask: {
    article: 'alarm_source_33_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 33 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_33_path.article);
      };
    },
  },
  alarm_source_33_path: {
    article: 'alarm_source_33_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 33 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_34_failure_latch: {
    article: 'alarm_source_34_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 34 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_34_failure_mask: {
    article: 'alarm_source_34_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 34 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_34_path.article);
      };
    },
  },
  alarm_source_34_path: {
    article: 'alarm_source_34_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 34 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_35_failure_latch: {
    article: 'alarm_source_35_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 35 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_35_failure_mask: {
    article: 'alarm_source_35_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 35 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_35_path.article);
      };
    },
  },
  alarm_source_35_path: {
    article: 'alarm_source_35_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 35 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_36_failure_latch: {
    article: 'alarm_source_36_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 36 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_36_failure_mask: {
    article: 'alarm_source_36_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 36 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_36_path.article);
      };
    },
  },
  alarm_source_36_path: {
    article: 'alarm_source_36_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 36 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_37_failure_latch: {
    article: 'alarm_source_37_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 37 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_37_failure_mask: {
    article: 'alarm_source_37_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 37 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_37_path.article);
      };
    },
  },
  alarm_source_37_path: {
    article: 'alarm_source_37_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 37 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_38_failure_latch: {
    article: 'alarm_source_38_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 38 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_38_failure_mask: {
    article: 'alarm_source_38_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 38 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_38_path.article);
      };
    },
  },
  alarm_source_38_path: {
    article: 'alarm_source_38_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 38 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_39_failure_latch: {
    article: 'alarm_source_39_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 39 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_39_failure_mask: {
    article: 'alarm_source_39_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 39 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_39_path.article);
      };
    },
  },
  alarm_source_39_path: {
    article: 'alarm_source_39_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 39 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_3_failure_latch: {
    article: 'alarm_source_3_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 3 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_3_failure_mask: {
    article: 'alarm_source_3_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 3 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_3_path.article);
      };
    },
  },
  alarm_source_3_path: {
    article: 'alarm_source_3_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 3 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_40_failure_latch: {
    article: 'alarm_source_40_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 40 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_40_failure_mask: {
    article: 'alarm_source_40_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 40 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_40_path.article);
      };
    },
  },
  alarm_source_40_path: {
    article: 'alarm_source_40_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 40 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_41_failure_latch: {
    article: 'alarm_source_41_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 41 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_41_failure_mask: {
    article: 'alarm_source_41_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 41 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_41_path.article);
      };
    },
  },
  alarm_source_41_path: {
    article: 'alarm_source_41_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 41 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_42_failure_latch: {
    article: 'alarm_source_42_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 42 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_42_failure_mask: {
    article: 'alarm_source_42_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 42 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_42_path.article);
      };
    },
  },
  alarm_source_42_path: {
    article: 'alarm_source_42_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 42 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_43_failure_latch: {
    article: 'alarm_source_43_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 43 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_43_failure_mask: {
    article: 'alarm_source_43_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 43 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_43_path.article);
      };
    },
  },
  alarm_source_43_path: {
    article: 'alarm_source_43_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 43 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_44_failure_latch: {
    article: 'alarm_source_44_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 44 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_44_failure_mask: {
    article: 'alarm_source_44_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 44 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_44_path.article);
      };
    },
  },
  alarm_source_44_path: {
    article: 'alarm_source_44_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 44 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_45_failure_latch: {
    article: 'alarm_source_45_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 45 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_45_failure_mask: {
    article: 'alarm_source_45_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 45 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_45_path.article);
      };
    },
  },
  alarm_source_45_path: {
    article: 'alarm_source_45_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 45 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_46_failure_latch: {
    article: 'alarm_source_46_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 46 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_46_failure_mask: {
    article: 'alarm_source_46_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 46 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_46_path.article);
      };
    },
  },
  alarm_source_46_path: {
    article: 'alarm_source_46_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 46 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_47_failure_latch: {
    article: 'alarm_source_47_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 47 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_47_failure_mask: {
    article: 'alarm_source_47_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 47 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_47_path.article);
      };
    },
  },
  alarm_source_47_path: {
    article: 'alarm_source_47_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 47 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_48_failure_latch: {
    article: 'alarm_source_48_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 48 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_48_failure_mask: {
    article: 'alarm_source_48_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 48 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_48_path.article);
      };
    },
  },
  alarm_source_48_path: {
    article: 'alarm_source_48_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 48 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_49_failure_latch: {
    article: 'alarm_source_49_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 49 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_49_failure_mask: {
    article: 'alarm_source_49_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 49 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_49_path.article);
      };
    },
  },
  alarm_source_49_path: {
    article: 'alarm_source_49_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 49 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_4_failure_latch: {
    article: 'alarm_source_4_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 4 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_4_failure_mask: {
    article: 'alarm_source_4_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 4 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_4_path.article);
      };
    },
  },
  alarm_source_4_path: {
    article: 'alarm_source_4_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 4 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_50_failure_latch: {
    article: 'alarm_source_50_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 50 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_50_failure_mask: {
    article: 'alarm_source_50_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 50 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_50_path.article);
      };
    },
  },
  alarm_source_50_path: {
    article: 'alarm_source_50_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 50 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_51_failure_latch: {
    article: 'alarm_source_51_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 51 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_51_failure_mask: {
    article: 'alarm_source_51_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 51 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_51_path.article);
      };
    },
  },
  alarm_source_51_path: {
    article: 'alarm_source_51_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 51 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_52_failure_latch: {
    article: 'alarm_source_52_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 52 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_52_failure_mask: {
    article: 'alarm_source_52_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 52 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_52_path.article);
      };
    },
  },
  alarm_source_52_path: {
    article: 'alarm_source_52_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 52 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_53_failure_latch: {
    article: 'alarm_source_53_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 53 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_53_failure_mask: {
    article: 'alarm_source_53_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 53 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_53_path.article);
      };
    },
  },
  alarm_source_53_path: {
    article: 'alarm_source_53_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 53 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_54_failure_latch: {
    article: 'alarm_source_54_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 54 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_54_failure_mask: {
    article: 'alarm_source_54_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 54 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_54_path.article);
      };
    },
  },
  alarm_source_54_path: {
    article: 'alarm_source_54_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 54 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_55_failure_latch: {
    article: 'alarm_source_55_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 55 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_55_failure_mask: {
    article: 'alarm_source_55_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 55 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_55_path.article);
      };
    },
  },
  alarm_source_55_path: {
    article: 'alarm_source_55_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 55 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_56_failure_latch: {
    article: 'alarm_source_56_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 56 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_56_failure_mask: {
    article: 'alarm_source_56_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 56 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_56_path.article);
      };
    },
  },
  alarm_source_56_path: {
    article: 'alarm_source_56_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 56 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_57_failure_latch: {
    article: 'alarm_source_57_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 57 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_57_failure_mask: {
    article: 'alarm_source_57_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 57 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_57_path.article);
      };
    },
  },
  alarm_source_57_path: {
    article: 'alarm_source_57_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 57 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_58_failure_latch: {
    article: 'alarm_source_58_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 58 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_58_failure_mask: {
    article: 'alarm_source_58_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 58 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_58_path.article);
      };
    },
  },
  alarm_source_58_path: {
    article: 'alarm_source_58_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 58 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_59_failure_latch: {
    article: 'alarm_source_59_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 59 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_59_failure_mask: {
    article: 'alarm_source_59_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 59 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_59_path.article);
      };
    },
  },
  alarm_source_59_path: {
    article: 'alarm_source_59_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 59 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_5_failure_latch: {
    article: 'alarm_source_5_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 5 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_5_failure_mask: {
    article: 'alarm_source_5_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 5 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_5_path.article);
      };
    },
  },
  alarm_source_5_path: {
    article: 'alarm_source_5_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 5 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_60_failure_latch: {
    article: 'alarm_source_60_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 60 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_60_failure_mask: {
    article: 'alarm_source_60_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 60 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_60_path.article);
      };
    },
  },
  alarm_source_60_path: {
    article: 'alarm_source_60_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 60 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_61_failure_latch: {
    article: 'alarm_source_61_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 61 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_61_failure_mask: {
    article: 'alarm_source_61_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 61 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_61_path.article);
      };
    },
  },
  alarm_source_61_path: {
    article: 'alarm_source_61_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 61 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_62_failure_latch: {
    article: 'alarm_source_62_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 62 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_62_failure_mask: {
    article: 'alarm_source_62_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 62 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_62_path.article);
      };
    },
  },
  alarm_source_62_path: {
    article: 'alarm_source_62_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 62 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_63_failure_latch: {
    article: 'alarm_source_63_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 63 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_63_failure_mask: {
    article: 'alarm_source_63_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 63 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_63_path.article);
      };
    },
  },
  alarm_source_63_path: {
    article: 'alarm_source_63_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 63 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_64_failure_latch: {
    article: 'alarm_source_64_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 64 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_64_failure_mask: {
    article: 'alarm_source_64_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 64 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_64_path.article);
      };
    },
  },
  alarm_source_64_path: {
    article: 'alarm_source_64_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 64 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_65_failure_latch: {
    article: 'alarm_source_65_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 65 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_65_failure_mask: {
    article: 'alarm_source_65_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 65 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_65_path.article);
      };
    },
  },
  alarm_source_65_path: {
    article: 'alarm_source_65_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 65 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_66_failure_latch: {
    article: 'alarm_source_66_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 66 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_66_failure_mask: {
    article: 'alarm_source_66_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 66 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_66_path.article);
      };
    },
  },
  alarm_source_66_path: {
    article: 'alarm_source_66_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 66 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_67_failure_latch: {
    article: 'alarm_source_67_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 67 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_67_failure_mask: {
    article: 'alarm_source_67_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 67 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_67_path.article);
      };
    },
  },
  alarm_source_67_path: {
    article: 'alarm_source_67_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 67 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_68_failure_latch: {
    article: 'alarm_source_68_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 68 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_68_failure_mask: {
    article: 'alarm_source_68_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 68 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_68_path.article);
      };
    },
  },
  alarm_source_68_path: {
    article: 'alarm_source_68_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 68 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_69_failure_latch: {
    article: 'alarm_source_69_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 69 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_69_failure_mask: {
    article: 'alarm_source_69_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 69 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_69_path.article);
      };
    },
  },
  alarm_source_69_path: {
    article: 'alarm_source_69_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 69 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_6_failure_latch: {
    article: 'alarm_source_6_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 6 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_6_failure_mask: {
    article: 'alarm_source_6_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 6 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_6_path.article);
      };
    },
  },
  alarm_source_6_path: {
    article: 'alarm_source_6_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 6 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_70_failure_latch: {
    article: 'alarm_source_70_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 70 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_70_failure_mask: {
    article: 'alarm_source_70_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 70 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_70_path.article);
      };
    },
  },
  alarm_source_70_path: {
    article: 'alarm_source_70_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 70 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_71_failure_latch: {
    article: 'alarm_source_71_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 71 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_71_failure_mask: {
    article: 'alarm_source_71_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 71 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_71_path.article);
      };
    },
  },
  alarm_source_71_path: {
    article: 'alarm_source_71_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 71 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_72_failure_latch: {
    article: 'alarm_source_72_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 72 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_72_failure_mask: {
    article: 'alarm_source_72_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 72 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_72_path.article);
      };
    },
  },
  alarm_source_72_path: {
    article: 'alarm_source_72_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 72 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_73_failure_latch: {
    article: 'alarm_source_73_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 73 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_73_failure_mask: {
    article: 'alarm_source_73_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 73 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_73_path.article);
      };
    },
  },
  alarm_source_73_path: {
    article: 'alarm_source_73_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 73 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_74_failure_latch: {
    article: 'alarm_source_74_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 74 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_74_failure_mask: {
    article: 'alarm_source_74_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 74 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_74_path.article);
      };
    },
  },
  alarm_source_74_path: {
    article: 'alarm_source_74_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 74 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_75_failure_latch: {
    article: 'alarm_source_75_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 75 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_75_failure_mask: {
    article: 'alarm_source_75_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 75 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_75_path.article);
      };
    },
  },
  alarm_source_75_path: {
    article: 'alarm_source_75_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 75 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_76_failure_latch: {
    article: 'alarm_source_76_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 76 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_76_failure_mask: {
    article: 'alarm_source_76_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 76 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_76_path.article);
      };
    },
  },
  alarm_source_76_path: {
    article: 'alarm_source_76_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 76 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_77_failure_latch: {
    article: 'alarm_source_77_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 77 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_77_failure_mask: {
    article: 'alarm_source_77_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 77 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_77_path.article);
      };
    },
  },
  alarm_source_77_path: {
    article: 'alarm_source_77_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 77 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_78_failure_latch: {
    article: 'alarm_source_78_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 78 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_78_failure_mask: {
    article: 'alarm_source_78_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 78 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_78_path.article);
      };
    },
  },
  alarm_source_78_path: {
    article: 'alarm_source_78_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 78 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_79_failure_latch: {
    article: 'alarm_source_79_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 79 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_79_failure_mask: {
    article: 'alarm_source_79_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 79 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_79_path.article);
      };
    },
  },
  alarm_source_79_path: {
    article: 'alarm_source_79_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 79 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_7_failure_latch: {
    article: 'alarm_source_7_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 7 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_7_failure_mask: {
    article: 'alarm_source_7_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 7 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_7_path.article);
      };
    },
  },
  alarm_source_7_path: {
    article: 'alarm_source_7_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 7 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_8_failure_latch: {
    article: 'alarm_source_8_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 8 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_8_failure_mask: {
    article: 'alarm_source_8_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 8 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_8_path.article);
      };
    },
  },
  alarm_source_8_path: {
    article: 'alarm_source_8_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 8 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_9_failure_latch: {
    article: 'alarm_source_9_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/AlarmSourceFailure/elem',
    label: 'Alarm source 9 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_source_9_failure_mask: {
    article: 'alarm_source_9_failure_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/AlarmSourceFailure/elem',
    label: 'Alarm source 9 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(control, control.value, ngc40io.alarm_source_9_path.article);
      };
    },
  },
  alarm_source_9_path: {
    article: 'alarm_source_9_path',
    defaultValue: 'None',
    group: 'Regs/Config/AlarmSourceSetup/CANNetworkDeviceID/elem',
    label: 'Alarm source 9 path',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  device_reset_mask: {
    article: 'device_reset_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/DeviceReset',
    label: 'Device reset mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  device_tag: {
    article: 'device_tag',
    defaultValue: 'NGC40-IO-{CAN Address}',
    group: 'Regs/Config/ControllerSetupParameters/Tag',
    label: 'Tag',
    type: 'string16',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.max = 40;
        this.min = 1;
        control.setValidators([Validators.maxLength(this.max), Validators.minLength(this.min)]);
      };
    },
  } as Configurable,
  digital_input_0_alarm_latch: {
    article: 'digital_input_0_alarm_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/DigitalInput',
    label: 'Digital input latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  digital_input_source_0_mode: {
    article: 'digital_input_source_0_mode',
    defaultValue: 'NOT_USED',
    group: 'Regs/Config/ControllerSetupParameters/DigitalInputConfiguration',
    label: 'Digital input configuration',
    options: [
      { key: 0, value: 'NOT_USED', displayText: 'NOT USED' },
      {
        key: 1,
        value: 'ALARM_WHEN_DIGITAL_INPUT_LOCAL_REMOTE_CLOSED',
        displayText: 'ALARM WHEN DIGITAL INPUT LOCAL REMOTE CLOSED',
      },
      {
        key: 2,
        value: 'ALARM_WHEN_DIGITAL_INPUT_LOCAL_REMOTE_OPEN',
        displayText: 'ALARM WHEN DIGITAL INPUT LOCAL REMOTE OPEN',
      },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  nvram_0_factory_configuration_data_lost_mask: {
    article: 'nvram_0_factory_configuration_data_lost_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/FactoryConfigurationDataLost',
    label: 'Factory configuration data lost mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  nvram_0_user_configuration_data_lost_mask: {
    article: 'nvram_0_user_configuration_data_lost_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/UserConfigurationDataLost',
    label: 'User configuration data lost mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_0_failure_latch: {
    article: 'temperature_0_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/TemperatureFailure/elem',
    label: 'Temperature 0 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_0_failure_mask: {
    article: 'temperature_0_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/TemperatureFailure/elem',
    label: 'Temperature 0 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_0_high_alarm_filter: {
    article: 'temperature_0_high_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/TemperatureSensorSetup/elem/HighAlarmFilter',
    label: 'High alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 59940;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_0_high_alarm_setpoint: {
    article: 'temperature_0_high_alarm_setpoint',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSensorSetup/elem/HighAlarmSetpoint',
    label: 'High alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = -80.0;
        this.max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          this.min = -112.0;
          this.max = 1292.0;
        }
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_0_high_latch: {
    article: 'temperature_0_high_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/TemperatureHigh/elem',
    label: 'Temperature 0 high latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_0_high_mask: {
    article: 'temperature_0_high_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/TemperatureHigh/elem',
    label: 'Temperature 0 high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(
          control,
          control.value,
          ngc40io.temperature_0_high_alarm_setpoint.article,
          ngc40io.temperature_0_high_alarm_filter.article,
        );
      };
    },
  },
  temperature_0_lead_resistance: {
    article: 'temperature_0_lead_resistance',
    defaultValue: '0.00Ω',
    group: 'Regs/Config/TemperatureSensorSetup/elem/RTDLeadResistance',
    label: 'Rtd lead resistance',
    type: 'range',
    unit: 'Ω',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.0;
        this.max = 20.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_0_low_alarm_filter: {
    article: 'temperature_0_low_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/TemperatureSensorSetup/elem/LowAlarmFilter',
    label: 'Low alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 59940;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_0_low_alarm_setpoint: {
    article: 'temperature_0_low_alarm_setpoint',
    defaultValue: '5.0°C',
    group: 'Regs/Config/TemperatureSensorSetup/elem/LowAlarmSetpoint',
    label: 'Low alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = -80.0;
        this.max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          this.min = -112.0;
          this.max = 1292.0;
        }
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_0_low_latch: {
    article: 'temperature_0_low_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/TemperatureLow/elem',
    label: 'Temperature 0 low latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_0_low_mask: {
    article: 'temperature_0_low_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/TemperatureLow/elem',
    label: 'Temperature 0 low mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(
          control,
          control.value,
          ngc40io.temperature_0_low_alarm_setpoint.article,
          ngc40io.temperature_0_low_alarm_filter.article,
        );
      };
    },
  },
  temperature_0_tag: {
    article: 'temperature_0_tag',
    defaultValue: 'NGC40-IO-{CAN Address}',
    group: 'Regs/Config/TemperatureSensorSetup/elem/Tag',
    label: 'Tag',
    type: 'string16',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.max = 40;
        this.min = 1;
        control.setValidators([Validators.maxLength(this.max), Validators.minLength(this.min)]);
      };
    },
  } as Configurable,
  temperature_0_type: {
    article: 'temperature_0_type',
    defaultValue: 'THREE_WIRE_100_OHM_PLATINUM',
    group: 'Regs/Config/TemperatureSensorSetup/elem/Type',
    label: 'Type',
    options: [
      { key: 0, value: 'NOT_USED', displayText: 'NOT USED' },
      { key: 1, value: 'THREE_WIRE_100_OHM_PLATINUM', displayText: 'THREE WIRE 100 OHM PLATINUM' },
      { key: 2, value: 'TWO_WIRE_100_OHM_NICKEL_IRON', displayText: 'TWO WIRE 100 OHM NICKEL IRON' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(
          control,
          control.value === 'TWO_WIRE_100_OHM_NICKEL_IRON',
          ngc40io.temperature_0_lead_resistance.article,
        );
      };
    },
  },
  temperature_1_failure_latch: {
    article: 'temperature_1_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/TemperatureFailure/elem',
    label: 'Temperature 1 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_1_failure_mask: {
    article: 'temperature_1_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/TemperatureFailure/elem',
    label: 'Temperature 1 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_1_high_alarm_filter: {
    article: 'temperature_1_high_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/TemperatureSensorSetup/elem/HighAlarmFilter',
    label: 'High alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 59940;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_1_high_alarm_setpoint: {
    article: 'temperature_1_high_alarm_setpoint',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSensorSetup/elem/HighAlarmSetpoint',
    label: 'High alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = -80.0;
        this.max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          this.min = -112.0;
          this.max = 1292.0;
        }
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_1_high_latch: {
    article: 'temperature_1_high_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/TemperatureHigh/elem',
    label: 'Temperature 1 high latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_1_high_mask: {
    article: 'temperature_1_high_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/TemperatureHigh/elem',
    label: 'Temperature 1 high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(
          control,
          control.value,
          ngc40io.temperature_1_high_alarm_setpoint.article,
          ngc40io.temperature_1_high_alarm_filter.article,
        );
      };
    },
  },
  temperature_1_lead_resistance: {
    article: 'temperature_1_lead_resistance',
    defaultValue: '0.00Ω',
    group: 'Regs/Config/TemperatureSensorSetup/elem/RTDLeadResistance',
    label: 'Rtd lead resistance',
    type: 'range',
    unit: 'Ω',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.0;
        this.max = 20.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_1_low_alarm_filter: {
    article: 'temperature_1_low_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/TemperatureSensorSetup/elem/LowAlarmFilter',
    label: 'Low alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 59940;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_1_low_alarm_setpoint: {
    article: 'temperature_1_low_alarm_setpoint',
    defaultValue: '5.0°C',
    group: 'Regs/Config/TemperatureSensorSetup/elem/LowAlarmSetpoint',
    label: 'Low alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = -80.0;
        this.max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          this.min = -112.0;
          this.max = 1292.0;
        }
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_1_low_latch: {
    article: 'temperature_1_low_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/TemperatureLow/elem',
    label: 'Temperature 1 low latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_1_low_mask: {
    article: 'temperature_1_low_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/TemperatureLow/elem',
    label: 'Temperature 1 low mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(
          control,
          control.value,
          ngc40io.temperature_1_low_alarm_setpoint.article,
          ngc40io.temperature_1_low_alarm_filter.article,
        );
      };
    },
  },
  temperature_1_tag: {
    article: 'temperature_1_tag',
    defaultValue: 'NGC40-IO-{CAN Address}',
    group: 'Regs/Config/TemperatureSensorSetup/elem/Tag',
    label: 'Tag',
    type: 'string16',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.max = 40;
        this.min = 1;
        control.setValidators([Validators.maxLength(this.max), Validators.minLength(this.min)]);
      };
    },
  } as Configurable,
  temperature_1_type: {
    article: 'temperature_1_type',
    defaultValue: 'THREE_WIRE_100_OHM_PLATINUM',
    group: 'Regs/Config/TemperatureSensorSetup/elem/Type',
    label: 'Type',
    options: [
      { key: 0, value: 'NOT_USED', displayText: 'NOT USED' },
      { key: 1, value: 'THREE_WIRE_100_OHM_PLATINUM', displayText: 'THREE WIRE 100 OHM PLATINUM' },
      { key: 2, value: 'TWO_WIRE_100_OHM_NICKEL_IRON', displayText: 'TWO WIRE 100 OHM NICKEL IRON' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(
          control,
          control.value === 'TWO_WIRE_100_OHM_NICKEL_IRON',
          ngc40io.temperature_1_lead_resistance.article,
        );
      };
    },
  },
  temperature_2_failure_latch: {
    article: 'temperature_2_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/TemperatureFailure/elem',
    label: 'Temperature 2 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_2_failure_mask: {
    article: 'temperature_2_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/TemperatureFailure/elem',
    label: 'Temperature 2 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_2_high_alarm_filter: {
    article: 'temperature_2_high_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/TemperatureSensorSetup/elem/HighAlarmFilter',
    label: 'High alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 59940;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_2_high_alarm_setpoint: {
    article: 'temperature_2_high_alarm_setpoint',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSensorSetup/elem/HighAlarmSetpoint',
    label: 'High alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = -80.0;
        this.max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          this.min = -112.0;
          this.max = 1292.0;
        }
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_2_high_latch: {
    article: 'temperature_2_high_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/TemperatureHigh/elem',
    label: 'Temperature 2 high latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_2_high_mask: {
    article: 'temperature_2_high_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/TemperatureHigh/elem',
    label: 'Temperature 2 high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(
          control,
          control.value,
          ngc40io.temperature_2_high_alarm_setpoint.article,
          ngc40io.temperature_2_high_alarm_filter.article,
        );
      };
    },
  },
  temperature_2_lead_resistance: {
    article: 'temperature_2_lead_resistance',
    defaultValue: '0.00Ω',
    group: 'Regs/Config/TemperatureSensorSetup/elem/RTDLeadResistance',
    label: 'Rtd lead resistance',
    type: 'range',
    unit: 'Ω',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.0;
        this.max = 20.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_2_low_alarm_filter: {
    article: 'temperature_2_low_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/TemperatureSensorSetup/elem/LowAlarmFilter',
    label: 'Low alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 59940;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_2_low_alarm_setpoint: {
    article: 'temperature_2_low_alarm_setpoint',
    defaultValue: '5.0°C',
    group: 'Regs/Config/TemperatureSensorSetup/elem/LowAlarmSetpoint',
    label: 'Low alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = -80.0;
        this.max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          this.min = -112.0;
          this.max = 1292.0;
        }
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_2_low_latch: {
    article: 'temperature_2_low_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/TemperatureLow/elem',
    label: 'Temperature 2 low latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_2_low_mask: {
    article: 'temperature_2_low_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/TemperatureLow/elem',
    label: 'Temperature 2 low mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(
          control,
          control.value,
          ngc40io.temperature_2_low_alarm_setpoint.article,
          ngc40io.temperature_2_low_alarm_filter.article,
        );
      };
    },
  },
  temperature_2_tag: {
    article: 'temperature_2_tag',
    defaultValue: 'NGC40-IO-{CAN Address}',
    group: 'Regs/Config/TemperatureSensorSetup/elem/Tag',
    label: 'Tag',
    type: 'string16',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.max = 40;
        this.min = 1;
        control.setValidators([Validators.maxLength(this.max), Validators.minLength(this.min)]);
      };
    },
  } as Configurable,
  temperature_2_type: {
    article: 'temperature_2_type',
    defaultValue: 'THREE_WIRE_100_OHM_PLATINUM',
    group: 'Regs/Config/TemperatureSensorSetup/elem/Type',
    label: 'Type',
    options: [
      { key: 0, value: 'NOT_USED', displayText: 'NOT USED' },
      { key: 1, value: 'THREE_WIRE_100_OHM_PLATINUM', displayText: 'THREE WIRE 100 OHM PLATINUM' },
      { key: 2, value: 'TWO_WIRE_100_OHM_NICKEL_IRON', displayText: 'TWO WIRE 100 OHM NICKEL IRON' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(
          control,
          control.value === 'TWO_WIRE_100_OHM_NICKEL_IRON',
          ngc40io.temperature_2_lead_resistance.article,
        );
      };
    },
  },
  temperature_3_failure_latch: {
    article: 'temperature_3_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/TemperatureFailure/elem',
    label: 'Temperature 3 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_3_failure_mask: {
    article: 'temperature_3_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/TemperatureFailure/elem',
    label: 'Temperature 3 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_3_high_alarm_filter: {
    article: 'temperature_3_high_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/TemperatureSensorSetup/elem/HighAlarmFilter',
    label: 'High alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 59940;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_3_high_alarm_setpoint: {
    article: 'temperature_3_high_alarm_setpoint',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSensorSetup/elem/HighAlarmSetpoint',
    label: 'High alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = -80.0;
        this.max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          this.min = -112.0;
          this.max = 1292.0;
        }
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_3_high_latch: {
    article: 'temperature_3_high_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/TemperatureHigh/elem',
    label: 'Temperature 3 high latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_3_high_mask: {
    article: 'temperature_3_high_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/TemperatureHigh/elem',
    label: 'Temperature 3 high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(
          control,
          control.value,
          ngc40io.temperature_3_high_alarm_setpoint.article,
          ngc40io.temperature_3_high_alarm_filter.article,
        );
      };
    },
  },
  temperature_3_lead_resistance: {
    article: 'temperature_3_lead_resistance',
    defaultValue: '0.00Ω',
    group: 'Regs/Config/TemperatureSensorSetup/elem/RTDLeadResistance',
    label: 'Rtd lead resistance',
    type: 'range',
    unit: 'Ω',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.0;
        this.max = 20.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_3_low_alarm_filter: {
    article: 'temperature_3_low_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/TemperatureSensorSetup/elem/LowAlarmFilter',
    label: 'Low alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 59940;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_3_low_alarm_setpoint: {
    article: 'temperature_3_low_alarm_setpoint',
    defaultValue: '5.0°C',
    group: 'Regs/Config/TemperatureSensorSetup/elem/LowAlarmSetpoint',
    label: 'Low alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = -80.0;
        this.max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          this.min = -112.0;
          this.max = 1292.0;
        }
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_3_low_latch: {
    article: 'temperature_3_low_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/TemperatureLow/elem',
    label: 'Temperature 3 low latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_3_low_mask: {
    article: 'temperature_3_low_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/TemperatureLow/elem',
    label: 'Temperature 3 low mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(
          control,
          control.value,
          ngc40io.temperature_3_low_alarm_setpoint.article,
          ngc40io.temperature_3_low_alarm_filter.article,
        );
      };
    },
  },
  temperature_3_tag: {
    article: 'temperature_3_tag',
    defaultValue: 'NGC40-IO-{CAN Address}',
    group: 'Regs/Config/TemperatureSensorSetup/elem/Tag',
    label: 'Tag',
    type: 'string16',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.max = 40;
        this.min = 1;
        control.setValidators([Validators.maxLength(this.max), Validators.minLength(this.min)]);
      };
    },
  } as Configurable,
  temperature_3_type: {
    article: 'temperature_3_type',
    defaultValue: 'THREE_WIRE_100_OHM_PLATINUM',
    group: 'Regs/Config/TemperatureSensorSetup/elem/Type',
    label: 'Type',
    options: [
      { key: 0, value: 'NOT_USED', displayText: 'NOT USED' },
      { key: 1, value: 'THREE_WIRE_100_OHM_PLATINUM', displayText: 'THREE WIRE 100 OHM PLATINUM' },
      { key: 2, value: 'TWO_WIRE_100_OHM_NICKEL_IRON', displayText: 'TWO WIRE 100 OHM NICKEL IRON' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        setControlState(
          control,
          control.value === 'TWO_WIRE_100_OHM_NICKEL_IRON',
          ngc40io.temperature_3_lead_resistance.article,
        );
      };
    },
  },
};
