import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from '@env/environment';
import { IdTokenPayload } from '@models/id-token';
import { RoleService } from '@services/jsonapi-services/role.service';
import { UserService } from '@services/jsonapi-services/user.service';
import { KeycloakService } from '@services/keycloak.service';
import { PermissionsService } from '@shared/services/permissions.service';
import { userParam, userPermissionsParams } from '@utils/constants/params';
import { ProdMode } from '@utils/enums/prod-mode-enums';
import { RoleEnum } from '@utils/enums/roles-enum';
import { combineLatest, firstValueFrom, map } from 'rxjs';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  isEulaAccepted = false;

  constructor(
    protected router: Router,
    protected keycloakService: KeycloakService,
    private userService: UserService,
    private auth: AuthService,
    private permissionsService: PermissionsService,
    private roleService: RoleService,
  ) {}

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let url = state.url;
    const isAuthenticated = environment.e2e ? this.auth.isAuthenticated() : this.keycloakService.isAuthenticated();
    if (isAuthenticated) {
      const token = environment.e2e ? this.auth.parseIdToken() : this.keycloakService.getToken();
      const allowedUser = environment.e2e ? true : token.groups && token.groups.includes('/approved-users');
      if (allowedUser) {
        if (!this.isEulaAccepted) {
          const data = await this.getData(token);
          const isEulaAccepted = data.userData.data.length ? data.userData.data[0].attributes?.eula_accepted : false;
          if (isEulaAccepted) {
            this.isEulaAccepted = true;
            return url.includes('terms') || url.includes('approval')
              ? this.router.navigate([''])
              : this.checkPermission(_route);
          }
          return url.includes('terms') ? true : this.router.navigate(['terms']);
        }
        return url.includes('terms') || url.includes('approval')
          ? this.router.navigate([''])
          : this.checkPermission(_route);
      }
      return url.includes('approval') ? true : this.router.navigate(['approval']);
    } else if (environment.prodMode === ProdMode.HmiPanel) {
      const permissions = await firstValueFrom(this.roleService.getMulti(userPermissionsParams(RoleEnum.level1)));
      this.permissionsService.extractPermissions(permissions.included!);
      return this.checkPermission(_route);
    }
    return false;
  }

  checkPermission(route: ActivatedRouteSnapshot) {
    if (route.data && route.data.permission) {
      return this.permissionsService.checkPermission(route.data.permission)
        ? true
        : this.router.navigate(['unauthorized']);
    }
    return true;
  }

  private async getData(token: IdTokenPayload) {
    const role = this.keycloakService.getUserRoles();
    const data = await firstValueFrom(
      combineLatest([
        this.userService.getMulti(userParam(token)),
        this.roleService.getMulti(userPermissionsParams(role[0])),
      ]).pipe(
        map(([user, permissions]) => ({
          userData: user,
          permissionsData: permissions,
        })),
      ),
    );
    this.permissionsService.extractPermissions(data.permissionsData.included!);
    return data;
  }
}
