import { AbstractControl, Validators } from '@angular/forms';
import { Configurable, Context } from '@app/models/configurator-models/form-fields';

export const htc910 = {
  alarm_0_mode: {
    article: 'alarm_0_mode',
    defaultValue: 'False',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterBits/FlashAlarmOutput',
    label: 'Alarm 0 mode',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  console_0_control_temperature_setpoint_lower_bound: {
    article: 'console_0_control_temperature_setpoint_lower_bound',
    defaultValue: '10.0°C',
    group: 'Regs/Config/Console/ControlTemperatureSetpointLowerBound',
    label: 'Control temperature setpoint lower bound',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      this.min = -60.0;
      this.max = 570.0;
      if (ctx?.temperaturePreferenceUnit !== 'Celsius') {
        this.min = (this.min * 9) / 5 + 32;
        this.max = (this.max * 9) / 5 + 32;
      }
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  console_0_control_temperature_setpoint_upper_bound: {
    article: 'console_0_control_temperature_setpoint_upper_bound',
    defaultValue: '10.0°C',
    group: 'Regs/Config/Console/ControlTemperatureSetpointUpperBound',
    label: 'Control temperature setpoint upper bound',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      this.min = -60.0;
      this.max = 570.0;
      if (ctx?.temperaturePreferenceUnit !== 'Celsius') {
        this.min = (this.min * 9) / 5 + 32;
        this.max = (this.max * 9) / 5 + 32;
      }
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  device_point_being_used: {
    article: 'device_point_being_used',
    defaultValue: 'True',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterBits/PointBeingUsed',
    label: 'Device point being used',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  device_reset_mask: {
    article: 'device_reset_mask',
    defaultValue: 'False',
    group: 'Regs/Config/Alarm/Mask/ControllerReset',
    label: 'Device reset mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  device_tag: {
    article: 'device_tag',
    defaultValue: 'HTC910',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterRegs/Tag',
    label: 'Device tag',
    type: 'string16',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(10)]);
      };
    },
  } as Configurable,
  htc_0_auto_cycle_enabled: {
    article: 'htc_0_auto_cycle_enabled',
    defaultValue: 'True',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterBits/AutoCycleEnabled',
    label: 'Auto cycle enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        if (!control.value) {
          control.parent?.get('htc_0_auto_cycle_interval')?.disable();
          control.parent?.get('htc_0_auto_cycle_interval')?.reset();
          control.parent?.get('htc_0_auto_cycle_interval_unit')?.disable();
          control.parent?.get('htc_0_auto_cycle_interval_unit')?.reset();
        } else {
          control.parent?.get('htc_0_auto_cycle_interval')?.enable();
          control.parent?.get('htc_0_auto_cycle_interval_unit')?.enable();
        }
      };
    },
  } as Configurable,
  htc_0_auto_cycle_interval: {
    article: 'htc_0_auto_cycle_interval',
    defaultValue: '8h',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterRegs/AutoCycleInterval',
    label: 'Auto cycle interval',
    type: 'range',
    unit: 'h',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        [this.min, this.max] = [1, 240];
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_auto_cycle_interval_unit: {
    article: 'htc_0_auto_cycle_interval_unit',
    defaultValue: 'False',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterBits/AutoCycleIntervalUnit',
    label: 'Auto cycle interval unit',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  htc_0_circuit_breaker_0_current_rating: {
    article: 'htc_0_circuit_breaker_0_current_rating',
    defaultValue: '50.00A',
    group: 'Regs/Config/ACAnalogParameters/CircuitBreakerCurrentRating',
    label: 'Circuit breaker 0 current rating',
    type: 'range',
    unit: '_A',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.min = 0.3;
        this.max = 100.0;
        this.min *= Number(htc910.htc_0_current_turns_ratio.defaultValue);
        this.max *= Number(htc910.htc_0_current_turns_ratio.defaultValue);
        if (this.min < 0.1) this.min = 0.1;
        if (this.max > 300) this.max = 300;
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_circuit_breaker_0_limiting_mask: {
    article: 'htc_0_circuit_breaker_0_limiting_mask',
    defaultValue: 'False',
    group: 'Regs/Config/Alarm/Mask/CircuitBreakerLimiting',
    label: 'Circuit breaker 0 limiting mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  htc_0_contactor_cycle_count_limit_exceeded_mask: {
    article: 'htc_0_contactor_cycle_count_limit_exceeded_mask',
    defaultValue: 'True',
    group: 'Regs/Config/Alarm/Mask/ContactorCycleCount',
    label: 'Contactor cycle count limit exceeded mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        if (control.value) control.parent?.get('htc_0_contactor_cycle_count_setpoint')?.enable();
        else {
          control.parent?.get('htc_0_contactor_cycle_count_setpoint')?.disable();
          control.parent?.get('htc_0_contactor_cycle_count_setpoint')?.reset();
        }
      };
    },
  } as Configurable,
  htc_0_contactor_cycle_count_setpoint: {
    article: 'htc_0_contactor_cycle_count_setpoint',
    defaultValue: 200000,
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterRegs/ContactorCycleCountAlarmLimitSetpoint',
    label: 'Contactor cycle count setpoint',
    type: 'int',
    unit: '',
    validator(ctx?: Context) {
      [this.min, this.max] = [0, 999999];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_control_temperature_failure_mask: {
    article: 'htc_0_control_temperature_failure_mask',
    defaultValue: 'True',
    group: 'Regs/Config/Alarm/Mask/ControlTemperatureFailure',
    label: 'Control temperature failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  htc_0_control_temperature_setpoint: {
    article: 'htc_0_control_temperature_setpoint',
    defaultValue: '20.0°C',
    group: 'Regs/Config/ControlParameters/ControlTemperatureSetpoint',
    label: 'Control temperature setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      this.min = -60.0;
      this.max = 570.0;
      if (ctx?.temperaturePreferenceUnit !== 'Celsius') {
        this.min = (this.min * 9) / 5 + 32;
        this.max = (this.max * 9) / 5 + 32;
      }
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_current_turns_ratio: {
    article: 'htc_0_current_turns_ratio',
    defaultValue: 1.0,
    group: 'Regs/Config/ACAnalogParameters/CurrentTurnsRatio',
    label: 'Current turns ratio',
    type: 'range',
    unit: '',
    validator(ctx?: Context) {
      [this.min, this.max] = [1.0, 1.0];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_deadband: {
    article: 'htc_0_deadband',
    defaultValue: '3.0Δ°C',
    group: 'Regs/Config/ControlParameters/Deadband',
    label: 'Deadband',
    type: 'range',
    unit: 'Δ°C',
    validator(ctx?: Context) {
      this.min = 1.0;
      this.max = 350.0;
      if (ctx!.fwVersion <= 3.18) this.max = 50.0;
      if (ctx?.temperaturePreferenceUnit !== 'Celsius') {
        this.min = (this.min * 9) / 5;
        this.max = (this.max * 9) / 5;
      }
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_external_input_configuration: {
    article: 'htc_0_external_input_configuration',
    defaultValue: 0,
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterRegs/ExternalInputConfiguration',
    label: 'External input configuration',
    options: [
      { key: 0, value: 'NOT_USED', displayText: 'NOT USED' },
      { key: 2, value: 'INHIBIT', displayText: 'INHIBIT' },
      { key: 3, value: 'FORCE_ON', displayText: 'FORCE ON' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  htc_0_ground_fault_current_high_alarm_filter: {
    article: 'htc_0_ground_fault_current_high_alarm_filter',
    defaultValue: '5s',
    group: 'Regs/Config/ACAnalogParameters/HighGFCurrentAlarmFilter',
    label: 'Ground fault current high alarm filter',
    type: 'range',
    unit: '<s',
    validator(ctx?: Context) {
      [this.min, this.max] = [0, 12];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_ground_fault_current_high_alarm_setpoint: {
    article: 'htc_0_ground_fault_current_high_alarm_setpoint',
    defaultValue: '50.0mA',
    group: 'Regs/Config/ACAnalogParameters/HighGFCurrentAlarmSetpoint',
    label: 'Ground fault current high alarm setpoint',
    type: 'range',
    unit: 'mA',
    validator(ctx?: Context) {
      [this.min, this.max] = [20, 250];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_ground_fault_current_high_mask: {
    article: 'htc_0_ground_fault_current_high_mask',
    defaultValue: 'True',
    group: 'Regs/Config/Alarm/Mask/HighGroundFaultCurrent',
    label: 'Ground fault current high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        if (!control.value) {
          control.parent?.get('htc_0_ground_fault_current_high_alarm_filter')?.disable();
          control.parent?.get('htc_0_ground_fault_current_high_alarm_filter')?.reset();
          control.parent?.get('htc_0_ground_fault_current_high_alarm_setpoint')?.disable();
          control.parent?.get('htc_0_ground_fault_current_high_alarm_setpoint')?.reset();
        } else {
          control.parent?.get('htc_0_ground_fault_current_high_alarm_filter')?.enable();
          control.parent?.get('htc_0_ground_fault_current_high_alarm_setpoint')?.enable();
        }
      };
    },
  } as Configurable,
  htc_0_ground_fault_current_trip_setpoint: {
    article: 'htc_0_ground_fault_current_trip_setpoint',
    defaultValue: '50.0mA',
    group: 'Regs/Config/ACAnalogParameters/GroundFaultTripCurrentSetpoint',
    label: 'Ground fault current trip setpoint',
    type: 'range',
    unit: 'mA',
    validator(ctx?: Context) {
      [this.min, this.max] = [20, 250];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_ground_fault_trip_mask: {
    article: 'htc_0_ground_fault_trip_mask',
    defaultValue: 'True',
    group: 'Regs/Config/Alarm/Mask/GroundFaultTrip',
    label: 'Ground fault trip mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  htc_0_line_current_0_over_mask: {
    article: 'htc_0_line_current_0_over_mask',
    defaultValue: 'True',
    group: 'Regs/Config/Alarm/Mask/OvercurrentTrip',
    label: 'Line current 0 over mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  htc_0_line_voltage_high_mask: {
    article: 'htc_0_line_voltage_high_mask',
    defaultValue: 'False',
    group: 'Regs/Config/Alarm/Mask/HighVoltage',
    label: 'Line voltage high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        if (!control.value) {
          control.parent?.get('htc_0_voltage_high_alarm_filter')?.disable();
          control.parent?.get('htc_0_voltage_high_alarm_filter')?.reset();
          control.parent?.get('htc_0_voltage_high_alarm_setpoint')?.disable();
          control.parent?.get('htc_0_voltage_high_alarm_setpoint')?.reset();
        } else {
          control.parent?.get('htc_0_voltage_high_alarm_filter')?.enable();
          control.parent?.get('htc_0_voltage_high_alarm_setpoint')?.enable();
        }
      };
    },
  } as Configurable,
  htc_0_line_voltage_low_mask: {
    article: 'htc_0_line_voltage_low_mask',
    defaultValue: 'True',
    group: 'Regs/Config/Alarm/Mask/LowVoltage',
    label: 'Line voltage low mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        if (!control.value) {
          control.parent?.get('htc_0_voltage_low_alarm_filter')?.disable();
          control.parent?.get('htc_0_voltage_low_alarm_filter')?.reset();
          control.parent?.get('htc_0_voltage_low_alarm_setpoint')?.disable();
          control.parent?.get('htc_0_voltage_low_alarm_setpoint')?.reset();
        } else {
          control.parent?.get('htc_0_voltage_low_alarm_filter')?.enable();
          control.parent?.get('htc_0_voltage_low_alarm_setpoint')?.enable();
        }
      };
    },
  } as Configurable,
  htc_0_load_current_high_alarm_filter: {
    article: 'htc_0_load_current_high_alarm_filter',
    defaultValue: '5s',
    group: 'Regs/Config/ACAnalogParameters/HighLoadCurrentAlarmFilter',
    label: 'Load current high alarm filter',
    type: 'range',
    unit: '<s',
    validator(ctx?: Context) {
      [this.min, this.max] = [0, 12];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_load_current_high_alarm_setpoint: {
    article: 'htc_0_load_current_high_alarm_setpoint',
    defaultValue: '50.00A',
    group: 'Regs/Config/ACAnalogParameters/HighLoadCurrentAlarmSetpoint',
    label: 'Load current high alarm setpoint',
    type: 'range',
    unit: '_A',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        const currentRange = [0.3, 100.0];
        this.min = 0.3;
        this.max = 100.0;
        this.min *= Number(htc910.htc_0_current_turns_ratio.defaultValue);
        this.max *= Number(htc910.htc_0_current_turns_ratio.defaultValue);
        if (this.min < 0.1) this.min = 0.1;
        if (this.max > 300) this.max = 300;
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_load_current_high_mask: {
    article: 'htc_0_load_current_high_mask',
    defaultValue: 'False',
    group: 'Regs/Config/Alarm/Mask/HighLoadCurrent',
    label: 'Load current high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        if (!control.value) {
          control.parent?.get('htc_0_load_current_high_alarm_filter')?.disable();
          control.parent?.get('htc_0_load_current_high_alarm_filter')?.reset();
          control.parent?.get('htc_0_load_current_high_alarm_setpoint')?.disable();
          control.parent?.get('htc_0_load_current_high_alarm_setpoint')?.reset();
        } else {
          control.parent?.get('htc_0_load_current_high_alarm_filter')?.enable();
          control.parent?.get('htc_0_load_current_high_alarm_setpoint')?.enable();
        }
      };
    },
  } as Configurable,
  htc_0_load_current_low_alarm_filter: {
    article: 'htc_0_load_current_low_alarm_filter',
    defaultValue: '5s',
    group: 'Regs/Config/ACAnalogParameters/LowLoadCurrentAlarmFilter',
    label: 'Load current low alarm filter',
    type: 'range',
    unit: '<s',
    validator(ctx?: Context) {
      [this.min, this.max] = [0, 12];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_load_current_low_alarm_setpoint: {
    article: 'htc_0_load_current_low_alarm_setpoint',
    defaultValue: '50.00A',
    group: 'Regs/Config/ACAnalogParameters/LowLoadCurrentAlarmSetpoint',
    label: 'Load current low alarm setpoint',
    type: 'range',
    unit: '_A',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.min = 0.3;
        this.max = 100.0;
        this.min *= Number(htc910.htc_0_current_turns_ratio.defaultValue);
        this.max *= Number(htc910.htc_0_current_turns_ratio.defaultValue);
        if (this.min < 0.1) this.min = 0.1;
        if (this.max > 300) this.max = 300;
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_load_current_low_mask: {
    article: 'htc_0_load_current_low_mask',
    defaultValue: 'True',
    group: 'Regs/Config/Alarm/Mask/LowLoadCurrent',
    label: 'Load current low mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        if (!control.value) {
          control.parent?.get('htc_0_load_current_low_alarm_filter')?.disable();
          control.parent?.get('htc_0_load_current_low_alarm_filter')?.reset();
          control.parent?.get('htc_0_load_current_low_alarm_setpoint')?.disable();
          control.parent?.get('htc_0_load_current_low_alarm_setpoint')?.reset();
        } else {
          control.parent?.get('htc_0_load_current_low_alarm_filter')?.enable();
          control.parent?.get('htc_0_load_current_low_alarm_setpoint')?.enable();
        }
      };
    },
  } as Configurable,
  htc_0_load_shed_enabled: {
    article: 'htc_0_load_shed_enabled',
    defaultValue: 'False',
    group: 'Regs/Config/ControllerSetupParameters/LoadSheddingParameters/LoadSheddingEnabled',
    label: 'Load shed enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        if (!control.value) {
          control.parent?.get('htc_0_load_shed_fail_safe_enabled')?.disable();
          control.parent?.get('htc_0_load_shed_fail_safe_enabled')?.reset();
          control.parent?.get('htc_0_load_shed_zone_0_enabled')?.disable();
          control.parent?.get('htc_0_load_shed_zone_0_enabled')?.reset();
          control.parent?.get('htc_0_load_shed_zone_1_enabled')?.disable();
          control.parent?.get('htc_0_load_shed_zone_1_enabled')?.reset();
          control.parent?.get('htc_0_load_shed_zone_2_enabled')?.disable();
          control.parent?.get('htc_0_load_shed_zone_2_enabled')?.reset();
          control.parent?.get('htc_0_load_shed_zone_3_enabled')?.disable();
          control.parent?.get('htc_0_load_shed_zone_3_enabled')?.reset();
        } else {
          control.parent?.get('htc_0_load_shed_fail_safe_enabled')?.enable();
          control.parent?.get('htc_0_load_shed_zone_0_enabled')?.enable();
          control.parent?.get('htc_0_load_shed_zone_1_enabled')?.enable();
          control.parent?.get('htc_0_load_shed_zone_2_enabled')?.enable();
          control.parent?.get('htc_0_load_shed_zone_3_enabled')?.enable();
        }
      };
    },
  } as Configurable,
  htc_0_load_shed_fail_safe_enabled: {
    article: 'htc_0_load_shed_fail_safe_enabled',
    defaultValue: 'False',
    group: 'Regs/Config/ControllerSetupParameters/LoadSheddingParameters/LoadSheddingFailSafeEnabled',
    label: 'Load shed fail safe enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  htc_0_load_shed_zone_0_enabled: {
    article: 'htc_0_load_shed_zone_0_enabled',
    defaultValue: 'False',
    group: 'Regs/Config/ControllerSetupParameters/LoadSheddingParameters/ZoneLoadSheddingEnabled/elem',
    label: 'Load shed zone 0 enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  htc_0_load_shed_zone_1_enabled: {
    article: 'htc_0_load_shed_zone_1_enabled',
    defaultValue: 'False',
    group: 'Regs/Config/ControllerSetupParameters/LoadSheddingParameters/ZoneLoadSheddingEnabled/elem',
    label: 'Load shed zone 1 enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  htc_0_load_shed_zone_2_enabled: {
    article: 'htc_0_load_shed_zone_2_enabled',
    defaultValue: 'False',
    group: 'Regs/Config/ControllerSetupParameters/LoadSheddingParameters/ZoneLoadSheddingEnabled/elem',
    label: 'Load shed zone 2 enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  htc_0_load_shed_zone_3_enabled: {
    article: 'htc_0_load_shed_zone_3_enabled',
    defaultValue: 'False',
    group: 'Regs/Config/ControllerSetupParameters/LoadSheddingParameters/ZoneLoadSheddingEnabled/elem',
    label: 'Load shed zone 3 enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  htc_0_maximum_power_setpoint: {
    article: 'htc_0_maximum_power_setpoint',
    defaultValue: '5000W',
    group: 'Regs/Config/ACAnalogParameters/MaximumPowerSetpoint',
    label: 'Maximum power setpoint',
    type: 'range',
    unit: '_W',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        let minCurrent = 0.3;
        let maxCurrent = 100.0;
        minCurrent *= Number(htc910.htc_0_current_turns_ratio.defaultValue);
        maxCurrent *= Number(htc910.htc_0_current_turns_ratio.defaultValue);
        if (minCurrent < 0.1) minCurrent = 0.1;
        if (maxCurrent > 300) maxCurrent = 300;

        let minVoltage = 10;
        let maxVoltage = 330;
        minVoltage *= Number(htc910.htc_0_voltage_turns_ratio.defaultValue);
        maxVoltage *= Number(htc910.htc_0_voltage_turns_ratio.defaultValue);
        if (control.parent?.get('htc_0_three_phase_power_calculation_enabled')?.value) {
          minVoltage *= Math.sqrt(3);
          maxVoltage *= Math.sqrt(3);
        }
        if (minVoltage < 1) minVoltage = 1;
        if (maxVoltage > 1000) maxVoltage = 1000;

        let minPower = 3;
        let maxPower = 33000;
        minPower = minCurrent * minVoltage;
        maxPower = maxCurrent * maxVoltage;
        if (minPower < 1) minPower = 1;
        if (ctx!.deviceType == 'htc910') {
          if (maxPower > 33000) maxPower = 33000;
        } else {
          if (maxPower > 65535) maxPower = 65535;
        }
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_non_latching_temperature_alarms: {
    article: 'htc_0_non_latching_temperature_alarms',
    defaultValue: 'False',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterBits/NonLatchingTemperatureAlarms',
    label: 'Non latching temperature alarms',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  htc_0_override_source: {
    article: 'htc_0_override_source',
    defaultValue: 'False',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterBits/OverrideSource',
    label: 'Override source',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  htc_0_power_limiting_mask: {
    article: 'htc_0_power_limiting_mask',
    defaultValue: 'False',
    group: 'Regs/Config/Alarm/Mask/PowerLimiting',
    label: 'Power limiting mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  htc_0_proportional_ambient_cycle_time: {
    article: 'htc_0_proportional_ambient_cycle_time',
    defaultValue: '20m',
    group: 'Regs/Config/ControlParameters/ProportionalAmbientCycleTime',
    label: 'Proportional ambient cycle time',
    type: 'range',
    unit: 'm',
    validator(ctx?: Context) {
      [this.min, this.max] = [10, 255];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_proportional_band: {
    article: 'htc_0_proportional_band',
    defaultValue: '2.0Δ°C',
    group: 'Regs/Config/ControlParameters/ProportionalBand',
    label: 'Proportional band',
    type: 'range',
    unit: 'Δ°C',
    validator(ctx?: Context) {
      this.min = 1.0;
      this.max = 350.0;
      if (ctx!.fwVersion <= 3.18) this.max = 50.0;
      if (ctx?.temperaturePreferenceUnit !== 'Celsius') {
        this.min = (this.min * 9) / 5;
        this.max = (this.max * 9) / 5;
      }
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_remote_override: {
    article: 'htc_0_remote_override',
    defaultValue: 0,
    group: 'Regs/Config/ControlParameters/RemoteOverride',
    label: 'Remote override',
    options: [
      { key: 0, value: 'OVERRIDE_NOT_ACTIVE', displayText: 'OVERRIDE NOT ACTIVE' },
      { key: 1, value: 'FORCE_ON_OVERRIDE_ACTIVE', displayText: 'FORCE ON OVERRIDE ACTIVE' },
      { key: 2, value: 'INHIBIT_OVERRIDE_ACTIVE', displayText: 'INHIBIT OVERRIDE ACTIVE' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  htc_0_ssr_rating: {
    article: 'htc_0_ssr_rating',
    defaultValue: '50.00A',
    group: 'Regs/Config/ACAnalogParameters/SwitchControllerCurrentRating',
    label: 'Ssr rating',
    type: 'range',
    unit: '_A',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.min = 0.3;
        this.max = 100.0;
        this.min *= Number(htc910.htc_0_current_turns_ratio.defaultValue);
        this.max *= Number(htc910.htc_0_current_turns_ratio.defaultValue);
        if (this.min < 0.1) this.min = 0.1;
        if (this.max > 300) this.max = 300;
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_switch_0_failure_mask: {
    article: 'htc_0_switch_0_failure_mask',
    defaultValue: 'True',
    group: 'Regs/Config/Alarm/Mask/SwitchFailure',
    label: 'Switch 0 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  htc_0_switch_0_limiting_mask: {
    article: 'htc_0_switch_0_limiting_mask',
    defaultValue: 'False',
    group: 'Regs/Config/Alarm/Mask/SwitchProtectionLimiting',
    label: 'Switch 0 limiting mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  htc_0_switch_control_mode: {
    article: 'htc_0_switch_control_mode',
    defaultValue: 0,
    group: 'Regs/Config/ControlParameters/SwitchControlMode',
    label: 'Switch control mode',
    options: [
      { key: 0, value: 'PROPORTIONAL', displayText: 'PROPORTIONAL' },
      { key: 1, value: 'PROPORTIONAL_AMBIENT_SSR', displayText: 'PROPORTIONAL AMBIENT SSR' },
      { key: 2, value: 'DEADBAND', displayText: 'DEADBAND' },
      {
        key: 3,
        value: 'PROPORTIONAL_AMBIENT_CONTACTOR',
        displayText: 'PROPORTIONAL AMBIENT CONTACTOR',
      },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        switch (control.value) {
          case 'PROPORTIONAL': // PROPORTIONAL
            // enable()
            control.parent?.get('htc_0_circuit_breaker_0_current_rating')?.enable();
            control.parent?.get('htc_0_line_current_0_over_mask')?.enable();
            control.parent?.get('htc_0_maximum_power_setpoint')?.enable();
            control.parent?.get('htc_0_power_limiting_mask')?.enable();
            control.parent?.get('htc_0_proportional_band')?.enable();
            control.parent?.get('htc_0_ssr_rating')?.enable();
            control.parent?.get('htc_0_switch_0_failure_mask')?.enable();

            // disable()
            control.parent?.get('htc_0_contactor_cycle_count_limit_exceeded_mask')?.disable();
            control.parent?.get('htc_0_contactor_cycle_count_limit_exceeded_mask')?.reset();
            control.parent?.get('htc_0_deadband')?.disable();
            control.parent?.get('htc_0_deadband')?.reset();
            control.parent?.get('htc_0_proportional_ambient_cycle_time')?.disable();
            control.parent?.get('htc_0_proportional_ambient_cycle_time')?.reset();
            break;
          case 'PROPORTIONAL_AMBIENT_SSR': // PROPORTIONAL_AMBIENT_SSR
            // enable()
            control.parent?.get('htc_0_circuit_breaker_0_current_rating')?.enable();
            control.parent?.get('htc_0_line_current_0_over_mask')?.enable();
            control.parent?.get('htc_0_maximum_power_setpoint')?.enable();
            control.parent?.get('htc_0_power_limiting_mask')?.enable();
            control.parent?.get('htc_0_proportional_band')?.enable();
            control.parent?.get('htc_0_ssr_rating')?.enable();
            control.parent?.get('htc_0_switch_0_failure_mask')?.enable();

            // disable()
            control.parent?.get('htc_0_contactor_cycle_count_limit_exceeded_mask')?.disable();
            control.parent?.get('htc_0_contactor_cycle_count_limit_exceeded_mask')?.reset();
            control.parent?.get('htc_0_deadband')?.disable();
            control.parent?.get('htc_0_deadband')?.reset();
            control.parent?.get('htc_0_proportional_ambient_cycle_time')?.disable();
            control.parent?.get('htc_0_proportional_ambient_cycle_time')?.reset();
            break;
          case 'DEADBAND': // DEADBAND
            // enable()
            control.parent?.get('htc_0_contactor_cycle_count_limit_exceeded_mask')?.enable();
            control.parent?.get('htc_0_deadband')?.enable();

            // disable()
            control.parent?.get('htc_0_circuit_breaker_0_current_rating')?.disable();
            control.parent?.get('htc_0_circuit_breaker_0_current_rating')?.reset();
            control.parent?.get('htc_0_line_current_0_over_mask')?.disable();
            control.parent?.get('htc_0_line_current_0_over_mask')?.reset();
            control.parent?.get('htc_0_maximum_power_setpoint')?.disable();
            control.parent?.get('htc_0_maximum_power_setpoint')?.reset();
            control.parent?.get('htc_0_power_limiting_mask')?.disable();
            control.parent?.get('htc_0_power_limiting_mask')?.reset();
            control.parent?.get('htc_0_proportional_ambient_cycle_time')?.disable();
            control.parent?.get('htc_0_proportional_ambient_cycle_time')?.reset();
            control.parent?.get('htc_0_proportional_band')?.disable();
            control.parent?.get('htc_0_proportional_band')?.reset();
            control.parent?.get('htc_0_ssr_rating')?.disable();
            control.parent?.get('htc_0_ssr_rating')?.reset();
            control.parent?.get('htc_0_switch_0_failure_mask')?.disable();
            control.parent?.get('htc_0_switch_0_failure_mask')?.reset();
            break;
          case 'PROPORTIONAL_AMBIENT_CONTACTOR': // PROPORTIONAL_AMBIENT_CONTACTOR
            // enable()
            control.parent?.get('htc_0_contactor_cycle_count_limit_exceeded_mask')?.enable();
            control.parent?.get('htc_0_proportional_ambient_cycle_time')?.enable();
            control.parent?.get('htc_0_proportional_band')?.enable();

            // disable()
            control.parent?.get('htc_0_circuit_breaker_0_current_rating')?.disable();
            control.parent?.get('htc_0_circuit_breaker_0_current_rating')?.reset();
            control.parent?.get('htc_0_deadband')?.disable();
            control.parent?.get('htc_0_deadband')?.reset();
            control.parent?.get('htc_0_line_current_0_over_mask')?.disable();
            control.parent?.get('htc_0_line_current_0_over_mask')?.reset();
            control.parent?.get('htc_0_maximum_power_setpoint')?.disable();
            control.parent?.get('htc_0_maximum_power_setpoint')?.reset();
            control.parent?.get('htc_0_power_limiting_mask')?.disable();
            control.parent?.get('htc_0_power_limiting_mask')?.reset();
            control.parent?.get('htc_0_ssr_rating')?.disable();
            control.parent?.get('htc_0_ssr_rating')?.reset();
            control.parent?.get('htc_0_switch_0_failure_mask')?.disable();
            control.parent?.get('htc_0_switch_0_failure_mask')?.reset();
            break;
          default:
            break;
        }
      };
    },
  } as Configurable,
  htc_0_temperature_control_mode: {
    article: 'htc_0_temperature_control_mode',
    defaultValue: 3,
    group: 'Regs/Config/ControlParameters/TSControlMode',
    label: 'Temperature control mode',
    options: [
      { key: 0, value: 'TS1_FAIL_OFF_ON', displayText: 'TS1 FAIL OFF ON' },
      { key: 1, value: 'TS1_FAIL_TS2', displayText: 'TS1 FAIL TS2' },
      { key: 2, value: 'TS2_FAIL_OFF_ON', displayText: 'TS2 FAIL OFF ON' },
      { key: 3, value: 'TS2_FAIL_TS1', displayText: 'TS2 FAIL TS1' },
      { key: 4, value: 'AVERAGE_FAIL_OFF_ON', displayText: 'AVERAGE FAIL OFF ON' },
      { key: 5, value: 'AVERAGE_FAIL_GOOD', displayText: 'AVERAGE FAIL GOOD' },
      { key: 6, value: 'LOWEST_FAIL_OFF_ON', displayText: 'LOWEST FAIL OFF ON' },
      { key: 7, value: 'LOWEST_FAIL_GOOD', displayText: 'LOWEST FAIL GOOD' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  htc_0_temperature_fail_mode: {
    article: 'htc_0_temperature_fail_mode',
    defaultValue: 'True',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterBits/TSFailMode',
    label: 'Temperature fail mode',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  htc_0_three_phase_power_calculation_enabled: {
    article: 'htc_0_three_phase_power_calculation_enabled',
    defaultValue: 'False',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterBits/ThreePhasePowerCalculationEnabled',
    label: 'Three phase power calculation enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_0_high_alarm_filter: {
    article: 'htc_0_tracing_resistance_0_high_alarm_filter',
    defaultValue: '5s',
    group: 'Regs/Config/ACAnalogParameters/HighTracingResistanceAlarmFilter',
    label: 'Tracing resistance 0 high alarm filter',
    type: 'range',
    unit: '<s',
    validator(ctx?: Context) {
      [this.min, this.max] = [0, 12];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_0_high_deviation: {
    article: 'htc_0_tracing_resistance_0_high_deviation',
    defaultValue: '50%',
    group: 'Regs/Config/ACAnalogParameters/HighTracingResistanceDeviation',
    label: 'Tracing resistance 0 high deviation',
    type: 'range',
    unit: '%',
    validator(ctx?: Context) {
      [this.min, this.max] = [1, 250];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_0_high_mask: {
    article: 'htc_0_tracing_resistance_0_high_mask',
    defaultValue: 'False',
    group: 'Regs/Config/Alarm/Mask/HighTracingResistance',
    label: 'Tracing resistance 0 high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        if (!control.value) {
          control.parent?.get('htc_0_tracing_resistance_0_high_alarm_filter')?.disable();
          control.parent?.get('htc_0_tracing_resistance_0_high_alarm_filter')?.reset();
          control.parent?.get('htc_0_tracing_resistance_0_high_deviation')?.disable();
          control.parent?.get('htc_0_tracing_resistance_0_high_deviation')?.reset();
        } else {
          control.parent?.get('htc_0_tracing_resistance_0_high_alarm_filter')?.enable();
          control.parent?.get('htc_0_tracing_resistance_0_high_deviation')?.enable();
        }
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_0_low_alarm_filter: {
    article: 'htc_0_tracing_resistance_0_low_alarm_filter',
    defaultValue: '5s',
    group: 'Regs/Config/ACAnalogParameters/LowTracingResistanceAlarmFilter',
    label: 'Tracing resistance 0 low alarm filter',
    type: 'range',
    unit: '<s',
    validator(ctx?: Context) {
      [this.min, this.max] = [0, 12];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_0_low_deviation: {
    article: 'htc_0_tracing_resistance_0_low_deviation',
    defaultValue: '50%',
    group: 'Regs/Config/ACAnalogParameters/LowTracingResistanceDeviation',
    label: 'Tracing resistance 0 low deviation',
    type: 'range',
    unit: '%',
    validator(ctx?: Context) {
      [this.min, this.max] = [1, 100];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_0_low_mask: {
    article: 'htc_0_tracing_resistance_0_low_mask',
    defaultValue: 'False',
    group: 'Regs/Config/Alarm/Mask/LowTracingResistance',
    label: 'Tracing resistance 0 low mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        if (!control.value) {
          control.parent?.get('htc_0_tracing_resistance_0_low_alarm_filter')?.disable();
          control.parent?.get('htc_0_tracing_resistance_0_low_alarm_filter')?.reset();
          control.parent?.get('htc_0_tracing_resistance_0_low_deviation')?.disable();
          control.parent?.get('htc_0_tracing_resistance_0_low_deviation')?.reset();
        } else {
          control.parent?.get('htc_0_tracing_resistance_0_low_alarm_filter')?.enable();
          control.parent?.get('htc_0_tracing_resistance_0_low_deviation')?.enable();
        }
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_0_nominal: {
    article: 'htc_0_tracing_resistance_0_nominal',
    defaultValue: '50.00Ω',
    group: 'Regs/Config/ACAnalogParameters/NominalTracingResistance',
    label: 'Tracing resistance 0 nominal',
    type: 'range',
    unit: '_Ω',
    validator(ctx?: Context) {
      [this.min, this.max] = [2.0, 2000.0];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_voltage_high_alarm_filter: {
    article: 'htc_0_voltage_high_alarm_filter',
    defaultValue: '5s',
    group: 'Regs/Config/ACAnalogParameters/HighVoltageAlarmFilter',
    label: 'Voltage high alarm filter',
    type: 'range',
    unit: '<s',
    validator(ctx?: Context) {
      [this.min, this.max] = [0, 12];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_voltage_high_alarm_setpoint: {
    article: 'htc_0_voltage_high_alarm_setpoint',
    defaultValue: '50.0V',
    group: 'Regs/Config/ACAnalogParameters/HighVoltageAlarmSetpoint',
    label: 'Voltage high alarm setpoint',
    type: 'range',
    unit: '_V',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.min = 10;
        this.max = 330;
        let absoluteMin = 1;
        if (ctx!.fwVersion < 3) {
          this.min = 90;
          this.max = 280;
          absoluteMin = 9;
        }
        this.min *= Number(htc910.htc_0_current_turns_ratio.defaultValue);
        this.max *= Number(htc910.htc_0_current_turns_ratio.defaultValue);
        if (this.min < absoluteMin) this.min = absoluteMin;
        if (this.max > 1000) this.max = 1000;
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_voltage_low_alarm_filter: {
    article: 'htc_0_voltage_low_alarm_filter',
    defaultValue: '5s',
    group: 'Regs/Config/ACAnalogParameters/LowVoltageAlarmFilter',
    label: 'Voltage low alarm filter',
    type: 'range',
    unit: '<s',
    validator(ctx?: Context) {
      [this.min, this.max] = [0, 12];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_voltage_low_alarm_setpoint: {
    article: 'htc_0_voltage_low_alarm_setpoint',
    defaultValue: '50.0V',
    group: 'Regs/Config/ACAnalogParameters/LowVoltageAlarmSetpoint',
    label: 'Voltage low alarm setpoint',
    type: 'range',
    unit: '_V',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.min = 10;
        this.max = 330;
        let absoluteMin = 1;
        if (ctx!.fwVersion < 3) {
          this.min = 90;
          this.max = 280;
          absoluteMin = 9;
        }
        this.min *= Number(htc910.htc_0_current_turns_ratio.defaultValue);
        this.max *= Number(htc910.htc_0_current_turns_ratio.defaultValue);
        if (this.min < absoluteMin) this.min = absoluteMin;
        if (this.max > 1000) this.max = 1000;
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_voltage_source: {
    article: 'htc_0_voltage_source',
    defaultValue: 0,
    group: 'Regs/Config/ACAnalogParameters/VoltageSource',
    label: 'Voltage source',
    options: [{ key: 0, value: 'SWITCH_INTERFACE', displayText: 'SWITCH INTERFACE' }],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  htc_0_voltage_turns_ratio: {
    article: 'htc_0_voltage_turns_ratio',
    defaultValue: 1.0,
    group: 'Regs/Config/ACAnalogParameters/VoltageTurnsRatio',
    label: 'Voltage turns ratio',
    type: 'range',
    unit: '',
    validator(ctx?: Context) {
      [this.min, this.max] = [1.0, 1.0];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  nvram_0_total_failure_mask: {
    article: 'nvram_0_total_failure_mask',
    defaultValue: 'True',
    group: 'Regs/Config/Alarm/Mask/EEROMDataFailure',
    label: 'Nvram 0 total failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  serial_0_baud_rate: {
    article: 'serial_0_baud_rate',
    defaultValue: 9600,
    group: 'Regs/Config/CommunicationParameters/BaudRate',
    label: 'Baud rate',
    type: 'int',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        if (control.touched) return null;
        control.value === 0 ||
          control.value === 300 ||
          control.value === 600 ||
          control.value === 1200 ||
          control.value === 2400 ||
          control.value === 4800 ||
          control.value === 9600;
      };
    },
  } as Configurable,
  serial_0_communications_driver: {
    article: 'serial_0_communications_driver',
    defaultValue: 0,
    group: 'Regs/Config/CommunicationParameters/CommunicationsDriver',
    label: 'Communications driver',
    options: [
      { key: 0, value: 'AUTO', displayText: 'AUTO' },
      { key: 1, value: 'MODEM', displayText: 'MODEM' },
      { key: 2, value: 'RS232', displayText: 'RS232' },
      { key: 3, value: 'RS485', displayText: 'RS485' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  serial_0_communications_profile: {
    article: 'serial_0_communications_profile',
    defaultValue: 0,
    group: 'Regs/Config/CommunicationParameters/CommunicationsProfile',
    label: 'Communications profile',
    options: [
      { key: 0, value: 'AUTO', displayText: 'AUTO' },
      { key: 1, value: 'BAUD_300_MODEM', displayText: 'BAUD 300 MODEM' },
      { key: 2, value: 'BAUD_1200_MODEM', displayText: 'BAUD 1200 MODEM' },
      { key: 4, value: 'RS485', displayText: 'RS485' },
      { key: 5, value: 'THREE_WIRE_RS232', displayText: 'THREE WIRE RS232' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      // Not found in the metadata spreadsheet.
    },
  } as Configurable,
  serial_0_frame_type: {
    article: 'serial_0_frame_type',
    defaultValue: 1,
    group: 'Regs/Config/CommunicationParameters/FrameType',
    label: 'Frame type',
    options: [
      { key: 0, value: 'HTCBUS', displayText: 'HTCBUS' },
      { key: 1, value: 'MODBUS_ASCII', displayText: 'MODBUS ASCII' },
      { key: 2, value: 'MODBUS_RTU', displayText: 'MODBUS RTU' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      // Not found in the metadata spreadsheet.
    },
  } as Configurable,
  serial_0_modbus_address: {
    article: 'serial_0_modbus_address',
    defaultValue: 1,
    group: 'Regs/Config/CommunicationParameters/ModbusAddress',
    label: 'Modbus address',
    type: 'int',
    unit: '',
    validator(ctx?: Context) {
      [this.min, this.max] = [1, 247];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  serial_0_modbus_sub_address: {
    article: 'serial_0_modbus_sub_address',
    defaultValue: 0,
    group: 'Regs/Config/CommunicationParameters/ModbusSubAddress',
    label: 'Modbus sub address',
    type: 'int',
    unit: '',
    validator(ctx?: Context) {
      [this.min, this.max] = [0, 31];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  serial_0_parity: {
    article: 'serial_0_parity',
    defaultValue: 0,
    group: 'Regs/Config/CommunicationParameters/Parity',
    label: 'Parity',
    options: [
      { key: 0, value: 'NONE', displayText: 'NONE' },
      { key: 1, value: 'ODD', displayText: 'ODD' },
      { key: 2, value: 'EVEN', displayText: 'EVEN' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      // Not found in the metadata spreadsheet.
    },
  } as Configurable,
  serial_0_tx_delay: {
    article: 'serial_0_tx_delay',
    defaultValue: '20ms',
    group: 'Regs/Config/CommunicationParameters/TxDelay',
    label: 'Tx delay',
    type: 'range',
    unit: '<s',
    validator(ctx?: Context) {
      // Not found in the metadata spreadsheet.
    },
  } as Configurable,
  temperature_0_failure_mask: {
    article: 'temperature_0_failure_mask',
    defaultValue: 'True',
    group: 'Regs/Config/Alarm/Mask/TemperatureSensorFailure/elem',
    label: 'Temperature failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  temperature_0_high_alarm_filter: {
    article: 'temperature_0_high_alarm_filter',
    defaultValue: '50s',
    group: 'Regs/Config/TemperatureSetup/HighTSAlarmFilter',
    label: 'Temperature high alarm filter',
    type: 'range',
    unit: '<s',
    validator(ctx?: Context) {
      [this.min, this.max] = [0, 59940];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  temperature_0_high_alarm_setpoint: {
    article: 'temperature_0_high_alarm_setpoint',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSetup/HighTSAlarmSetpoint/elem',
    label: 'Temperature high alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      this.min = -60.0;
      this.max = 570.0;
      if (ctx?.temperaturePreferenceUnit !== 'Celsius') {
        this.min = (this.min * 9) / 5 + 32;
        this.max = (this.max * 9) / 5 + 32;
      }
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  temperature_0_high_cutout_enabled: {
    article: 'temperature_0_high_cutout_enabled',
    defaultValue: 0,
    group: 'Regs/Config/TemperatureSetup/CutoutEnabled/elem',
    label: 'Temperature high cutout enabled',
    type: 'int1',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  temperature_0_high_mask: {
    article: 'temperature_0_high_mask',
    defaultValue: 'True',
    group: 'Regs/Config/Alarm/Mask/TemperatureSensorHigh/elem',
    label: 'Temperature high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        if (!control.value) {
          control.parent?.get('temperature_0_high_alarm_setpoint')?.disable();
          control.parent?.get('temperature_0_high_alarm_setpoint')?.reset();
        } else control.parent?.get('temperature_0_high_alarm_setpoint')?.enable();
      };
    },
  } as Configurable,
  temperature_0_lead_resistance: {
    article: 'temperature_0_lead_resistance',
    defaultValue: '5.00Ω',
    group: 'Regs/Config/TemperatureSetup/TSRTDLeadResistance/elem',
    label: 'Temperature lead resistance',
    type: 'range',
    unit: '_Ω',
    validator(ctx?: Context) {
      [this.min, this.max] = [0.0, 20.0];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  temperature_0_low_alarm_filter: {
    article: 'temperature_0_low_alarm_filter',
    defaultValue: '50s',
    group: 'Regs/Config/TemperatureSetup/LowTSAlarmFilter',
    label: 'Temperature low alarm filter',
    type: 'range',
    unit: '<s',
    validator(ctx?: Context) {
      [this.min, this.max] = [0, 59940];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  temperature_0_low_alarm_setpoint: {
    article: 'temperature_0_low_alarm_setpoint',
    defaultValue: '-10.0°C',
    group: 'Regs/Config/TemperatureSetup/LowTSAlarmSetpoint/elem',
    label: 'Temperature low alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      this.min = -60.0;
      this.max = 570.0;
      if (ctx?.temperaturePreferenceUnit !== 'Celsius') {
        this.min = (this.min * 9) / 5 + 32;
        this.max = (this.max * 9) / 5 + 32;
      }
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  temperature_0_low_mask: {
    article: 'temperature_0_low_mask',
    defaultValue: 'True',
    group: 'Regs/Config/Alarm/Mask/TemperatureSensorLow/elem',
    label: 'Temperature low mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        if (!control.value) {
          control.parent?.get('temperature_0_low_alarm_setpoint')?.disable();
          control.parent?.get('temperature_0_low_alarm_setpoint')?.reset();
        } else control.parent?.get('temperature_0_low_alarm_setpoint')?.enable();
      };
    },
  } as Configurable,
  temperature_0_type: {
    article: 'temperature_0_type',
    defaultValue: 1,
    group: 'Regs/Config/TemperatureSetup/TSRTDType/elem',
    label: 'Temperature type',
    options: [
      { key: 0, value: 'THREE_WIRE_100_OHM_PLATINUM', displayText: 'THREE WIRE 100 OHM PLATINUM' },
      {
        key: 1,
        value: 'TWO_OR_THREE_WIRE_100_OHM_NICKEL_IRON',
        displayText: 'TWO OR THREE WIRE 100 OHM NICKEL IRON',
      },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        if (control.value === 'TWO_OR_THREE_WIRE_100_OHM_NICKEL_IRON')
          control.parent?.get('temperature_0_lead_resistance')?.enable();
        else {
          control.parent?.get('temperature_0_lead_resistance')?.disable();
          control.parent?.get('temperature_0_lead_resistance')?.reset();
        }
      };
    },
  } as Configurable,
  temperature_1_failure_mask: {
    article: 'temperature_1_failure_mask',
    defaultValue: 'True',
    group: 'Regs/Config/Alarm/Mask/TemperatureSensorFailure/elem',
    label: 'Temperature 1 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  temperature_1_high_alarm_setpoint: {
    article: 'temperature_1_high_alarm_setpoint',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSetup/HighTSAlarmSetpoint/elem',
    label: 'Temperature 1 high alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      this.min = -60.0;
      this.max = 570.0;
      if (ctx?.temperaturePreferenceUnit !== 'Celsius') {
        this.min = (this.min * 9) / 5 + 32;
        this.max = (this.max * 9) / 5 + 32;
      }
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  temperature_1_high_cutout_enabled: {
    article: 'temperature_1_high_cutout_enabled',
    defaultValue: 0,
    group: 'Regs/Config/TemperatureSetup/CutoutEnabled/elem',
    label: 'Temperature 1 high cutout enabled',
    type: 'int1',
    unit: '',
    validator(ctx?: Context) {
      [this.min, this.max] = [0, 1];
      return (control: AbstractControl): any | null => {
        return control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  temperature_1_high_mask: {
    article: 'temperature_1_high_mask',
    defaultValue: 'True',
    group: 'Regs/Config/Alarm/Mask/TemperatureSensorHigh/elem',
    label: 'Temperature 1 high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  temperature_1_lead_resistance: {
    article: 'temperature_1_lead_resistance',
    defaultValue: '5.00Ω',
    group: 'Regs/Config/TemperatureSetup/TSRTDLeadResistance/elem',
    label: 'Temperature 1 lead resistance',
    type: 'range',
    unit: '_Ω',
    validator(ctx?: Context) {
      [this.min, this.max] = [0.0, 20.0];
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  temperature_1_low_alarm_setpoint: {
    article: 'temperature_1_low_alarm_setpoint',
    defaultValue: '-10.0°C',
    group: 'Regs/Config/TemperatureSetup/LowTSAlarmSetpoint/elem',
    label: 'Temperature 1 low alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      this.min = -60.0;
      this.max = 570.0;
      if (ctx?.temperaturePreferenceUnit !== 'Celsius') {
        this.min = (this.min * 9) / 5 + 32;
        this.max = (this.max * 9) / 5 + 32;
      }
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  temperature_1_low_mask: {
    article: 'temperature_1_low_mask',
    defaultValue: 'True',
    group: 'Regs/Config/Alarm/Mask/TemperatureSensorLow/elem',
    label: 'Temperature 1 low mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        control.setValidators([Validators.required]);
      };
    },
  } as Configurable,
  temperature_1_type: {
    article: 'temperature_1_type',
    defaultValue: 1,
    group: 'Regs/Config/TemperatureSetup/TSRTDType/elem',
    label: 'Temperature 1 type',
    options: [
      { key: 0, value: 'THREE_WIRE_100_OHM_PLATINUM', displayText: 'THREE WIRE 100 OHM PLATINUM' },
      {
        key: 1,
        value: 'TWO_OR_THREE_WIRE_100_OHM_NICKEL_IRON',
        displayText: 'TWO OR THREE WIRE 100 OHM NICKEL IRON',
      },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        if (control.value === 'TWO_OR_THREE_WIRE_100_OHM_NICKEL_IRON')
          control.parent?.get('temperature_1_lead_resistance')?.enable();
        else {
          control.parent?.get('temperature_1_lead_resistance')?.disable();
          control.parent?.get('temperature_1_lead_resistance')?.reset();
        }
      };
    },
  },
};
