import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfiguratorComponent } from '@components/configurator/configurator.component';
import { ConfiguratorFormContainerComponent } from '@components/configurator/configurator-form-container/configurator-form-container.component';
import { ConfiguratorFormsComponent } from '@components/configurator/configurator-forms/configurator-forms.component';
import { MaterialModule } from '@app/material.module';
import { SharedModule } from '@shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlockUIModule } from 'ng-block-ui';
import { RouterModule } from '@angular/router';
import { ConfigConfirmationComponent } from '@components/configurator/config-confirmation/config-confirmation.component';

@NgModule({
  declarations: [
    ConfiguratorComponent,
    ConfiguratorFormContainerComponent,
    ConfiguratorFormsComponent,
    ConfigConfirmationComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [ConfiguratorComponent],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BlockUIModule.forRoot(),
  ],
})
export class ConfiguratorModule {}
