<mat-accordion [multi]="false" [togglePosition]="'before'">
  <div class="col-flex all-forms">
    @for (displayForm of configFormOutlet.formContent; track displayForm.formName; let index = $index) {
      <app-configurator-forms
        [isEditable]="isEditable"
        [formFields]="displayForm.controls"
        [configurationFormGroup]="displayForm.formGroup"
        [formHeader]="displayForm.formName"
        [positionIndex]="index"
        [currentHeader]="currentHeader"
        [parentHeader]="parentHeader"></app-configurator-forms>
    }
  </div>
</mat-accordion>
