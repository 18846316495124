import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceType } from '@app/utils/enums/device-enums';
import { Observable } from 'rxjs';
import { AppConfigService } from '../app-config.service';
import { maintenanceInfoParams } from '@app/utils/constants/params';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceInfoService {
  uiBaseURL = '';

  constructor(
    private httpClient: HttpClient,
    appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }

  getDeviceForMaintenanceInfo(deviceId: string | null | undefined, deviceType: string | undefined): Observable<Object> {
    deviceType = deviceType == '4010i/4020i' ? DeviceType.Elexant40X0 : deviceType;
    const params: HttpParams = maintenanceInfoParams(deviceType);
    const url = `${this.uiBaseURL}/${deviceType}s/${deviceId}`;
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/vnd.api+json',
      }),
      params,
    };

    return this.httpClient.get(url, options);
  }
}
