import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ConfiguratorModule } from '@app/components/configurator/configurator.module';
import { AddDevicesComponent } from '@app/components/devices/add-devices/add-devices.component';
import { MaterialModule } from '@app/material.module';
import { SharedModule } from '@app/shared/shared.module';
import { AlarmsModule } from '@components/alarms/alarms.module';
import { DeviceDetailsComponent } from '@components/devices/device-details/device-details.component';
import { DevicesTableViewComponent } from '@components/devices/devices-table-view/devices-table-view.component';
import { BlockUIModule } from 'ng-block-ui';
import { DevicesComponent } from './devices.component';
import { MaintenanceInfoComponent } from './maintenance-info/maintenance-info.component';
@NgModule({
  declarations: [
    DevicesComponent,
    DevicesTableViewComponent,
    DeviceDetailsComponent,
    MaintenanceInfoComponent,
    AddDevicesComponent,
  ],
  imports: [
    RouterModule,
    MaterialModule,
    CommonModule,
    SharedModule,
    BlockUIModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AlarmsModule,
    OverlayModule,
    ConfiguratorModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DevicesModule {}
