import { Injectable } from '@angular/core';
import { DeviceUIModel } from '@models/device';
import { numeric_regex } from '@utils/patterns';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor() {}

  filterByTerm(data: DeviceUIModel[], term: string, key: string, transform: (x: any) => any = x => x): DeviceUIModel[] {
    const searchTerm = term.toLowerCase();
    return data.filter((item: DeviceUIModel) => {
      let value =
        item[key as keyof DeviceUIModel] !== null && item[key as keyof DeviceUIModel] !== undefined
          ? transform(item[key as keyof DeviceUIModel])
          : null;
      return searchTerm === '-'
        ? value === null || value === undefined
        : value !== null && value.toString().toLowerCase().indexOf(searchTerm) !== -1;
    });
  }

  filterByAlarmCount(data: DeviceUIModel[], term: string, key: string): DeviceUIModel[] {
    const count = parseInt(term, 10);
    return data.filter((item: DeviceUIModel) => {
      let value = item[key as keyof DeviceUIModel];
      return value !== null && value !== undefined && value === count;
    });
  }

  filterByNumericTerm(data: DeviceUIModel[], term: string, key: string): DeviceUIModel[] {
    const searchTerm = term;
    return data.filter((item: DeviceUIModel) => {
      let value = item[key as keyof DeviceUIModel]
        ? parseFloat((item[key as keyof DeviceUIModel] as string).replace(numeric_regex, ''))
        : null;
      if (value !== null) {
        value = Math.round(value);
      }
      return searchTerm === '-'
        ? value === null || value === undefined
        : value !== null && value.toString().indexOf(searchTerm) !== -1;
    });
  }

  filterByLineCurrent(data: DeviceUIModel[], term: string): DeviceUIModel[] {
    const searchTerm = term;
    return data.filter((item: DeviceUIModel) => {
      let lineCurrent = item.lineCurrent ? parseFloat(item.lineCurrent.replace(numeric_regex, '')) : null;
      if (lineCurrent !== null) {
        lineCurrent = Math.round(lineCurrent * 10) / 10;
      }
      const lineCurrentString = lineCurrent !== null ? lineCurrent.toFixed(1) : '';
      return searchTerm === '-' ? lineCurrent === null : lineCurrentString.startsWith(searchTerm);
    });
  }
}
