import {
  Component,
  DestroyRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { AlarmUIModel } from '@app/models/alarm';
import { CardInfo, DeviceUIModel } from '@app/models/device';
import { AttributesInfo } from '@app/models/table-items';
import { UserPreferenceUIModel } from '@app/models/user_preference';
import { ConversionService } from '@app/services/conversion.service';
import { DataStoreService } from '@app/services/data-store.service';
import { MaintenanceInfoService } from '@app/services/jsonapi-services/maintenance-info.service';
import { UserUtilService } from '@app/services/user-util.service';
import { alarmListColumns, displayedAlarmColumnsInList } from '@app/utils/constants/alarm-constants';
import { overviewContentCards } from '@app/utils/constants/device-constants';
import { maintenanceInfoAttributes } from '@app/utils/constants/maintenanceInfo-constants';
import { DeviceTabIndexes, DeviceTabs, DeviceType } from '@app/utils/enums/device-enums';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription, distinctUntilChanged, map } from 'rxjs';

@Component({
  selector: 'app-device-details',
  templateUrl: './device-details.component.html',
  styleUrl: './device-details.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class DeviceDetailsComponent implements OnInit, OnChanges {
  @Input() device!: DeviceUIModel;
  @BlockUI('maintenanceBody') blockUI!: NgBlockUI;
  @BlockUI('trendBody') trendBlockUI!: NgBlockUI;
  @BlockUI('maintenanceBody') maintenanceBlockUI!: NgBlockUI;
  @BlockUI('configureBody') configureBlockUI!: NgBlockUI;
  @BlockUI('notesBody') notesBlockUI!: NgBlockUI;
  preferenceUnit!: string;
  overviewContent: CardInfo[] = overviewContentCards;
  deviceAlarms: AlarmUIModel[] | undefined = undefined;
  activeTabLabel: string = '';
  activeTabIndex: number = 0;
  listOfTableColumns: AttributesInfo[] = alarmListColumns;
  displayedColumns: string[] = displayedAlarmColumnsInList.filter(col => col !== 'deviceTag');
  disabledButtonIcons = ['more_horiz', 'expand_content'];
  disabledTabs = [DeviceTabs.HistoryLog];
  disabledTooltipMessage = 'Feature is not yet available';
  peerDevicesList!: DeviceUIModel[];
  configureDeviceTypes: string[] = [
    DeviceType.Ngc40htc3,
    DeviceType.Htc910,
    DeviceType.Htc920,
    DeviceType.Cm2000,
    DeviceType.Cm2000p,
    DeviceType.Elexant40X0i,
    DeviceType.Ngc40htc,
    DeviceType.Ngc40io,
    DeviceType.Ngc40slim,
    DeviceType.Elexant5010i,
    DeviceType.Elexant3500i,
  ];
  maintenanceDisabledDeviceTypes: string[] = [DeviceType.Ngc20, DeviceType.Elexant5010i];
  subscription!: Subscription;
  showTrendSelectionCategory: boolean = false;
  deviceMaintenanceInfo: { [key: string]: string[] } = {};
  isDeviceExist: boolean = true;
  notesOverlayToggle: boolean = false;
  destroyRef = inject(DestroyRef);
  showTrendsTab = false;

  constructor(
    @Inject(Router) private router: Router,
    private dataStoreService: DataStoreService,
    private conversionService: ConversionService,
    private userUtilsService: UserUtilService,
    private maintenanceInfoService: MaintenanceInfoService,
  ) {}

  ngOnInit(): void {
    const previousUrl: any = this.router.lastSuccessfulNavigation?.previousNavigation;
    if (previousUrl && previousUrl?.targetRouterState?.snapshot?.url.includes('configure')) {
      this.getPeerDevices();
      this.switchToTab(DeviceTabs.Configure, DeviceTabIndexes.Configure);
    }
    if (previousUrl && previousUrl?.targetRouterState?.snapshot?.url === '/alarms') {
      this.switchToTab(DeviceTabs.Alarms, DeviceTabIndexes.Alarms);
      this.getDeviceAlarms();
    }
    this.getCurrentUserPreference();
    this.storeMaintenanceInfo();
    this.getDeviceData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['device'].previousValue && changes['device'].currentValue.id !== changes['device'].previousValue.id) {
      if (this.subscription) this.subscription.unsubscribe();
      switch (this.activeTabLabel) {
        case DeviceTabs.Alarms:
          this.getDeviceAlarms();
          break;
        case DeviceTabs.Configure:
          if (!this.configureDeviceTypes.includes(this.device.deviceType!)) this.switchToTab();
          else this.getPeerDevices();
          break;
        case DeviceTabs.MaintenanceInfo:
          if (this.maintenanceDisabledDeviceTypes.includes(this.device.deviceType!)) this.switchToTab();
          else {
            this.isDeviceExist = true;
            this.deviceMaintenanceInfo = {};
            this.storeMaintenanceInfo();
          }
          break;
      }
    }
  }

  switchToTab(tabLabel: string = DeviceTabs.Details, tabIndex: number = 0) {
    this.activeTabLabel = tabLabel;
    this.activeTabIndex = tabIndex;
  }

  toggleNotesOverlay() {
    this.notesOverlayToggle = !this.notesOverlayToggle;
  }

  tabClick(event: MatTabChangeEvent): void {
    this.activeTabLabel = event.tab.textLabel;
    this.activeTabIndex = event.index;
    if (this.subscription) this.subscription.unsubscribe();
    switch (this.activeTabLabel) {
      case DeviceTabs.Alarms:
        this.getDeviceAlarms();
        this.showTrendsTab = false;
        break;
      case DeviceTabs.Configure:
        this.getPeerDevices();
        this.showTrendsTab = false;
        break;
      case DeviceTabs.Trend:
        this.showTrendsTab = true;
        break;
    }
  }

  getPeerDevices(): void {
    this.configureBlockUI.start('Getting config data...');
    this.subscription = this.dataStoreService
      .getDevicesData()
      .pipe(
        map(data => data.data.filter(device => device.bridgeName === this.device?.bridgeName)),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (devicesData: DeviceUIModel[]) => {
          this.peerDevicesList = devicesData;
          this.configureBlockUI.stop();
        },
        error: (err: unknown) => {
          console.error('Error getting devices from Redis', err);
          this.configureBlockUI.stop();
        },
      });
  }

  getDeviceAlarms(): void {
    this.subscription = this.dataStoreService.getAlarmsData().subscribe(alarms => {
      this.deviceAlarms = [...alarms.data.filter(x => x.deviceTag === this.device.deviceTag)];
    });
  }

  toggleTrendSelectionOverlay() {
    this.showTrendSelectionCategory = !this.showTrendSelectionCategory;
  }

  getCurrentUserPreference() {
    this.userUtilsService.getUserPreference().subscribe((userPreference: UserPreferenceUIModel) => {
      this.preferenceUnit = userPreference.unit;
    });
  }

  mapAttributes(obj: any) {
    Object.entries(obj).forEach(([key, value]) => {
      const mainInfoAttribute = this.maintenanceInfoAttributeConverter(key);
      if (mainInfoAttribute && mainInfoAttribute !== '') {
        this.deviceMaintenanceInfo[mainInfoAttribute] = this.conversionService.separateNumericalandUnit(value);
      }
    });
  }

  getDeviceData() {
    this.dataStoreService
      .getUpdatedDeviceData()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(updatedEvent => {
        if (updatedEvent.op === 'update' && updatedEvent.data.id === this.device.id) {
          this.mapAttributes(updatedEvent.data.attributes);
        }
      });
  }

  storeMaintenanceInfo() {
    if (this.device.id && this.device.deviceType) {
      this.maintenanceBlockUI.start('Loading Details...');
      this.maintenanceInfoService
        .getDeviceForMaintenanceInfo(this.device.id, this.device.deviceType)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response: any) => {
            this.mapAttributes(response.included[0].attributes);
            this.maintenanceBlockUI.stop();
          },
          error: () => {
            this.isDeviceExist = false;
            this.maintenanceBlockUI.stop();
          },
        });
    }
  }

  maintenanceInfoAttributeConverter(attr: string) {
    if (attr in maintenanceInfoAttributes) {
      return maintenanceInfoAttributes[attr];
    } else if (this.device.deviceType !== 'ngc40io') {
      return this.conversionService.maintenanceAttribute(attr);
    } else return '';
  }

  ngOnDestroy() {
    this.trendBlockUI.reset();
    this.maintenanceBlockUI.reset();
    this.configureBlockUI.reset();
  }
}
