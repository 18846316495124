<app-overlay [title]="title" class="description">
  <ng-container body>
    <app-alarms-table-view
      [alarmsList]="data"
      [displayedColumns]="displayedColumns"
      [listOfTableColumns]="listOfTableColumns"
      [sortState]="sortState"
      [isFilterVisible]="false"
      [isAlarmPage]="false"
      class="description-table" />
  </ng-container>
  <ng-container footer>
    <div class="action-buttons">
      <button id="close" class="round-edge-button btn-light" (click)="onClose()">Close</button>
    </div>
  </ng-container>
</app-overlay>
