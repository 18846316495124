import { AlarmStateModel } from '@app/models/alarm';
import { AttributesInfo } from '@app/models/table-items';
import { AlarmStateEnum, AlarmTableHeaders } from '@utils/enums/alarm-enums';
import { alarmTypeDescription, alarmTypes } from './alarm-types';

export const alarmStates: AlarmStateModel[] = [
  { state: AlarmStateEnum.Acknowledge, updatedState: AlarmStateEnum.Acknowledged },
  { state: AlarmStateEnum.Shelve, updatedState: AlarmStateEnum.Shelved },
  { state: AlarmStateEnum.UnShelve, updatedState: AlarmStateEnum.UnShelved },
  { state: AlarmStateEnum.Reset, updatedState: AlarmStateEnum.Reset },
  { state: AlarmStateEnum.New, updatedState: AlarmStateEnum.New },
];

export const alarmDisabledStates = [
  {
    state: AlarmStateEnum.Acknowledged,
    disabledStates: [AlarmStateEnum.UnShelve, AlarmStateEnum.New],
  },
  {
    state: AlarmStateEnum.New,
    disabledStates: [AlarmStateEnum.UnShelve],
  },
  {
    state: AlarmStateEnum.Shelved,
    disabledStates: [AlarmStateEnum.Acknowledge, AlarmStateEnum.Reset, AlarmStateEnum.New],
  },
];

export const displayedAlarmColumnsInList: string[] = [
  'deviceTag',
  'alarmType',
  'alarmDetail',
  'timestamp',
  'devicePriority',
  'alarmPriority',
  'shelveTimeRemaining',
  'alarmState',
  'deviceNotes',
];

export const displayedDescriptionColumnsInList: string[] = ['type', 'description'];

export const alarmListColumns: AttributesInfo[] = [
  {
    field: 'deviceTag',
    header: AlarmTableHeaders.deviceTag,
  },
  {
    field: 'alarmType',
    header: AlarmTableHeaders.alarmType,
  },
  {
    field: 'alarmDetail',
    header: AlarmTableHeaders.alarmDetail,
  },
  {
    field: 'timestamp',
    header: AlarmTableHeaders.timestamp,
  },
  {
    field: 'devicePriority',
    header: AlarmTableHeaders.devicePriority,
  },
  {
    field: 'alarmPriority',
    header: AlarmTableHeaders.alarmPriority,
  },
  {
    field: 'shelveTimeRemaining',
    header: AlarmTableHeaders.shelveTimeRemaining,
  },
  {
    field: 'alarmState',
    header: AlarmTableHeaders.alarmState,
  },
  {
    field: 'deviceNotes',
    header: AlarmTableHeaders.deviceNotes,
  },
];

export const alarmDescriptionColumns: AttributesInfo[] = [
  {
    field: 'type',
    header: AlarmTableHeaders.alarmType,
  },
  {
    field: 'description',
    header: AlarmTableHeaders.description,
  },
];

export const alarmShelveReasons = [
  'Device Under Maintenance',
  'Suspected Nuisance Alarm',
  'No action can be taken',
  'Set aside to address other alarms',
  'Other',
];

export const alarmDescriptionData = [
  {
    type: alarmTypes.Communications,
    description: alarmTypeDescription.Communications,
  },
  {
    type: alarmTypes.GroundFault,
    description: alarmTypeDescription.GroundFault,
  },
  {
    type: alarmTypes.HighLoadCurrent,
    description: alarmTypeDescription.HighLoadCurrent,
  },
  {
    type: alarmTypes.HighTemperature,
    description: alarmTypeDescription.HighTemperature,
  },
  {
    type: alarmTypes.LimitingOrTripsOrSwitchFailure,
    description: alarmTypeDescription.LimitingOrTripsOrSwitchFailure,
  },
  {
    type: alarmTypes.LoadResistance,
    description: alarmTypeDescription.LoadResistance,
  },
  {
    type: alarmTypes.LoadVoltage,
    description: alarmTypeDescription.LoadVoltage,
  },
  {
    type: alarmTypes.LowLoadCurrent,
    description: alarmTypeDescription.LowLoadCurrent,
  },
  {
    type: alarmTypes.LowTemperature,
    description: alarmTypeDescription.LowTemperature,
  },
  {
    type: alarmTypes.Other,
    description: alarmTypeDescription.Other,
  },
  {
    type: alarmTypes.TemperatureInput,
    description: alarmTypeDescription.TemperatureInput,
  },
];
