<div>
  <app-header [title]="pageTitle" (searchTag)="filterAlarmsData($event)">
    <ng-container header-right-content>
      <button
        [matTooltip]="isFilterVisible ? 'Hide and Clear Filter' : 'Table Filter'"
        (click)="toggleFilter()"
        class="round-edge-button btn-black table-filter-button">
        <div class="cl-light flex-col-center font-med">
          <span>{{ isFilterVisible ? 'Clear Filter' : 'Table Filter' }}</span>
          <div class="btn-divider"></div>
          <span class="material-symbols-outlined"> filter_alt{{ isFilterVisible ? '_off' : '' }} </span>
        </div>
      </button>
      <button
        class="round-edge-button export-btn-wrapper flex-center"
        (click)="exportTableData()"
        [matTooltip]="'Export to CSV'">
        <div class="cl-light flex-col-center font-med">
          <span class="material-symbols-outlined black-icon"> download </span>
        </div>
      </button>
    </ng-container>
  </app-header>
  <block-ui>
    @if (this.alarmsData) {
      <div class="alarm-container">
        <app-alarms-table-view
          [alarmsList]="filteredAlarmsData"
          [displayedColumns]="displayedColumns"
          [listOfTableColumns]="listOfTableColumns"
          [isFilterVisible]="isFilterVisible"
          [hasPaginator]="true"
          [isResizingOn]="true" />
      </div>
    }
  </block-ui>
</div>
