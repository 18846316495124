import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { Component, DestroyRef, Inject, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlarmUIModel } from '@app/models/alarm';
import { DeviceUIModel } from '@app/models/device';
import { AttributesInfo } from '@app/models/table-items';
import { UserPreferenceUIModel } from '@app/models/user_preference';
import { UserUtilService } from '@app/services/user-util.service';
import { UtilsService } from '@app/services/utils.service';
import { STORAGE_KEYS } from '@app/utils/constants/local-storage-constants';
import { GroupKeys, GroupNames, GroupTypes } from '@app/utils/enums/device-enums';
import { DashboardStoreService } from '@services/dashboard-store.service';
import { getGroupOptions } from '@utils/constants/group-options';
import { PermissionsList } from '@utils/constants/user-permissions';
import { Page } from '@utils/enums/page-enum';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrl: './devices.component.css',
})
export class DevicesComponent implements OnInit {
  @BlockUI() blockUI!: NgBlockUI;
  pageTitle = Page.Devices;
  devicesData: DeviceUIModel[] = [];
  selectedGroup!: GroupTypes;
  selectedGroupKey!: string;
  destroyRef = inject(DestroyRef);
  listOfTableColumns!: AttributesInfo[];
  isloading: boolean = true;
  isFilterVisible = false;
  selectedDevice!: DeviceUIModel;
  selectedDeviceTag!: string;
  groupList!: string[];
  permissionList = PermissionsList;
  isDropdownOpen: boolean = false;
  showGroupOptions: boolean = false;
  overlayType!: CdkOverlayOrigin;
  groups = getGroupOptions(this.selectedGroup);

  constructor(
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    @Inject(Router) private router: Router,
    private utilsService: UtilsService,
    private userUtilsService: UserUtilService,
    private dashboardStoreService: DashboardStoreService,
  ) {}

  ngOnInit(): void {
    this.setInitialGroup();
    this.selectedDeviceTag = this.activatedRoute.snapshot.paramMap.get('deviceTag') || '';
    this.blockUI.start('Loading Devices...');
    this.userUtilsService.getUserPreference().subscribe((userPreference: UserPreferenceUIModel) => {
      this.getTableRowValues(userPreference.unit);
    });
  }

  toggleFilter(): void {
    this.isFilterVisible = !this.isFilterVisible;
  }

  getTableRowValues(unit: string): void {
    const columns = this.utilsService.getDeviceListColumns(unit);
    this.listOfTableColumns = this.selectedDeviceTag ? columns.slice(0, 2) : columns;
  }

  setInitialGroup() {
    const dashboardGroup = this.dashboardStoreService.getGroup();
    if (dashboardGroup && dashboardGroup.type) {
      this.groups = getGroupOptions(dashboardGroup.type);
      this.onGroupUpdate(dashboardGroup.type);
      this.dashboardStoreService.clearGroupType();
    } else {
      const group = GroupTypes[localStorage.getItem(STORAGE_KEYS.selectedGroup) as keyof typeof GroupTypes];
      if (!group) this.onGroupUpdate(GroupTypes.Parent);
      else {
        this.selectedGroup = group;
        this.selectedGroupKey = GroupKeys[group];
      }
    }
  }

  onGroupUpdate(group: GroupTypes): void {
    localStorage.setItem(STORAGE_KEYS.selectedGroup, group);
    this.selectedGroup = group;
    this.selectedGroupKey = GroupKeys[group];
    this.setUniqueGroupsList();
  }

  setUniqueGroupsList(): void {
    const groupSet = new Set<string>([]);
    if (this.selectedGroupKey === GroupKeys.None) groupSet.add(GroupNames.AllDevices);
    else
      this.devicesData.forEach(device => {
        switch (this.selectedGroupKey) {
          case GroupKeys[GroupTypes.OnlineState]:
            device.onlineState ? groupSet.add(GroupNames.Online) : groupSet.add(GroupNames.Offline);
            break;
          case GroupKeys[GroupTypes.AlarmType]:
          case GroupKeys[GroupTypes.AlarmState]:
          case GroupKeys[GroupTypes.AlarmPriority]:
            device.alarmDetails?.forEach((alarm: AlarmUIModel) =>
              groupSet.add(alarm[this.selectedGroupKey as keyof AlarmUIModel]?.toString()!),
            );
            groupSet.add(GroupNames.DevicesWithNoAlarms);
            break;
          default:
            if (device[this.selectedGroupKey as keyof DeviceUIModel] !== undefined)
              groupSet.add(device[this.selectedGroupKey as keyof DeviceUIModel]?.toString()!);
        }
      });
    this.groupList = [...groupSet];
    this.groupList.sort((a, b) => a.localeCompare(b));
  }

  onDeviceListUpdate(devices: DeviceUIModel[]): void {
    this.devicesData = [...devices];
    this.setUniqueGroupsList();
    if (this.selectedDeviceTag && this.devicesData.length) {
      const device = this.devicesData.find(device => this.selectedDeviceTag === device.deviceTag)!;
      if (device) this.selectedDevice = { ...device } as DeviceUIModel;
      else {
        const firstGroup = this.groupList[0];
        const newSelectedDevice = this.devicesData.find(
          device =>
            firstGroup === GroupNames.AllDevices ||
            (firstGroup === GroupNames.Online && device[this.selectedGroupKey as keyof DeviceUIModel]) ||
            (firstGroup === GroupNames.Offline && !device[this.selectedGroupKey as keyof DeviceUIModel]) ||
            device.alarmDetails?.some(alarm => alarm[this.selectedGroupKey as keyof AlarmUIModel] === firstGroup) ||
            (firstGroup === GroupNames.DevicesWithNoAlarms && device.alarmDetails?.length === 0) ||
            (device[this.selectedGroupKey as keyof DeviceUIModel] as string) === firstGroup,
        )!;
        this.selectedDevice = {
          ...newSelectedDevice,
        } as DeviceUIModel;
        this.selectedDeviceTag = newSelectedDevice.deviceTag!;
        this.router.navigateByUrl('/devices/' + newSelectedDevice.deviceTag);
      }
    }
    this.isloading = false;
    this.blockUI.stop();
  }

  setDeviceDetails(device: DeviceUIModel): void {
    this.selectedDeviceTag = device.deviceTag!;
    this.selectedDevice = device;
  }

  selectOverlay(overlayType: CdkOverlayOrigin, buttonType: string) {
    this.isDropdownOpen = !this.isDropdownOpen;
    this.overlayType = overlayType;
    this.showGroupOptions = buttonType === 'groupByBtn';
  }
}
