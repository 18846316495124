import { Group } from '@app/models/group-option';
import { cloneDeep } from 'lodash-es';
import { GroupTypes } from '../enums/device-enums';

export const groupOptions: Group[] = [
  {
    groupName: 'No Grouping',
    active: false,
    value: GroupTypes.None,
    options: [],
  },
  {
    groupName: 'Device',
    active: false,
    options: [
      { value: GroupTypes.Model, viewValue: 'Device Model' },
      { value: GroupTypes.Parent, viewValue: 'Parent Device' },
      { value: GroupTypes.Priority, viewValue: 'Device priority' },
      { value: GroupTypes.OnlineState, viewValue: 'Online state' },
      { value: 'type', viewValue: 'Device type', isDisabled: true },
    ],
  },
  {
    groupName: 'Alarm',
    active: false,
    options: [
      { value: GroupTypes.AlarmState, viewValue: 'Alarm State' },
      { value: GroupTypes.AlarmType, viewValue: 'Alarm Type' },
      { value: GroupTypes.AlarmPriority, viewValue: 'Alarm Priority' },
    ],
  },
  {
    groupName: 'Site Hierarchy',
    active: false,
    options: [
      { value: 'plant', viewValue: 'Plant', isDisabled: true },
      { value: 'area', viewValue: 'Area', isDisabled: true },
      { value: 'unit', viewValue: 'Unit', isDisabled: true },
      { value: 'system', viewValue: 'System', isDisabled: true },
      { value: 'line_number', viewValue: 'Line Number', isDisabled: true },
      { value: 'equipment', viewValue: 'Equipment', isDisabled: true },
      { value: 'breaker_panel', viewValue: 'Breaker Panel', isDisabled: true },
      { value: 'control_panel', viewValue: 'Control Panel', isDisabled: true },
    ],
  },
];

export const getGroupOptions = (selectedGroup: GroupTypes): Group[] => {
  let groups = cloneDeep(groupOptions);
  for (let group of groups) {
    if (group.options.find(option => option.value === selectedGroup) || selectedGroup === group.value) {
      group.active = true;
      break;
    }
  }
  return groups;
};
