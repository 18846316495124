import { Component, Input, OnInit } from '@angular/core';
import { ConfigFormOutlet } from '@app/models/configurator-models/configurator-outline';

@Component({
  selector: 'app-configurator-form-container',
  templateUrl: './configurator-form-container.component.html',
  styleUrl: './configurator-form-container.component.css',
})
export class ConfiguratorFormContainerComponent implements OnInit {
  @Input() configFormOutlet!: ConfigFormOutlet;
  @Input() isEditable: boolean = false;
  @Input() currentHeader: string = '';
  @Input() parentHeader: string = '';
  ngOnInit(): void {}
}
