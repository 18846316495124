export const enum AlarmStateEnum {
  Acknowledge = 'acknowledge',
  Shelve = 'shelve',
  UnShelve = 'un-shelve',
  Reset = 'reset',
  New = 'new',
  Acknowledged = 'acknowledged',
  Shelved = 'shelved',
  UnShelved = 'un-shelved',
}

export const enum AlarmPriority {
  Warning = 'Warning',
  Alarm = 'Alarm',
}

export enum AlarmTableHeaders {
  deviceTag = 'Device Tag',
  alarmType = 'Alarm Type',
  alarmDetail = 'Alarm Detail',
  timestamp = 'Timestamp',
  devicePriority = 'Device Priority',
  alarmPriority = 'Alarm Priority',
  shelveTimeRemaining = 'Shelve Time Remaining',
  alarmState = 'Alarm State',
  deviceNotes = 'Device Notes',
  description = 'Alarm Description',
}
