import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { interval, map } from 'rxjs';

@Component({
  selector: 'app-alarm-timer',
  templateUrl: './alarm-timer.component.html',
  styleUrls: ['./alarm-timer.component.css'],
})
export class AlarmTimerComponent implements OnInit {
  @Input() date!: string | [string, string];
  @Output() isShelveTimeCompletedEmitter = new EventEmitter<void>();
  timeDiffSeconds = 0;
  countDown = 0;
  destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    interval(1000)
      .pipe(
        map(x => {
          return this.calculateTimeDiffInSeconds();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: val => {
          this.countDown = val;
        },
      });
  }

  calculateTimeDiffInSeconds(): number {
    this.timeDiffSeconds = Math.floor((new Date(this.date.toString()).getTime() - new Date().getTime()) / 1000);
    if (this.timeDiffSeconds < 0) {
      this.isShelveTimeCompletedEmitter.emit();
    }
    return this.timeDiffSeconds;
  }
}
