import {
  AfterViewInit,
  Component,
  DestroyRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AlarmStateModel, AlarmUIModel } from '@app/models/alarm';
import { AttributesInfo } from '@app/models/table-items';
import { ConversionService } from '@app/services/conversion.service';
import { Event, EventService } from '@app/services/event.service';
import { Alarm, AlarmData, AlarmService } from '@app/services/jsonapi-services/alarm.service';
import { Command, CommandService } from '@app/services/jsonapi-services/command.service';
import { alarmDisabledStates, alarmStates } from '@app/utils/constants/alarm-constants';
import { AlarmStateEnum, AlarmTableHeaders } from '@app/utils/enums/alarm-enums';
import { EventTypes, Events } from '@app/utils/enums/event-enum';
import { SortOrder } from '@app/utils/enums/sort-order-enum';
import { DashboardFilter } from '@models/dashboard-filter';
import { DashboardStoreService } from '@services/dashboard-store.service';
import { ExportCsvService } from '@services/export-csv.service';
import { UtilsService } from '@services/utils.service';
import { PermissionsService } from '@shared/services/permissions.service';
import { defaultWriteOptions } from '@utils/constants/export-constants';
import { PermissionsList } from '@utils/constants/user-permissions';
import { cloneDeep } from 'lodash-es';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Observable, of } from 'rxjs';
import { utils } from 'xlsx';

@Component({
  selector: 'app-alarms-table-view',
  templateUrl: './alarms-table-view.component.html',
  styleUrl: './alarms-table-view.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class AlarmsTableViewComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() alarmsList!: AlarmUIModel[];
  @Input() displayedColumns: string[] = [];
  @Input() listOfTableColumns!: AttributesInfo[];
  @Input() isFilterVisible!: boolean;
  @Input() isAlarmPage = true;
  @Input() isResizingOn: boolean = false;
  @Input() sortState: Sort = { active: 'timestamp', direction: 'desc' };
  @ViewChild(MatSort) sort!: MatSort;
  @BlockUI('alarmListBody', { scopeToInstance: true }) blockUI!: NgBlockUI;
  selectedAlarm!: AlarmUIModel;
  destroyRef = inject(DestroyRef);
  alarmStates: AlarmStateModel[] = cloneDeep(alarmStates);
  dataSource = new MatTableDataSource<AlarmUIModel>();
  isLoading!: boolean;
  alarmResponseData: Observable<AlarmData> = of();
  filterForm!: FormGroup;
  filteredAlarms: AlarmUIModel[] = [];
  isFormDirty = false;
  matSelectRef!: Partial<MatSelect>;
  showAlarmShelveForm = false;
  showAlarmDescription = false;
  notesOverlayToggle = false;
  selectedDeviceId!: string;
  selectedDeviceTag!: string;
  intervalID: null | ReturnType<typeof setTimeout> = null;
  pageOptions = [10, 20, 50, 100];
  initialized!: Promise<void>;
  dashboardFilter: DashboardFilter | undefined;
  disableStateChange: boolean = false;
  private commandUpdateEventId = 0;
  private deviceIdForAlarmReset: string | undefined;
  private filters!: string;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private alarmService: AlarmService,
    private formBuilderService: FormBuilder,
    private conversionService: ConversionService,
    private _snackBar: MatSnackBar,
    private commandService: CommandService,
    private event: EventService,
    private exportCsvService: ExportCsvService,
    private dashboardStoreService: DashboardStoreService,
    private permissionService: PermissionsService,
    private utilsService: UtilsService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.filteredAlarms = this.alarmsList;
    this.dashboardFilter = this.dashboardStoreService.getFilter();
    this.dashboardStoreService.clearFilter();
    if (!this.dashboardFilter && 'isFilterVisible' in changes) this.clearForm();
    this.sortAndFilterUpdatedData();
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<AlarmUIModel>(this.alarmsList);
    this.filteredAlarms = this.alarmsList;
    this.disableButton();
    this.initializeFilters();
    this.trackFilters();
    this.exportTableToCSV();

    if (this.dashboardFilter) {
      switch (this.dashboardFilter.property) {
        case 'state':
          this.filterForm.controls.alarmState.setValue(
            this.utilsService.titleCaseConverter(this.dashboardFilter.value),
          );
      }
      this.dashboardStoreService.clearFilter();
    }
    this.bindEvents();
  }

  ngAfterViewInit(): void {
    this.isAlarmPage ? (this.dataSource.paginator = this.paginator) : '';
  }

  toggleNotesOverlay(deviceId?: string, deviceTag?: string) {
    this.notesOverlayToggle = !this.notesOverlayToggle;
    if (deviceId) {
      [this.selectedDeviceId, this.selectedDeviceTag] = [deviceId!, deviceTag!];
    }
  }

  trackAlarms(index: number, item: AlarmUIModel) {
    return item.id;
  }

  disabledAlarmStates(elementState: string, alarmState: AlarmStateModel): boolean {
    const disabledStates = alarmDisabledStates.find(x => x.state === elementState)?.disabledStates;
    return (
      elementState === alarmState.state ||
      elementState === alarmState.updatedState ||
      disabledStates?.includes(alarmState.state as AlarmStateEnum)!
    );
  }

  alarmPatch(alarmId: string, newState: string): Observable<AlarmData> {
    const alarmBody: Alarm = {
      type: EventTypes.Alarms,
      id: alarmId,
      attributes: {
        state: newState,
      },
    };
    return this.alarmService.updateAlarm(alarmBody);
  }

  onSelected(alarmData: AlarmUIModel, event: Partial<MatSelectChange>) {
    this.matSelectRef = event.source!;
    if (event.value === AlarmStateEnum.Shelved) {
      this.selectedAlarm = alarmData;
      this.showAlarmShelveForm = true;
    } else {
      this.blockUI.start('Loading Alarms...');
      this.isLoading = true;
      event.value == AlarmStateEnum.Reset
        ? this.resetAlarm(alarmData)
        : this.updateAlarmState(alarmData, event.value === AlarmStateEnum.UnShelved ? '' : event.value);
    }
  }

  resetAlarm(alarmData: AlarmUIModel) {
    this.matSelectRef.writeValue!(null);
    this.deviceIdForAlarmReset = alarmData.deviceId!;
    const articleName = alarmData.articleName!;
    const deadline = this.conversionService.addSecondsToDate(new Date(), 50);
    const clearAlarmPayload: Command = {
      type: 'commands',
      attributes: {
        args: {
          alarms: [`${articleName}`],
        },
        deadline: this.conversionService.convertDateToTimestampWithTimezone(deadline),
        operation: 'clear_alarms',
        done: false,
      },
      relationships: {
        device: {
          data: {
            type: 'devices',
            id: this.deviceIdForAlarmReset,
          },
        },
      },
    };

    this.commandService.addCommand(clearAlarmPayload).subscribe({
      error: () => {
        this.blockUI.stop();
      },
    });
  }

  sortData(sort: Sort): void {
    this.sortState = sort;
    const isAsc: boolean = sort.direction === SortOrder.Ascending;
    const value: string = sort.active;
    if (sort.active === 'timestamp') {
      this.dataSource.data = this.dataSource.data.sort((a, b) => this.sortByDate(a.timestamp!, b.timestamp!, isAsc));
    } else {
      this.dataSource.data = this.dataSource.data.sort((a, b) =>
        this.sortByValue(a[value as keyof AlarmUIModel], b[value as keyof AlarmUIModel], isAsc),
      );
    }
  }

  clearSearch(filterColumn: string): void {
    this.filterForm.get(filterColumn)?.setValue('');
  }

  initializeFilters(): void {
    this.filterForm = this.formBuilderService.group({
      deviceTag: new FormControl(''),
      alarmType: new FormControl(''),
      alarmDetail: new FormControl(''),
      timestamp: new FormControl(''),
      description: new FormControl(''),
      devicePriority: new FormControl(''),
      alarmPriority: new FormControl(''),
      shelveTimeRemaining: new FormControl(''),
      alarmState: new FormControl(''),
    });
  }

  trackFilters(): void {
    this.filterForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(filterName => {
      this.filters = JSON.stringify(filterName);
      this.sortAndFilterUpdatedData();
    });
  }

  getFilteredAlarms(filter: string) {
    const searchTerms = JSON.parse(filter);
    const length = this.filteredAlarms.length;

    this.filteredAlarms = this.alarmsList.filter(
      data =>
        data.deviceTag?.toLowerCase().indexOf(searchTerms.deviceTag.trim().toLowerCase()) !== -1 &&
        data.alarmType?.toLowerCase().indexOf(searchTerms.alarmType.trim().toLowerCase()) !== -1 &&
        data.alarmDetail?.toLowerCase().indexOf(searchTerms.alarmDetail.trim().toLowerCase()) !== -1 &&
        this.conversionService
          .convertDateTimeStringToDate(data.timestamp!)
          ?.toLowerCase()
          .indexOf(searchTerms.timestamp.trim().toLowerCase()) !== -1 &&
        data.devicePriority?.toString().toLowerCase().indexOf(searchTerms.devicePriority.trim().toLowerCase()) !== -1 &&
        data.alarmPriority?.toLowerCase().indexOf(searchTerms.alarmPriority.trim().toLowerCase()) !== -1 &&
        data.alarmState?.toLowerCase().indexOf(searchTerms.alarmState.trim().toLowerCase()) !== -1 &&
        (data.shelveTimeRemaining != 'NA'
          ? this.conversionService
              .calculateTimeDiffInStandardFormat(data.shelveTimeRemaining!)
              .indexOf(searchTerms.shelveTimeRemaining.trim().toLowerCase()) !== -1
          : data.shelveTimeRemaining?.toLowerCase().indexOf(searchTerms.shelveTimeRemaining.trim().toLowerCase()) !==
            -1),
    );
    if (this.filteredAlarms.length !== length) this.isFormDirty = true;
    this.dataSource.data = this.filteredAlarms;
    this.intervalID =
      this.intervalID === null ? setInterval(this.checkShelvedStatus.bind(this), 60000) : this.intervalID;
  }

  checkShelvedStatus() {
    this.alarmsList.forEach(alarm => {
      if (alarm.alarmState !== AlarmStateEnum.Shelved) return;
      const date1 = new Date();
      const date2 = new Date(alarm.shelveTimeRemaining!);
      if (date1 >= date2) {
        this.updateAlarmState(alarm, '');
      }
    });
  }

  clearForm(): void {
    if (this.isFilterVisible) {
      this.isFormDirty = false;
      this.filterForm.patchValue({
        deviceTag: '',
        alarmType: '',
        alarmDetail: '',
        timestamp: '',
        description: '',
        devicePriority: '',
        alarmPriority: '',
        shelveTimeRemaining: '',
        alarmState: '',
      });
    } else {
      this.filters = '';
      this.sortData(this.sortState);
    }
  }

  updateAlarmState(alarmData: AlarmUIModel, alarmState: string) {
    this.alarmResponseData =
      alarmState === ''
        ? this.alarmPatch(alarmData.id!, AlarmStateEnum.New)
        : this.alarmPatch(alarmData.id!, alarmState);
    this.alarmResponseData.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: () => {
        this.dataSource.data = cloneDeep(this.filteredAlarms);
        this.isLoading = false;
        this.blockUI.stop();
      },
      error: (err: unknown) => {
        console.error(err);
        this.blockUI.stop();
        this.isLoading = false;
      },
    });
  }

  closeAlarmOnSave(): void {
    this.showAlarmShelveForm = false;
  }

  closeAlarmOnCancel(): void {
    this.showAlarmShelveForm = false;
    this.matSelectRef.writeValue!(null);
  }

  displayUpdateStateError(): void {
    this._snackBar.open('Failed to update alarm state', '', {
      duration: 2500,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: 'snackbar-error',
    });
    this.matSelectRef.writeValue!(null);
  }

  setAlarmDescription() {
    this.showAlarmDescription = !this.showAlarmDescription;
  }

  exportTableToCSV() {
    this.exportCsvService.exportAlarmListButton.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if (value)
        this.exportCsvService.writeWorkSheetToFile(
          utils.json_to_sheet(this.filterAlarmData(this.dataSource.data)),
          'alarm-list.csv',
          defaultWriteOptions,
          'alarms',
        );
    });
  }

  filterAlarmData(data: AlarmUIModel[]) {
    const exportData: { [key: string]: any }[] = [];
    const result = data.map(item => ({
      deviceTag: item.deviceTag,
      alarmType: item.alarmType,
      alarmDetail: item.alarmDetail,
      timestamp: item.timestamp,
      devicePriority: item.devicePriority,
      alarmPriority: item.alarmPriority,
      shelveTimeRemaining: item.shelveTimeRemaining,
      alarmState: item.alarmState,
    }));
    result.forEach(data => {
      let record: { [key: string]: any } = {};
      Object.keys(data).forEach((item, i) => {
        record[AlarmTableHeaders[item as keyof typeof AlarmTableHeaders]] =
          item === 'timestamp'
            ? this.conversionService.convertDateTimeStringToDate(data['timestamp']!)
            : item === 'shelveTimeRemaining'
              ? this.conversionService.calculateTimeDiffInStandardFormat(data['shelveTimeRemaining']!)
              : Object.values(data)[i];
      });
      exportData.push(record);
    });
    return exportData;
  }

  ngOnDestroy(): void {
    if (this.intervalID) {
      clearTimeout(this.intervalID);
    }
    if (this.commandUpdateEventId !== 0) {
      this.event.deregister(Events.CommandsUpdate, this.commandUpdateEventId);
    }
    this.dashboardStoreService.clearFilter();
  }

  onCommandsUpdate(event: Event) {
    this.initialized
      .then(() => {
        if (event.op == 'update' && event.data.attributes?.hasOwnProperty('done')) {
          if (
            event.data.attributes.operation == 'clear_alarms' &&
            event.data.attributes.done == true &&
            event.data.attributes.error == null
          ) {
            const deadline = this.conversionService.addSecondsToDate(new Date(), 50);
            const pollAlarmsPayload: Command = {
              type: 'commands',
              attributes: {
                args: {},
                deadline: this.conversionService.convertDateToTimestampWithTimezone(deadline),
                operation: 'poll_alarms',
                done: false,
              },
              relationships: {
                device: {
                  data: {
                    type: 'devices',
                    id: this.deviceIdForAlarmReset!,
                  },
                },
              },
            };
            this.commandService.addCommand(pollAlarmsPayload).subscribe({
              next: () => {
                this.blockUI.stop();
              },
              error: () => {
                this.blockUI.stop();
              },
            });
          }
        }
      })
      .catch(err => {
        this.blockUI.stop();
        console.error(err);
      });
  }

  private bindEvents(): void {
    this.initialized = new Promise<void>((resolve, _reject) => {
      this.commandUpdateEventId = this.event.register(Events.CommandsUpdate, this.onCommandsUpdate.bind(this));
      resolve();
    });
  }

  private sortByDate(a: string, b: string, isAsc: boolean): number {
    const date1 = new Date(a);
    const date2 = new Date(b);
    if (!isAsc) {
      return date1 <= date2 || a === undefined ? 1 : -1;
    }
    return date1 >= date2 || a === undefined ? 1 : -1;
  }

  private sortByValue(a: any, b: any, isAsc: boolean): number {
    return isAsc ? (a > b || a === '' ? 1 : a < b ? -1 : 0) : a < b || a === '' ? 1 : a > b ? -1 : 0;
  }

  sortDataDirection(sort: Sort): void {
    sort.direction = this.sortState.direction === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending;
    this.sortData(sort);
  }

  private sortAndFilterUpdatedData(): void {
    this.filters ? this.getFilteredAlarms(this.filters) : (this.dataSource.data = this.filteredAlarms);
    this.sortData(this.sortState);
  }

  private disableButton() {
    const permissions = [PermissionsList.AlarmPermissions.UpdateAlarm, PermissionsList.AlarmPermissions.DeleteAlarm];
    for (let permission of permissions) {
      if (!this.permissionService.checkPermission(permission)) {
        this.disableStateChange = true;
        break;
      }
    }
  }
}
