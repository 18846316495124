import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@app/services/app-config.service';
import { Observable } from 'rxjs';
const MEDIA_TYPE = 'application/vnd.api+json';

export interface CommandAttributes {
  args: { [key: string]: [string] };
  created_at: string;
  deadline: string;
  done: boolean;
  errors: {} | null;
  operation: string;
  result: {} | null;
}

export interface CommandRelationships {
  device: { data: ResourceIdentifierObject };
}

export interface Command<Attrs = Partial<CommandAttributes>, Rels = Partial<CommandRelationships>> {
  type: string;
  id?: string;
  attributes?: Attrs;
  relationships?: Rels;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
}

export interface CommandData {
  data: Command;
  included?: ResourceIdentifierObject[];
}

export interface MultiCommandData {
  data: Command[];
  included?: ResourceIdentifierObject[];
}

@Injectable({
  providedIn: 'root',
})
export class CommandService {
  uiBaseURL = '';
  constructor(
    private http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }

  addCommand(command: Command): Observable<Command> {
    const url = `${this.uiBaseURL}/commands`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: this.authorizationScopeToken(),
        'Content-Type': MEDIA_TYPE,
        Accept: MEDIA_TYPE,
      }),
    };
    return this.http.post<Command>(url, { data: command }, options);
  }
}
