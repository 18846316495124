import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { Configurable, Context } from '@models/configurator-models/form-fields';

export const ngc40htc = {
  alarm_0_mode: {
    article: 'alarm_0_mode',
    defaultValue: 'NORMAL_OPERATION',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterRegs/AlarmOutputMode',
    label: 'Alarm output mode',
    options: [
      { key: 0, value: 'NORMAL_OPERATION', displayText: 'NORMAL OPERATION' },
      { key: 1, value: 'TOGGLE', displayText: 'TOGGLE' },
      { key: 2, value: 'FLASH', displayText: 'FLASH' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  alarm_0_toggle_time: {
    article: 'alarm_0_toggle_time',
    defaultValue: '60s',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterRegs/AlarmOutputToggleTime',
    label: 'Alarm output toggle time',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 240;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  device_broadcast_timeout: {
    article: 'device_broadcast_timeout',
    defaultValue: '1m',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterRegs/BroadcastTimeout',
    label: 'Broadcast timeout',
    type: 'range',
    unit: 'm',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 10;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  device_reset_mask: {
    article: 'device_reset_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/DeviceReset',
    label: 'Device reset',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  device_tag: {
    article: 'device_tag',
    defaultValue: 'NGC40-HTC-{CAN Address}',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterRegs/Tag',
    label: 'Tag',
    type: 'string16',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.max = 40;
        this.min = 1;
        control.setValidators([Validators.maxLength(this.max), Validators.minLength(this.min)]);
      };
    },
  } as Configurable,
  digital_input_0_path: {
    article: 'digital_input_0_path',
    defaultValue: 'None',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterRegs/DigitalInputSourceRoute',
    label: 'Digital input source route',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  digital_input_source_0_alarm_latch: {
    article: 'digital_input_source_0_alarm_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/DigitalInputLocalRemote',
    label: 'Digital input local remote',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  digital_input_source_0_failure_latch: {
    article: 'digital_input_source_0_failure_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/DigitalInputLocalSourceFailure',
    label: 'Digital input local source failure Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  digital_input_source_0_failure_mask: {
    article: 'digital_input_source_0_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/DigitalInputLocalSourceFailure',
    label: 'Digital input local source failure Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  digital_input_source_0_mode: {
    article: 'digital_input_source_0_mode',
    defaultValue: 'NOT_USED',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterRegs/DigitalInputLocalRemoteConfiguration',
    label: 'Digital input local remote configuration',
    options: [
      { key: 0, value: 'NOT_USED', displayText: 'NOT USED' },
      {
        key: 1,
        value: 'ALARM_WHEN_DIGITAL_INPUT_LOCAL_REMOTE_CLOSED',
        displayText: 'ALARM WHEN DIGITAL INPUT LOCAL REMOTE CLOSED',
      },
      {
        key: 2,
        value: 'ALARM_WHEN_DIGITAL_INPUT_LOCAL_REMOTE_OPEN',
        displayText: 'ALARM WHEN DIGITAL INPUT LOCAL REMOTE OPEN',
      },
      {
        key: 3,
        value: 'FORCE_OFF_WHEN_DIGITAL_INPUT_LOCAL_REMOTE_CLOSED',
        displayText: 'FORCE OFF WHEN DIGITAL INPUT LOCAL REMOTE CLOSED',
      },
      {
        key: 4,
        value: 'FORCE_OFF_WHEN_DIGITAL_INPUT_LOCAL_REMOTE_OPEN',
        displayText: 'FORCE OFF WHEN DIGITAL INPUT LOCAL REMOTE OPEN',
      },
      {
        key: 5,
        value: 'FORCE_ON_WHEN_DIGITAL_INPUT_LOCAL_REMOTE_CLOSED',
        displayText: 'FORCE ON WHEN DIGITAL INPUT LOCAL REMOTE CLOSED',
      },
      {
        key: 6,
        value: 'FORCE_ON_WHEN_DIGITAL_INPUT_LOCAL_REMOTE_OPEN',
        displayText: 'FORCE ON WHEN DIGITAL INPUT LOCAL REMOTE OPEN',
      },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_auto_cycle_interval: {
    article: 'htc_0_auto_cycle_interval',
    defaultValue: '0h',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterRegs/AutoCycleInterval',
    label: 'Auto cycle interval',
    type: 'range',
    unit: 'h',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 750;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_circuit_breaker_0_current_rating: {
    article: 'htc_0_circuit_breaker_0_current_rating',
    defaultValue: '30.00A',
    group: 'Regs/Config/ACAnalogParameters/CircuitBreakerCurrentRating',
    label: 'Circuit breaker current rating',
    type: 'range',
    unit: 'A',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.3;
        this.max = 60.0;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_circuit_breaker_0_limiting_latch: {
    article: 'htc_0_circuit_breaker_0_limiting_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/CircuitBreakerLimiting',
    label: 'Circuit breaker limiting Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_circuit_breaker_0_limiting_mask: {
    article: 'htc_0_circuit_breaker_0_limiting_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/CircuitBreakerLimiting',
    label: 'Circuit breaker limiting Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_circuit_breaker_0_type: {
    article: 'htc_0_circuit_breaker_0_type',
    defaultValue: 'NEMA',
    group: 'Regs/Config/ACAnalogParameters/CircuitBreakerType',
    label: 'Circuit breaker type',
    options: [
      { key: 0, value: 'NEMA', displayText: 'NEMA' },
      { key: 1, value: 'TYPE_B', displayText: 'TYPE B' },
      { key: 2, value: 'TYPE_C', displayText: 'TYPE C' },
      { key: 3, value: 'TYPE_D', displayText: 'TYPE D' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_configured_line_frequency: {
    article: 'htc_0_configured_line_frequency',
    defaultValue: '60Hz',
    group: 'Regs/Config/ACAnalogParameters/Frequency',
    label: 'Configured Line Frequency',
    type: 'range',
    unit: 'Hz',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 45;
        this.max = 65;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_configured_line_voltage: {
    article: 'htc_0_configured_line_voltage',
    defaultValue: '120.0V',
    group: 'Regs/Config/ACAnalogParameters/LineLineVoltage',
    label: 'Configured Line voltage',
    type: 'range',
    unit: 'V',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 80.0;
        this.max = 700.0;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_contactor_cycle_count_limit_exceeded_mask: {
    article: 'htc_0_contactor_cycle_count_limit_exceeded_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/ContactorCycleCount',
    label: 'Contactor cycle count',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_contactor_cycle_count_setpoint: {
    article: 'htc_0_contactor_cycle_count_setpoint',
    defaultValue: 100000,
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterRegs/ContactorCycleCountAlarmLimitSetpoint',
    label: 'Contactor cycle count alarm limit setpoint',
    type: 'int',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 999999;
        return control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_control_temperature_failure_latch: {
    article: 'htc_0_control_temperature_failure_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/ControlTemperatureFailure',
    label: 'Control temperature failure',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_control_temperature_high_alarm_filter: {
    article: 'htc_0_control_temperature_high_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/TemperatureSetup/ControlTemperatureHighAlarmFilter',
    label: 'Control temperature high alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 59940;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_control_temperature_high_alarm_setpoint: {
    article: 'htc_0_control_temperature_high_alarm_setpoint',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSetup/ControlTemperatureHighAlarmSetpoint',
    label: 'Control temperature high alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -80.0;
        let max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -112.0;
          max = 1292.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_control_temperature_high_latch: {
    article: 'htc_0_control_temperature_high_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/ControlTemperatureHigh',
    label: 'Control temperature high Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_control_temperature_high_mask: {
    article: 'htc_0_control_temperature_high_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/ControlTemperatureHigh',
    label: 'Control temperature high Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_control_temperature_low_alarm_filter: {
    article: 'htc_0_control_temperature_low_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/TemperatureSetup/ControlTemperatureLowAlarmFilter',
    label: 'Control temperature low alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 59940;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_control_temperature_low_alarm_setpoint: {
    article: 'htc_0_control_temperature_low_alarm_setpoint',
    defaultValue: '5.0°C',
    group: 'Regs/Config/TemperatureSetup/ControlTemperatureLowAlarmSetpoint',
    label: 'Control temperature low alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -80.0;
        let max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -112.0;
          max = 1292.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_control_temperature_low_latch: {
    article: 'htc_0_control_temperature_low_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/ControlTemperatureLow',
    label: 'Control temperature low Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_control_temperature_low_mask: {
    article: 'htc_0_control_temperature_low_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/ControlTemperatureLow',
    label: 'Control temperature low Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_control_temperature_setpoint: {
    article: 'htc_0_control_temperature_setpoint',
    defaultValue: '10.0°C',
    group: 'Regs/Config/ControlParameters/ControlTemperatureSetpoint',
    label: 'Control temperature setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -80.0;
        let max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -112.0;
          max = 1292.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_deadband: {
    article: 'htc_0_deadband',
    defaultValue: '3.0Δ°C',
    group: 'Regs/Config/ControlParameters/Deadband',
    label: 'Deadband',
    type: 'range',
    unit: 'Δ°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = 1.0;
        let max = 50.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = 1.8;
          max = 90.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_energize: {
    article: 'htc_0_energize',
    defaultValue: '0s',
    group: 'Regs/Config/ControlParameters/TestTracing',
    label: 'Test tracing',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 300;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_ground_fault_current_high_alarm_filter: {
    article: 'htc_0_ground_fault_current_high_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/ACAnalogParameters/HighGFCurrentAlarmFilter',
    label: 'High gf current alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 12;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_ground_fault_current_high_alarm_setpoint: {
    article: 'htc_0_ground_fault_current_high_alarm_setpoint',
    defaultValue: '20.0mA',
    group: 'Regs/Config/ACAnalogParameters/HighGFCurrentAlarmSetpoint',
    label: 'High gf current alarm setpoint',
    type: 'range',
    unit: 'mA',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 10.0;
        this.max = 250.0;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_ground_fault_current_high_latch: {
    article: 'htc_0_ground_fault_current_high_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/HighGroundFaultCurrent',
    label: 'High ground fault current Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_ground_fault_current_high_mask: {
    article: 'htc_0_ground_fault_current_high_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/HighGroundFaultCurrent',
    label: 'High ground fault current Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_ground_fault_current_trip_setpoint: {
    article: 'htc_0_ground_fault_current_trip_setpoint',
    defaultValue: '30.0mA',
    group: 'Regs/Config/ACAnalogParameters/GroundFaultTripCurrentSetpoint',
    label: 'Ground fault trip current setpoint',
    type: 'range',
    unit: 'mA',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 10.0;
        this.max = 250.0;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_ground_fault_transformer_failure_latch: {
    article: 'htc_0_ground_fault_transformer_failure_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/GFICurrentTransformerFailure',
    label: 'Gfi current transformer failure Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_ground_fault_transformer_failure_mask: {
    article: 'htc_0_ground_fault_transformer_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/GFICurrentTransformerFailure',
    label: 'Gfi current transformer failure Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_ground_fault_trip_mask: {
    article: 'htc_0_ground_fault_trip_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/GroundFaultTrip',
    label: 'Ground fault trip',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_heater_time_limit_exceeded_mask: {
    article: 'htc_0_heater_time_limit_exceeded_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/HeaterTime',
    label: 'Heater time',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_heater_time_setpoint: {
    article: 'htc_0_heater_time_setpoint',
    defaultValue: '100000h',
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterRegs/HeaterTimeSetpoint',
    label: 'Heater time setpoint',
    type: 'range',
    unit: 'h',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 999999;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_high_limit_cutout_latch: {
    article: 'htc_0_high_limit_cutout_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/HighLimitCutout',
    label: 'High limit cutout Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_high_limit_cutout_mask: {
    article: 'htc_0_high_limit_cutout_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/HighLimitCutout',
    label: 'High limit cutout Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_high_limit_cutout_setpoint: {
    article: 'htc_0_high_limit_cutout_setpoint',
    defaultValue: '700.0°C',
    group: 'Regs/Config/TemperatureSetup/HighLimitCutoutSetpoint',
    label: 'High limit cutout setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -80.0;
        let max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -112.0;
          max = 1292.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_line_current_0_high_alarm_filter: {
    article: 'htc_0_line_current_0_high_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/ACAnalogParameters/HighLineCurrentAlarmFilter',
    label: 'High line current alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 12;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_line_current_0_high_alarm_setpoint: {
    article: 'htc_0_line_current_0_high_alarm_setpoint',
    defaultValue: '30.00A',
    group: 'Regs/Config/ACAnalogParameters/HighLineCurrentAlarmSetpoint',
    label: 'High line current alarm setpoint',
    type: 'range',
    unit: 'A',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.3;
        this.max = 60.0;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_line_current_0_high_latch: {
    article: 'htc_0_line_current_0_high_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/HighLineCurrent',
    label: 'High line current Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_line_current_0_high_mask: {
    article: 'htc_0_line_current_0_high_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/HighLineCurrent',
    label: 'High line current Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_line_current_0_low_alarm_filter: {
    article: 'htc_0_line_current_0_low_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/ACAnalogParameters/LowLineCurrentAlarmFilter',
    label: 'Low line current alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 12;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_line_current_0_low_alarm_setpoint: {
    article: 'htc_0_line_current_0_low_alarm_setpoint',
    defaultValue: '1.00A',
    group: 'Regs/Config/ACAnalogParameters/LowLineCurrentAlarmSetpoint',
    label: 'Low line current alarm setpoint',
    type: 'range',
    unit: 'A',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.3;
        this.max = 60.0;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_line_current_0_low_latch: {
    article: 'htc_0_line_current_0_low_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/LowLineCurrent',
    label: 'Low line current Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_line_current_0_low_mask: {
    article: 'htc_0_line_current_0_low_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/LowLineCurrent',
    label: 'Low line current Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_line_current_0_maximum: {
    article: 'htc_0_line_current_0_maximum',
    defaultValue: '60.00A',
    group: 'Regs/Config/ACAnalogParameters/MaximumCurrentLimitSetpoint',
    label: 'Maximum current limit setpoint',
    type: 'range',
    unit: 'A',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.3;
        this.max = 60.0;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_line_current_0_over_mask: {
    article: 'htc_0_line_current_0_over_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/LineOverCurrentTrip',
    label: 'Line over current trip Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_load_shed_enabled: {
    article: 'htc_0_load_shed_enabled',
    defaultValue: false,
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterBits/LoadSheddingEnabled',
    label: 'Load shedding enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_load_shed_fail_safe_enabled: {
    article: 'htc_0_load_shed_fail_safe_enabled',
    defaultValue: false,
    group: 'Regs/Config/ControllerSetupParameters/ControllerSetupParameterBits/LoadSheddingFailSafeEnabled',
    label: 'Load shedding fail safe enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_load_shed_source_failure_latch: {
    article: 'htc_0_load_shed_source_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/LoadShedSourceFailure',
    label: 'Load shed source failure Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_load_shed_source_failure_mask: {
    article: 'htc_0_load_shed_source_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/LoadShedSourceFailure',
    label: 'Load shed source failure Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_output_0_limiting_latch: {
    article: 'htc_0_output_0_limiting_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/OutputLimiting',
    label: 'Output limiting Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_output_0_limiting_mask: {
    article: 'htc_0_output_0_limiting_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/OutputLimiting',
    label: 'Output limiting Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_output_limit_mode: {
    article: 'htc_0_output_limit_mode',
    defaultValue: 'DISABLE',
    group: 'Regs/Config/ControlParameters/OutputLimitMode',
    label: 'Output limit mode',
    options: [
      { key: 0, value: 'DISABLE', displayText: 'DISABLE' },
      { key: 1, value: 'POWER', displayText: 'POWER' },
      { key: 2, value: 'CURRENT', displayText: 'CURRENT' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_pasc_min_ambient_temperature: {
    article: 'htc_0_pasc_min_ambient_temperature',
    defaultValue: '-40.0°C',
    group: 'Regs/Config/ControlParameters/PASCMinAmbientTemp',
    label: 'Pasc min ambient temp',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -73.0;
        let max = 51.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -99.4;
          max = 123.8;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_pasc_min_pipe_size: {
    article: 'htc_0_pasc_min_pipe_size',
    defaultValue: 'SIZE_1_POINT_3_CM',
    group: 'Regs/Config/ControlParameters/PASCMinPipeSize',
    label: 'Pasc min pipe size',
    options: [
      { key: 0, value: 'SIZE_1_POINT_3_CM', displayText: 'SIZE 1 POINT 3 CM' },
      { key: 1, value: 'SIZE_2_POINT_5_CM', displayText: 'SIZE 2 POINT 5 CM' },
      { key: 2, value: 'SIZE_GE_5_POINT_1_CM', displayText: 'SIZE GE 5 POINT 1 CM' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_pasc_power_adjust: {
    article: 'htc_0_pasc_power_adjust',
    defaultValue: '100%',
    group: 'Regs/Config/ControlParameters/PASCPowerAdjust',
    label: 'Pasc power adjust',
    type: 'range',
    unit: '%',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 10;
        this.max = 200;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_power_0_maximum_limit_setpoint: {
    article: 'htc_0_power_0_maximum_limit_setpoint',
    defaultValue: '42.000kW',
    group: 'Regs/Config/ACAnalogParameters/MaximumPowerLimitSetpoint',
    label: 'Maximum power limit setpoint',
    type: 'range',
    unit: 'W',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 3;
        this.max = 42000;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_proportional_band: {
    article: 'htc_0_proportional_band',
    defaultValue: '2.0Δ°C',
    group: 'Regs/Config/ControlParameters/ProportionalBand',
    label: 'Proportional band',
    type: 'range',
    unit: 'Δ°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = 1.0;
        let max = 50.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = 1.8;
          max = 90.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_ssr_rating: {
    article: 'htc_0_ssr_rating',
    defaultValue: '30.00A',
    group: 'Regs/Config/ACAnalogParameters/SSRRating',
    label: 'Ssr rating',
    type: 'range',
    unit: 'A',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.3;
        this.max = 60.0;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_switch_0_failure_latch: {
    article: 'htc_0_switch_0_failure_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/SwitchFailure',
    label: 'Switch failure Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_switch_0_failure_mask: {
    article: 'htc_0_switch_0_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/SwitchFailure',
    label: 'Switch failure Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_switch_0_limiting_latch: {
    article: 'htc_0_switch_0_limiting_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/SwitchLimiting',
    label: 'Switch limiting Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_switch_0_limiting_mask: {
    article: 'htc_0_switch_0_limiting_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/SwitchLimiting',
    label: 'Switch limiting Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_switch_control_mode: {
    article: 'htc_0_switch_control_mode',
    defaultValue: 'ON_OFF_EMR',
    group: 'Regs/Config/ControlParameters/SwitchControlMode',
    label: 'Switch control mode',
    options: [
      { key: 0, value: 'PROPORTIONAL_SSR', displayText: 'PROPORTIONAL SSR' },
      { key: 1, value: 'ON_OFF_EMR', displayText: 'ON OFF EMR' },
      { key: 2, value: 'ON_OFF_SSR', displayText: 'ON OFF SSR' },
      { key: 3, value: 'PASC_EMR', displayText: 'PASC EMR' },
      { key: 4, value: 'PASC_SSR', displayText: 'PASC SSR' },
      { key: 5, value: 'ALWAYS_ON', displayText: 'ALWAYS ON' },
      { key: 6, value: 'ALWAYS_OFF', displayText: 'ALWAYS OFF' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_control_mode: {
    article: 'htc_0_temperature_control_mode',
    defaultValue: 'LOWEST',
    group: 'Regs/Config/TemperatureSetup/TemperatureControlMode',
    label: 'Temperature control mode',
    options: [
      { key: 0, value: 'NA', displayText: 'NA' },
      { key: 1, value: 'AVERAGE', displayText: 'AVERAGE' },
      { key: 2, value: 'LOWEST', displayText: 'LOWEST' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_fail_mode: {
    article: 'htc_0_temperature_fail_mode',
    defaultValue: 'FAIL_OFF',
    group: 'Regs/Config/TemperatureSetup/TemperatureFailMode',
    label: 'Temperature fail mode',
    options: [
      { key: 0, value: 'FAIL_OFF', displayText: 'FAIL OFF' },
      { key: 1, value: 'FAIL_ON', displayText: 'FAIL ON' },
      { key: 2, value: 'FIXED_PERCENTAGE', displayText: 'FIXED PERCENTAGE' },
      { key: 3, value: 'LAST_PERCENTAGE', displayText: 'LAST PERCENTAGE' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_fail_mode_percentage: {
    article: 'htc_0_temperature_fail_mode_percentage',
    defaultValue: '50%',
    group: 'Regs/Config/TemperatureSetup/TemperatureFailModePercentage',
    label: 'Temperature fail mode percentage',
    type: 'range',
    unit: '%',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 99;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_temperature_source_0_control_enabled: {
    article: 'htc_0_temperature_source_0_control_enabled',
    defaultValue: false,
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSConfig/ControlEnable',
    label: 'Control enable',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_0_failure_latch: {
    article: 'htc_0_temperature_source_0_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/TemperatureSourceFailure/elem',
    label: 'Temperature source 0 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_0_high_cutout_enabled: {
    article: 'htc_0_temperature_source_0_high_cutout_enabled',
    defaultValue: false,
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSConfig/CutoutEnable',
    label: 'Cutout enable',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_0_path: {
    article: 'htc_0_temperature_source_0_path',
    defaultValue: 'None',
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSRoute',
    label: 'Ts route',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_1_control_enabled: {
    article: 'htc_0_temperature_source_1_control_enabled',
    defaultValue: false,
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSConfig/ControlEnable',
    label: 'Control enable',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_1_failure_latch: {
    article: 'htc_0_temperature_source_1_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/TemperatureSourceFailure/elem',
    label: 'Temperature source 1 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_1_high_cutout_enabled: {
    article: 'htc_0_temperature_source_1_high_cutout_enabled',
    defaultValue: false,
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSConfig/CutoutEnable',
    label: 'Cutout enable',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_1_path: {
    article: 'htc_0_temperature_source_1_path',
    defaultValue: 'None',
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSRoute',
    label: 'Ts route',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_2_control_enabled: {
    article: 'htc_0_temperature_source_2_control_enabled',
    defaultValue: false,
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSConfig/ControlEnable',
    label: 'Control enable',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_2_failure_latch: {
    article: 'htc_0_temperature_source_2_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/TemperatureSourceFailure/elem',
    label: 'Temperature source 2 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_2_high_cutout_enabled: {
    article: 'htc_0_temperature_source_2_high_cutout_enabled',
    defaultValue: false,
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSConfig/CutoutEnable',
    label: 'Cutout enable',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_2_path: {
    article: 'htc_0_temperature_source_2_path',
    defaultValue: 'None',
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSRoute',
    label: 'Ts route',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_3_control_enabled: {
    article: 'htc_0_temperature_source_3_control_enabled',
    defaultValue: false,
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSConfig/ControlEnable',
    label: 'Control enable',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_3_failure_latch: {
    article: 'htc_0_temperature_source_3_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/TemperatureSourceFailure/elem',
    label: 'Temperature source 3 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_3_high_cutout_enabled: {
    article: 'htc_0_temperature_source_3_high_cutout_enabled',
    defaultValue: false,
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSConfig/CutoutEnable',
    label: 'Cutout enable',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_3_path: {
    article: 'htc_0_temperature_source_3_path',
    defaultValue: 'None',
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSRoute',
    label: 'Ts route',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_4_control_enabled: {
    article: 'htc_0_temperature_source_4_control_enabled',
    defaultValue: false,
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSConfig/ControlEnable',
    label: 'Control enable',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_4_failure_latch: {
    article: 'htc_0_temperature_source_4_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/TemperatureSourceFailure/elem',
    label: 'Temperature source 4 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_4_high_cutout_enabled: {
    article: 'htc_0_temperature_source_4_high_cutout_enabled',
    defaultValue: false,
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSConfig/CutoutEnable',
    label: 'Cutout enable',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_4_path: {
    article: 'htc_0_temperature_source_4_path',
    defaultValue: 'None',
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSRoute',
    label: 'Ts route',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_5_control_enabled: {
    article: 'htc_0_temperature_source_5_control_enabled',
    defaultValue: false,
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSConfig/ControlEnable',
    label: 'Control enable',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_5_failure_latch: {
    article: 'htc_0_temperature_source_5_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/TemperatureSourceFailure/elem',
    label: 'Temperature source 5 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_5_high_cutout_enabled: {
    article: 'htc_0_temperature_source_5_high_cutout_enabled',
    defaultValue: false,
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSConfig/CutoutEnable',
    label: 'Cutout enable',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_5_path: {
    article: 'htc_0_temperature_source_5_path',
    defaultValue: 'None',
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSRoute',
    label: 'Ts route',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_6_control_enabled: {
    article: 'htc_0_temperature_source_6_control_enabled',
    defaultValue: false,
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSConfig/ControlEnable',
    label: 'Control enable',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_6_failure_latch: {
    article: 'htc_0_temperature_source_6_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/TemperatureSourceFailure/elem',
    label: 'Temperature source 6 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_6_high_cutout_enabled: {
    article: 'htc_0_temperature_source_6_high_cutout_enabled',
    defaultValue: false,
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSConfig/CutoutEnable',
    label: 'Cutout enable',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_6_path: {
    article: 'htc_0_temperature_source_6_path',
    defaultValue: 'None',
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSRoute',
    label: 'Ts route',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_7_control_enabled: {
    article: 'htc_0_temperature_source_7_control_enabled',
    defaultValue: false,
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSConfig/ControlEnable',
    label: 'Control enable',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_7_failure_latch: {
    article: 'htc_0_temperature_source_7_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/TemperatureSourceFailure/elem',
    label: 'Temperature source 7 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_7_high_cutout_enabled: {
    article: 'htc_0_temperature_source_7_high_cutout_enabled',
    defaultValue: false,
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSConfig/CutoutEnable',
    label: 'Cutout enable',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_temperature_source_7_path: {
    article: 'htc_0_temperature_source_7_path',
    defaultValue: 'None',
    group: 'Regs/Config/TemperatureSourceSetup/elem/TSRoute',
    label: 'Ts route',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_tracing_resistance_0_high_alarm_filter: {
    article: 'htc_0_tracing_resistance_0_high_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/ACAnalogParameters/HighTracingResistanceAlarmFilter',
    label: 'High tracing resistance alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 12;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_0_high_deviation: {
    article: 'htc_0_tracing_resistance_0_high_deviation',
    defaultValue: '50%',
    group: 'Regs/Config/ACAnalogParameters/HighTracingResistanceDeviation',
    label: 'High tracing resistance deviation',
    type: 'range',
    unit: '%',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 250;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_0_high_latch: {
    article: 'htc_0_tracing_resistance_0_high_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/HighTracingResistance',
    label: 'High tracing resistance Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_tracing_resistance_0_high_mask: {
    article: 'htc_0_tracing_resistance_0_high_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/HighTracingResistance',
    label: 'High tracing resistance Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_tracing_resistance_0_low_alarm_filter: {
    article: 'htc_0_tracing_resistance_0_low_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/ACAnalogParameters/LowTracingResistanceAlarmFilter',
    label: 'Low tracing resistance alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 12;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_0_low_deviation: {
    article: 'htc_0_tracing_resistance_0_low_deviation',
    defaultValue: '50%',
    group: 'Regs/Config/ACAnalogParameters/LowTracingResistanceDeviation',
    label: 'Low tracing resistance deviation',
    type: 'range',
    unit: '%',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 100;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_0_low_latch: {
    article: 'htc_0_tracing_resistance_0_low_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/LowTracingResistance',
    label: 'Low tracing resistance Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_tracing_resistance_0_low_mask: {
    article: 'htc_0_tracing_resistance_0_low_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/LowTracingResistance',
    label: 'Low tracing resistance Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_tracing_resistance_0_nominal: {
    article: 'htc_0_tracing_resistance_0_nominal',
    defaultValue: '6.00Ω',
    group: 'Regs/Config/ACAnalogParameters/NominalTracingResistance',
    label: 'Nominal tracing resistance',
    type: 'range',
    unit: 'Ω',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.8;
        this.max = 2500.0;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  limiter_0_communication_failure_latch: {
    article: 'limiter_0_communication_failure_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/LimiterCommunicationFailure',
    label: 'Limiter communication failure Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  limiter_0_communication_failure_mask: {
    article: 'limiter_0_communication_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/LimiterCommunicationFailure',
    label: 'Limiter communication failure Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  limiter_0_trip_latch: {
    article: 'limiter_0_trip_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/LimiterTripped',
    label: 'Limiter tripped Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  limiter_0_trip_mask: {
    article: 'limiter_0_trip_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/LimiterTripped',
    label: 'Limiter tripped Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  nvram_0_factory_configuration_data_lost_mask: {
    article: 'nvram_0_factory_configuration_data_lost_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/FactoryConfigurationDataLost',
    label: 'Factory configuration data lost',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  nvram_0_user_configuration_data_lost_mask: {
    article: 'nvram_0_user_configuration_data_lost_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/UserConfigurationDataLost',
    label: 'User configuration data lost',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_0_failure_latch: {
    article: 'temperature_0_failure_latch',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Latch/LocalTemperatureSensorFailure',
    label: 'Local temperature sensor failure',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_0_high_alarm_filter: {
    article: 'temperature_0_high_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/TemperatureSetup/LocalTemperatureSensorHighAlarmFilter',
    label: 'Local temperature sensor high alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 59940;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  temperature_0_high_alarm_setpoint: {
    article: 'temperature_0_high_alarm_setpoint',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSetup/LocalTemperatureSensorHighAlarmSetpoint',
    label: 'Local temperature sensor high alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -80.0;
        let max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -112.0;
          max = 1292.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  temperature_0_high_latch: {
    article: 'temperature_0_high_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/LocalTemperatureSensorHigh',
    label: 'Local temperature sensor high Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_0_high_mask: {
    article: 'temperature_0_high_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/LocalTemperatureSensorHigh',
    label: 'Local temperature sensor high Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_0_lead_resistance: {
    article: 'temperature_0_lead_resistance',
    defaultValue: '0.00Ω',
    group: 'Regs/Config/TemperatureSetup/LocalRTDLeadResistance',
    label: 'Local rtd lead resistance',
    type: 'range',
    unit: 'Ω',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.0;
        this.max = 20.0;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  temperature_0_low_alarm_filter: {
    article: 'temperature_0_low_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/TemperatureSetup/LocalTemperatureSensorLowAlarmFilter',
    label: 'Local temperature sensor low alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 59940;

        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  temperature_0_low_alarm_setpoint: {
    article: 'temperature_0_low_alarm_setpoint',
    defaultValue: '5.0°C',
    group: 'Regs/Config/TemperatureSetup/LocalTemperatureSensorLowAlarmSetpoint',
    label: 'Local temperature sensor low alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -80.0;
        let max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -112.0;
          max = 1292.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min!), Validators.max(this.max!)]);
      };
    },
  } as Configurable,
  temperature_0_low_latch: {
    article: 'temperature_0_low_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/LocalTemperatureSensorLow',
    label: 'Local temperature sensor low Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_0_low_mask: {
    article: 'temperature_0_low_mask',
    defaultValue: false,
    group: 'Regs/Config/Alarm/Mask/LocalTemperatureSensorLow',
    label: 'Local temperature sensor low Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  temperature_0_tag: {
    article: 'temperature_0_tag',
    defaultValue: 'NGC40-HTC-RTD1-{CAN Address}',
    group: 'Regs/Config/TemperatureSetup/LocalTemperatureSensorTag',
    label: 'Local temperature sensor tag',
    type: 'string16',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.max = 40;
        this.min = 1;
        control.setValidators([Validators.maxLength(this.max), Validators.minLength(this.min)]);
      };
    },
  } as Configurable,
  temperature_0_type: {
    article: 'temperature_0_type',
    defaultValue: 'THREE_WIRE_100_OHM_PLATINUM',
    group: 'Regs/Config/TemperatureSetup/LocalTemperatureSensorType',
    label: 'Local temperature sensor type',
    options: [
      { key: 0, value: 'NOT_USED', displayText: 'NOT USED' },
      { key: 1, value: 'THREE_WIRE_100_OHM_PLATINUM', displayText: 'THREE WIRE 100 OHM PLATINUM' },
      { key: 2, value: 'TWO_WIRE_100_OHM_NICKEL_IRON', displayText: 'TWO WIRE 100 OHM NICKEL IRON' },
      { key: 3, value: 'TWO_WIRE_100_OHM_NICKEL', displayText: 'TWO WIRE 100 OHM NICKEL' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
};
