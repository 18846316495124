<div class="header">
  <div class="title font-lg">
    <span>Create New Device </span>
  </div>
</div>
<form [formGroup]="addDevicesForm">
  <div class="outer-container col-flex">
    <div class="inner-container col-flex">
      <span class="font-small"> Device Tag</span>
      <mat-form-field appearance="outline" class="form-field">
        <input class="select-input" formControlName="deviceTag" matInput placeholder="Enter Device Tag" />
        @if (addDevicesForm.controls.deviceTag.errors?.required && addDevicesForm.controls.deviceTag.touched) {
          <mat-error> Device tag is required </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="inner-container col-flex">
      <span class="font-small"> Device Type </span>
      <mat-form-field appearance="outline" class="form-field">
        <mat-select
          class="select-input"
          panelClass="overlay-container select-panel"
          placeholder="Select Device Type"
          formControlName="deviceType">
          @for (deviceType of deviceTypes; track $index) {
            <mat-option [value]="deviceType">
              {{ deviceType }}
            </mat-option>
          }
        </mat-select>
        @if (addDevicesForm.controls.deviceType.errors?.required && addDevicesForm.controls.deviceType.touched) {
          <mat-error> Device type is required </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="inner-container col-flex">
      <span class="font-small"> Site Hierarchy </span>
      <mat-form-field
        appearance="outline"
        class="form-field no-drop-cursor"
        matTooltip="Field is Disabled"
        matTooltipPosition="above">
        <mat-select
          [disabled]="addDevicesForm.controls.siteHierarchy.disabled"
          class="select-input"
          panelClass="overlay-container select-panel"
          placeholder="Select site Hierarchy"
          formControlName="siteHierarchy">
        </mat-select>
      </mat-form-field>
    </div>
    <div class="inner-container col-flex">
      <span class="font-small"> Modbus Address </span>
      <mat-form-field appearance="outline" class="form-field">
        <input class="select-input" formControlName="modbusAddress" matInput placeholder="Enter Modbus Address" />
        @if (addDevicesForm.controls.modbusAddress.errors?.required && addDevicesForm.controls.modbusAddress.touched) {
          <mat-error> Modbus address is required </mat-error>
        }
        @if (addDevicesForm.controls.modbusAddress.errors?.modbusAddress) {
          <mat-error>
            {{ addDevicesForm.controls.modbusAddress.errors?.modbusAddress.value }}
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="inner-container col-flex">
      <span class="font-small"> IP Address </span>
      <mat-form-field appearance="outline" class="form-field">
        <input class="select-input" formControlName="ipAddress" matInput placeholder="Enter IP Address" />
        @if (addDevicesForm.controls.ipAddress.errors?.required && addDevicesForm.controls.ipAddress.touched) {
          <mat-error> IP address is required </mat-error>
        }
        @if (addDevicesForm.controls.ipAddress.errors?.ipAddress) {
          <mat-error>
            {{ addDevicesForm.controls.ipAddress.errors?.ipAddress.value }}
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="inner-container col-flex">
      <div class="checkbox-container">
        <input class="checkbox" type="checkbox" name="offlineState" formControlName="offlineState" />
        <label class="checkbox-label" for="offlineState">Set device initial state to offline</label>
      </div>
      <p class="instruction">
        Set device offline before you physically install it to prevent connection error messages.
      </p>
    </div>
  </div>
</form>
<div class="flex-between flex-col-center add-device-footer">
  <div class="row-flex action-buttons">
    <button class="round-edge-button btn-light" (click)="onCancel()">Cancel</button>
    <button
      class="round-edge-button btn-black"
      [disabled]="addDevicesForm.invalid || !addDevicesForm.get('offlineState')?.value"
      (click)="onSave()">
      Save
    </button>
  </div>
</div>
