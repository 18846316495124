import { Configurable, Options } from '@app/models/configurator-models/form-fields';
import { ngc40htc } from '@app/utils/configurator-meta/ngc40htc-meta';
import { updateBooleanAttributes } from '@app/utils/configurator-util';
import { enableAttributes, latchAttributes } from '@app/utils/constants/configurator-constants';

export const TSOptions: Options[] = [
  { key: 0, value: '00', displayText: 'MONITOR ONLY' },
  { key: 1, value: '01', displayText: 'MONITOR WITH HIGH LIMIT CUTOUT' },
  { key: 2, value: '10', displayText: 'CONTROL ONLY' },
  { key: 3, value: '11', displayText: 'CONTROL WITH HIGH LIMIT CUTOUT' },
];

export const TS1Usage: Configurable[] = [
  ngc40htc.htc_0_temperature_source_0_control_enabled as Configurable,
  ngc40htc.htc_0_temperature_source_0_high_cutout_enabled as Configurable,
];

export const TS2Usage: Configurable[] = [
  ngc40htc.htc_0_temperature_source_1_control_enabled as Configurable,
  ngc40htc.htc_0_temperature_source_1_high_cutout_enabled as Configurable,
];

export const TS3Usage: Configurable[] = [
  ngc40htc.htc_0_temperature_source_2_control_enabled as Configurable,
  ngc40htc.htc_0_temperature_source_2_high_cutout_enabled as Configurable,
];
export const TS4Usage: Configurable[] = [
  ngc40htc.htc_0_temperature_source_3_control_enabled as Configurable,
  ngc40htc.htc_0_temperature_source_3_high_cutout_enabled as Configurable,
];
export const TS5Usage: Configurable[] = [
  ngc40htc.htc_0_temperature_source_4_control_enabled as Configurable,
  ngc40htc.htc_0_temperature_source_4_high_cutout_enabled as Configurable,
];
export const TS6Usage: Configurable[] = [
  ngc40htc.htc_0_temperature_source_5_control_enabled as Configurable,
  ngc40htc.htc_0_temperature_source_5_high_cutout_enabled as Configurable,
];
export const TS7Usage: Configurable[] = [
  ngc40htc.htc_0_temperature_source_6_control_enabled as Configurable,
  ngc40htc.htc_0_temperature_source_6_high_cutout_enabled as Configurable,
];
export const TS8Usage: Configurable[] = [
  ngc40htc.htc_0_temperature_source_7_control_enabled as Configurable,
  ngc40htc.htc_0_temperature_source_7_high_cutout_enabled as Configurable,
];

const temperatureSource = {
  unit: 'None',
  type: 'choice',
  options: TSOptions,
  defaultValue: '0',
};

export const htcConfigurablesForForms: { [key: string]: Configurable[] } = {
  ControlTemperatureHTC: [
    ngc40htc.htc_0_control_temperature_setpoint as Configurable,
    ngc40htc.htc_0_high_limit_cutout_setpoint as Configurable,
    ngc40htc.htc_0_temperature_control_mode as Configurable,
    ngc40htc.htc_0_temperature_fail_mode as Configurable,
    ngc40htc.htc_0_temperature_fail_mode_percentage as Configurable,
  ],
  ControlModesHTC: [
    //"Output Switch Type":"Currently not part of the device description,will be added in a future story"
    ngc40htc.htc_0_switch_control_mode as Configurable,
    ngc40htc.htc_0_deadband as Configurable,
    ngc40htc.htc_0_proportional_band as Configurable,
    ngc40htc.htc_0_pasc_min_ambient_temperature as Configurable,
    ngc40htc.htc_0_pasc_min_pipe_size as Configurable,
    ngc40htc.htc_0_pasc_power_adjust as Configurable,
  ],
  LocalRTDHTC: [
    ngc40htc.temperature_0_type as Configurable,
    ngc40htc.temperature_0_lead_resistance as Configurable,
    ngc40htc.temperature_0_tag as Configurable,
    {
      ...temperatureSource,
      label: 'Usage',
      article: 'TS1Usage',
      childConfigurables: TS1Usage,
    } as Configurable,
  ],

  TemperatureSourceHTC: [
    {
      ...temperatureSource,
      label: 'Temperature Source 2 Usage',
      article: 'TS2Usage',
      childConfigurables: TS2Usage,
    } as Configurable,

    {
      ...temperatureSource,
      label: 'Temperature Source 3 Usage',
      article: 'TS3Usage',
      childConfigurables: TS3Usage,
    } as Configurable,
    {
      ...temperatureSource,
      label: 'Temperature Source 4 Usage',
      article: 'TS4Usage',
      childConfigurables: TS4Usage,
    } as Configurable,
    {
      ...temperatureSource,
      label: 'Temperature Source 5 Usage',
      article: 'TS5Usage',
      childConfigurables: TS5Usage,
    } as Configurable,
    {
      ...temperatureSource,
      label: 'Temperature Source 6 Usage',
      article: 'TS6Usage',
      childConfigurables: TS6Usage,
    } as Configurable,
    {
      ...temperatureSource,
      label: 'Temperature Source 7 Usage',
      article: 'TS7Usage',
      childConfigurables: TS7Usage,
    } as Configurable,
    {
      ...temperatureSource,
      label: 'Temperature Source 8 Usage',
      article: 'TS8Usage',
      childConfigurables: TS8Usage,
    } as Configurable,
  ],
  ElectricalGeneralHTC: [
    ngc40htc.htc_0_output_limit_mode as Configurable,
    ngc40htc.htc_0_configured_line_voltage as Configurable,
    ngc40htc.htc_0_configured_line_frequency as Configurable,
  ],
  LoadSheddingHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_load_shed_enabled as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40htc.htc_0_load_shed_fail_safe_enabled as Configurable, enableAttributes),
    ngc40htc.device_broadcast_timeout as Configurable,
  ],
  AutoCycleIntervalHTC: [ngc40htc.htc_0_auto_cycle_interval as Configurable],
  DigitalInputHTC: [
    ngc40htc.digital_input_source_0_mode as Configurable,
    {
      article: 'digital_input_source',
      label: 'Digital input source',
      unit: '',
      type: 'choice',
      options: [
        { key: 0, value: 'NOT_USED', displayText: 'NOT USED' },
        { key: 1, value: 'LOCAL', displayText: 'LOCAL' },
        { key: 2, value: 'CAN_NETWORK_I/O', displayText: 'CAN NETWORK I/O' },
      ],
      group: 'Digital Input',
      defaultValue: 0,
    } as Configurable,
    ngc40htc.digital_input_0_path as Configurable,
  ],
  DeviceResetAlarmHTC: [updateBooleanAttributes(ngc40htc.device_reset_mask as Configurable, enableAttributes)],
  DigitalInputRemoteSourceFailureAlarmHTC: [
    updateBooleanAttributes(ngc40htc.digital_input_source_0_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40htc.digital_input_source_0_failure_latch as Configurable, latchAttributes),
  ],
  LoadShedSourceFailureAlarmHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_load_shed_source_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40htc.htc_0_load_shed_source_failure_latch as Configurable, latchAttributes),
  ],
  HighLimitCutoutAlarmHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_high_limit_cutout_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40htc.htc_0_high_limit_cutout_latch as Configurable, latchAttributes),
  ],
  ContactorCycleCountAlarmHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_contactor_cycle_count_limit_exceeded_mask as Configurable, enableAttributes),
    ngc40htc.htc_0_contactor_cycle_count_setpoint as Configurable,
  ],
  HeaterTimeAlarmHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_heater_time_limit_exceeded_mask as Configurable, enableAttributes),
    ngc40htc.htc_0_heater_time_setpoint as Configurable,
  ],
  AlarmOutputHTC: [ngc40htc.alarm_0_mode as Configurable, ngc40htc.alarm_0_toggle_time as Configurable],
  ControlTemperatureFailureAlarmHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_control_temperature_failure_latch as Configurable, latchAttributes),
  ],
  ControlTemperatureHighAlarmHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_control_temperature_high_mask as Configurable, enableAttributes),
    ngc40htc.htc_0_control_temperature_high_alarm_setpoint as Configurable,
    ngc40htc.htc_0_control_temperature_high_alarm_filter as Configurable,
    updateBooleanAttributes(ngc40htc.htc_0_control_temperature_high_latch as Configurable, latchAttributes),
  ],
  ControlTemperatureLowAlarmHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_control_temperature_low_mask as Configurable, enableAttributes),
    ngc40htc.htc_0_control_temperature_low_alarm_setpoint as Configurable,
    ngc40htc.htc_0_control_temperature_low_alarm_filter as Configurable,
    updateBooleanAttributes(ngc40htc.htc_0_control_temperature_low_latch as Configurable, latchAttributes),
  ],
  LocalTemperatureSensorFailureAlarmHTC: [
    updateBooleanAttributes(ngc40htc.temperature_0_failure_latch as Configurable, latchAttributes),
  ],
  LocalTemperatureSensorHighAlarmHTC: [
    updateBooleanAttributes(ngc40htc.temperature_0_high_mask as Configurable, enableAttributes),
    ngc40htc.temperature_0_high_alarm_setpoint as Configurable,
    ngc40htc.temperature_0_high_alarm_filter as Configurable,
    updateBooleanAttributes(ngc40htc.temperature_0_high_latch as Configurable, latchAttributes),
  ],
  LocalTemperatureSensorLowAlarmHTC: [
    updateBooleanAttributes(ngc40htc.temperature_0_low_mask as Configurable, enableAttributes),
    ngc40htc.temperature_0_low_alarm_setpoint as Configurable,
    ngc40htc.temperature_0_low_alarm_filter as Configurable,
    updateBooleanAttributes(ngc40htc.temperature_0_low_latch as Configurable, latchAttributes),
  ],
  TemperatureSource1FailureAlarmHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_temperature_source_0_failure_latch as Configurable, latchAttributes),
  ],
  TemperatureSource2FailureAlarmHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_temperature_source_1_failure_latch as Configurable, latchAttributes),
  ],
  TemperatureSource3FailureAlarmHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_temperature_source_2_failure_latch as Configurable, latchAttributes),
  ],
  TemperatureSource4FailureAlarmHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_temperature_source_3_failure_latch as Configurable, latchAttributes),
  ],
  TemperatureSource5FailureAlarmHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_temperature_source_4_failure_latch as Configurable, latchAttributes),
  ],
  TemperatureSource6FailureAlarmHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_temperature_source_5_failure_latch as Configurable, latchAttributes),
  ],
  TemperatureSource7FailureAlarmHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_temperature_source_6_failure_latch as Configurable, latchAttributes),
  ],
  TemperatureSource8FailureAlarmHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_temperature_source_7_failure_latch as Configurable, latchAttributes),
  ],
  DigitalInputAlarmHTC: [
    updateBooleanAttributes(ngc40htc.digital_input_source_0_alarm_latch as Configurable, latchAttributes),
  ],
  SafetyTemperatureLimiterTrippedAlarmHTC: [
    updateBooleanAttributes(ngc40htc.limiter_0_trip_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40htc.limiter_0_trip_latch as Configurable, latchAttributes),
  ],
  SafetyTemperatureLimiterCommunicationFailureAlarmHTC: [
    updateBooleanAttributes(ngc40htc.limiter_0_communication_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40htc.limiter_0_communication_failure_latch as Configurable, latchAttributes),
  ],
  GroundFaultHighAlarmHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_ground_fault_current_high_mask as Configurable, enableAttributes),
    ngc40htc.htc_0_ground_fault_current_high_alarm_setpoint as Configurable,
    ngc40htc.htc_0_ground_fault_current_high_alarm_filter as Configurable,
    updateBooleanAttributes(ngc40htc.htc_0_ground_fault_current_high_latch as Configurable, latchAttributes),
  ],
  GroundFaultTripHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_ground_fault_trip_mask as Configurable, enableAttributes),
    ngc40htc.htc_0_ground_fault_current_trip_setpoint as Configurable,
  ],
  GFICurrentTransformerFailureAlarmHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_ground_fault_transformer_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40htc.htc_0_ground_fault_transformer_failure_latch as Configurable, latchAttributes),
  ],
  HeatingCableResistanceHighCurrentAlarmsHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_tracing_resistance_0_high_mask as Configurable, enableAttributes),
    ngc40htc.htc_0_tracing_resistance_0_high_deviation as Configurable,
    ngc40htc.htc_0_tracing_resistance_0_high_alarm_filter as Configurable,
    updateBooleanAttributes(ngc40htc.htc_0_tracing_resistance_0_high_latch as Configurable, latchAttributes),
  ],
  HeatingCableResistanceLowCurrentAlarmsHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_tracing_resistance_0_low_mask as Configurable, enableAttributes),
    ngc40htc.htc_0_tracing_resistance_0_low_deviation as Configurable,
    ngc40htc.htc_0_tracing_resistance_0_low_alarm_filter as Configurable,
    updateBooleanAttributes(ngc40htc.htc_0_tracing_resistance_0_low_latch as Configurable, latchAttributes),
  ],
  HeatingCableNominalTracingResistanceHTC: [ngc40htc.htc_0_tracing_resistance_0_nominal as Configurable],
  CircuitBreakerandOutputSwitchAlarmsHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_output_0_limiting_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40htc.htc_0_output_0_limiting_latch as Configurable, latchAttributes),
    updateBooleanAttributes(ngc40htc.htc_0_circuit_breaker_0_limiting_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40htc.htc_0_circuit_breaker_0_limiting_latch as Configurable, latchAttributes),
    updateBooleanAttributes(ngc40htc.htc_0_switch_0_limiting_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40htc.htc_0_switch_0_limiting_latch as Configurable, latchAttributes),
    updateBooleanAttributes(ngc40htc.htc_0_line_current_0_over_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40htc.htc_0_switch_0_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40htc.htc_0_switch_0_failure_latch as Configurable, latchAttributes),
  ],
  CircuitBreakerHTC: [
    ngc40htc.htc_0_line_current_0_maximum as Configurable,
    ngc40htc.htc_0_power_0_maximum_limit_setpoint as Configurable,
    ngc40htc.htc_0_ssr_rating as Configurable,
    ngc40htc.htc_0_circuit_breaker_0_type as Configurable,
  ],
  HighCurrentAlarmsHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_line_current_0_high_mask as Configurable, enableAttributes),
    ngc40htc.htc_0_line_current_0_high_alarm_setpoint as Configurable,
    ngc40htc.htc_0_line_current_0_high_alarm_filter as Configurable,
    updateBooleanAttributes(ngc40htc.htc_0_line_current_0_high_latch as Configurable, latchAttributes),
  ],
  LowCurrentAlarmsHTC: [
    updateBooleanAttributes(ngc40htc.htc_0_line_current_0_low_mask as Configurable, enableAttributes),
    ngc40htc.htc_0_line_current_0_low_alarm_setpoint as Configurable,
    ngc40htc.htc_0_line_current_0_low_alarm_filter as Configurable,
    updateBooleanAttributes(ngc40htc.htc_0_line_current_0_low_latch as Configurable, latchAttributes),
  ],
  DeviceInformationHTC: [
    ngc40htc.device_tag as Configurable,
    {
      article: 'device_type',
      label: 'Device type',
      unit: 'None',
      type: 'constant',
      disabled: true,
      group: 'Misc Settings\\Device Information',
    } as Configurable,
    {
      article: 'device_firmware_version',
      label: 'Firmware version',
      unit: 'None',
      type: 'constant',
      disabled: true,
      group: 'Misc Settings\\Device Information',
    } as Configurable,
    {
      article: 'device_serial',
      label: 'Serial number',
      unit: 'None',
      type: 'constant',
      disabled: true,
      group: 'Misc Settings\\Device Information',
    } as Configurable,
  ],
};
