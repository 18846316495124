import { Component, Input } from '@angular/core';
import { DeviceUIModel } from '@app/models/device';
@Component({
  selector: 'app-maintenance-info',
  templateUrl: './maintenance-info.component.html',
  styleUrl: './maintenance-info.component.css',
})
export class MaintenanceInfoComponent {
  @Input() device!: DeviceUIModel;
  @Input() latestMaintenanceInfo: { [key: string]: string[] } = {};
  @Input() isDeviceExist!: boolean;
  constructor() {}
}
