<app-overlay [title]="title" [isLoading]="isLoading" [loadingMessage]="blockUIMessage">
  <ng-container body>
    @if (alarmShelveForm) {
      <form id="alarmShelveForm" [formGroup]="alarmShelveForm">
        <div class="col-flex shelve-form-wrapper">
          <div>
            <mat-card class="alarm-details">
              <mat-card-content>
                @for (data of alarmInfo; track $index) {
                  <span>
                    <span> {{ data.field }}: </span>
                    <span>
                      <b id="{{ data.field | camelcase }}Value">{{ data.value }}</b> |
                    </span>
                  </span>
                }
              </mat-card-content>
            </mat-card>
          </div>
          <div class="shelve-form-input">
            <span>
              <span class="material-symbols-filled"> lightbulb </span>
              Reason for Shelving*
            </span>
            <div class="shelve-reason-dropdown">
              <mat-form-field appearance="outline" class="form-field">
                <mat-select
                  data-testid="reasonForShelving"
                  id="reasonForShelving"
                  placeholder="Select Reason"
                  formControlName="shelvingReason">
                  @for (shelvingReason of shelvingReasons; track $index) {
                    <mat-option [value]="shelvingReason" [id]="shelvingReason | camelcase">
                      {{ shelvingReason }}
                    </mat-option>
                  }
                </mat-select>
                @if (
                  alarmShelveForm.controls.shelvingReason.errors?.required &&
                  alarmShelveForm.controls.shelvingReason.touched
                ) {
                  <mat-error id="reasonForShelvingError">Field is required</mat-error>
                }
              </mat-form-field>
            </div>
          </div>
          @if (alarmShelveForm.controls['shelvingReason'].value === 'Other') {
            <div class="shelve-form-input">
              <span data-cy="otherShelvingReasonTitle"> Other Reason* </span>
              <span class="other-reason">
                <mat-form-field appearance="outline" class="form-field">
                  <textarea matInput formControlName="otherReason" id="otherShelvingReason"></textarea>
                  @if (
                    alarmShelveForm.controls.otherReason.errors?.required &&
                    alarmShelveForm.controls.otherReason.touched
                  ) {
                    <mat-error id="otherShelvingReasonError">Field is required</mat-error>
                  }
                </mat-form-field>
              </span>
            </div>
          }
          <div class="shelve-form-input">
            <span> Shelve Duration* </span>
            <div class="shelve-duration-form flex-between">
              @for (field of shelveDurationFields; track $index) {
                <div class="shelve-duration-input">
                  <span id="{{ field.controlName }}Title">
                    <span class="material-symbols-filled">
                      {{ field.iconName }}
                    </span>
                    {{ field.fieldName }}*
                  </span>
                  <span>
                    <mat-form-field appearance="outline" class="form-field">
                      <input
                        matInput
                        type="number"
                        min="0"
                        step="1"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        formControlName="{{ field.controlName }}"
                        (click)="onInputClick(field.controlName)"
                        id="{{ field.controlName }}" />
                      @if (alarmShelveForm.controls[field.controlName].errors?.max) {
                        <mat-error id="{{ field.controlName }}Error"
                          >{{ field.fieldName }} should be less than
                          {{ alarmShelveForm.controls[field.controlName].errors?.max.max + 1 }}</mat-error
                        >
                      } @else if (alarmShelveForm.controls[field.controlName].errors?.required) {
                        <mat-error id="{{ field.controlName }}Error">Field is required</mat-error>
                      } @else if (
                        shelveDurationError &&
                        field.fieldName === 'Days' &&
                        (this.alarmShelveForm.get('shelveDurationInDays')?.dirty ||
                          this.alarmShelveForm.get('shelveDurationInHours')?.dirty ||
                          this.alarmShelveForm.get('shelveDurationInMins')?.dirty)
                      ) {
                        <mat-error id="shelveDurationError">Shelve Duration should be greater than 0</mat-error>
                      }
                    </mat-form-field>
                  </span>
                </div>
              }
            </div>
          </div>
          <div class="shelve-form-input">
            <span id="commentsTitle">
              <span class="material-symbols-filled"> comment </span>
              Comments
            </span>
            <span>
              <textarea formControlName="comments" id="comments"></textarea>
            </span>
          </div>
        </div>
      </form>
    }
    <div class="mandatory-text">Fields marked with an asterisk(*) are mandatory.</div>
  </ng-container>
  <ng-container footer>
    @if (alarmShelveForm) {
      @if (unsavedChangesWarning) {
        <div class="flex-col-center">
          <span class="material-symbols-filled warning-icon"> warning </span>
          <span class="warning-msg" id="warningMessage">{{ unsavedChangesWarning }}</span>
        </div>
      }
      <div class="action-buttons">
        <button id="cancel" class="round-edge-button btn-light" (click)="onCancel()">Cancel</button>
        <button
          id="saveForm"
          class="round-edge-button btn-black"
          (click)="onSave()"
          [disabled]="shelveDurationError || alarmShelveForm.invalid">
          Save
        </button>
      </div>
    }
  </ng-container>
</app-overlay>
