import { ngc40io } from '@app/utils/configurator-meta/ngc40io-meta';
import { Configurable } from './form-fields';
import { updateBooleanAttributes } from '@app/utils/configurator-util';
import { enableAttributes, latchAttributes } from '@app/utils/constants/configurator-constants';

export const ngc40ioConfigurablesForForms: { [key: string]: Configurable[] } = {
  IOAlarmsandOutputIO: [
    updateBooleanAttributes(ngc40io.device_reset_mask as Configurable, enableAttributes),
    ngc40io.digital_input_source_0_mode as Configurable,
    ngc40io.alarm_0_mode as Configurable,
    ngc40io.alarm_0_toggle_time as Configurable,
  ],
  AlarmsandOutputIO: [
    updateBooleanAttributes(ngc40io.device_reset_mask as Configurable, enableAttributes),
    ngc40io.digital_input_source_0_mode as Configurable,
    ngc40io.alarm_0_mode as Configurable,
    ngc40io.alarm_0_toggle_time as Configurable,
  ],
  TS1RTDIO: [
    ngc40io.temperature_0_type as Configurable,
    ngc40io.temperature_0_lead_resistance as Configurable,
    ngc40io.temperature_0_tag as Configurable,
  ],
  TS1HighAlarmIO: [
    updateBooleanAttributes(ngc40io.temperature_0_high_mask as Configurable, enableAttributes),
    ngc40io.temperature_0_high_alarm_setpoint as Configurable,
    ngc40io.temperature_0_high_alarm_filter as Configurable,
  ],
  TS1LowAlarmIO: [
    updateBooleanAttributes(ngc40io.temperature_0_low_mask as Configurable, enableAttributes),
    ngc40io.temperature_0_low_alarm_setpoint as Configurable,
    ngc40io.temperature_0_low_alarm_filter as Configurable,
  ],
  TS1FailureAlarmIO: [
    updateBooleanAttributes(ngc40io.temperature_0_low_mask as Configurable, enableAttributes),
    ngc40io.temperature_0_low_alarm_setpoint as Configurable,
    ngc40io.temperature_0_low_alarm_filter as Configurable,
  ],
  TS2RTDIO: [
    ngc40io.temperature_1_type as Configurable,
    ngc40io.temperature_1_lead_resistance as Configurable,
    ngc40io.temperature_1_tag as Configurable,
  ],
  TS2HighAlarmIO: [
    updateBooleanAttributes(ngc40io.temperature_1_high_mask as Configurable, enableAttributes),
    ngc40io.temperature_1_high_alarm_setpoint as Configurable,
    ngc40io.temperature_1_high_alarm_filter as Configurable,
  ],
  TS2LowAlarmIO: [
    updateBooleanAttributes(ngc40io.temperature_1_low_mask as Configurable, enableAttributes),
    ngc40io.temperature_1_low_alarm_setpoint as Configurable,
    ngc40io.temperature_1_low_alarm_filter as Configurable,
  ],
  TS2FailureAlarmIO: [
    updateBooleanAttributes(ngc40io.temperature_1_low_mask as Configurable, enableAttributes),
    ngc40io.temperature_1_low_alarm_setpoint as Configurable,
    ngc40io.temperature_1_low_alarm_filter as Configurable,
  ],
  TS3RTDIO: [
    ngc40io.temperature_2_type as Configurable,
    ngc40io.temperature_2_lead_resistance as Configurable,
    ngc40io.temperature_2_tag as Configurable,
  ],
  TS3HighAlarmIO: [
    updateBooleanAttributes(ngc40io.temperature_2_high_mask as Configurable, enableAttributes),
    ngc40io.temperature_2_high_alarm_setpoint as Configurable,
    ngc40io.temperature_2_high_alarm_filter as Configurable,
  ],
  TS3LowAlarmIO: [
    updateBooleanAttributes(ngc40io.temperature_2_low_mask as Configurable, enableAttributes),
    ngc40io.temperature_2_low_alarm_setpoint as Configurable,
    ngc40io.temperature_2_low_alarm_filter as Configurable,
  ],
  TS3FailureAlarmIO: [
    updateBooleanAttributes(ngc40io.temperature_2_low_mask as Configurable, enableAttributes),
    ngc40io.temperature_2_low_alarm_setpoint as Configurable,
    ngc40io.temperature_2_low_alarm_filter as Configurable,
  ],
  TS4RTDIO: [
    ngc40io.temperature_3_type as Configurable,
    ngc40io.temperature_3_lead_resistance as Configurable,
    ngc40io.temperature_3_tag as Configurable,
  ],
  TS4HighAlarmIO: [
    updateBooleanAttributes(ngc40io.temperature_3_high_mask as Configurable, enableAttributes),
    ngc40io.temperature_3_high_alarm_setpoint as Configurable,
    ngc40io.temperature_3_high_alarm_filter as Configurable,
  ],
  TS4LowAlarmIO: [
    updateBooleanAttributes(ngc40io.temperature_3_low_mask as Configurable, enableAttributes),
    ngc40io.temperature_3_low_alarm_setpoint as Configurable,
    ngc40io.temperature_3_low_alarm_filter as Configurable,
  ],
  TS4FailureAlarmIO: [
    updateBooleanAttributes(ngc40io.temperature_3_low_mask as Configurable, enableAttributes),
    ngc40io.temperature_3_low_alarm_setpoint as Configurable,
    ngc40io.temperature_3_low_alarm_filter as Configurable,
  ],
  //   IOAlarmsAndOutputIO:
  //   Path data for all failure alarms is null in the database. Commented it for the time being
  Source1To5FailureAlarmIO: [
    updateBooleanAttributes(ngc40io.alarm_source_0_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_0_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_0_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_1_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_1_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_1_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_2_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_2_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_2_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_3_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_3_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_3_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_4_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_4_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_4_path as Configurable,
  ],
  Source6To10FailureAlarmIO: [
    updateBooleanAttributes(ngc40io.alarm_source_5_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_5_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_5_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_6_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_6_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_6_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_7_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_7_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_7_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_8_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_8_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_8_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_9_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_9_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_9_path as Configurable,
  ],
  Source11To15FailureAlarmIO: [
    updateBooleanAttributes(ngc40io.alarm_source_10_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_10_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_10_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_11_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_11_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_11_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_12_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_12_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_12_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_13_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_13_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_13_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_14_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_14_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_14_path as Configurable,
  ],
  Source16To20FailureAlarmIO: [
    updateBooleanAttributes(ngc40io.alarm_source_15_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_15_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_15_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_16_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_16_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_16_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_17_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_17_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_17_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_18_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_18_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_18_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_19_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_19_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_19_path as Configurable,
  ],
  Source21To25FailureAlarmIO: [
    updateBooleanAttributes(ngc40io.alarm_source_20_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_20_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_20_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_21_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_21_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_21_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_22_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_22_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_22_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_23_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_23_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_23_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_24_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_24_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_24_path as Configurable,
  ],
  Source26To30FailureAlarmIO: [
    updateBooleanAttributes(ngc40io.alarm_source_25_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_25_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_25_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_26_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_26_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_26_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_27_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_27_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_27_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_28_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_28_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_28_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_29_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_29_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_29_path as Configurable,
  ],
  Source31To35FailureAlarmIO: [
    updateBooleanAttributes(ngc40io.alarm_source_30_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_30_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_30_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_31_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_31_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_31_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_32_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_32_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_32_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_33_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_33_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_33_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_34_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_34_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_34_path as Configurable,
  ],
  Source36To40FailureAlarmIO: [
    updateBooleanAttributes(ngc40io.alarm_source_35_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_35_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_35_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_36_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_36_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_36_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_37_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_37_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_37_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_38_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_38_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_38_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_39_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_39_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_39_path as Configurable,
  ],
  Source41To45FailureAlarmIO: [
    updateBooleanAttributes(ngc40io.alarm_source_40_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_40_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_40_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_41_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_41_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_41_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_42_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_42_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_42_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_43_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_43_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_43_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_44_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_44_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_44_path as Configurable,
  ],
  Source46To50FailureAlarmIO: [
    updateBooleanAttributes(ngc40io.alarm_source_45_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_45_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_45_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_46_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_46_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_46_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_47_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_47_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_47_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_48_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_48_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_48_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_49_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_49_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_49_path as Configurable,
  ],
  Source51To55FailureAlarmIO: [
    updateBooleanAttributes(ngc40io.alarm_source_50_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_50_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_50_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_51_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_51_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_51_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_52_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_52_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_52_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_53_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_53_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_53_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_54_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_54_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_54_path as Configurable,
  ],
  Source56To60FailureAlarmIO: [
    updateBooleanAttributes(ngc40io.alarm_source_55_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_55_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_55_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_56_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_56_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_56_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_57_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_57_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_57_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_58_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_58_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_58_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_59_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_59_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_59_path as Configurable,
  ],
  Source61To65FailureAlarmIO: [
    updateBooleanAttributes(ngc40io.alarm_source_60_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_60_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_60_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_61_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_61_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_61_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_62_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_62_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_62_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_63_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_63_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_63_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_64_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_64_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_64_path as Configurable,
  ],
  Source66To70FailureAlarmIO: [
    updateBooleanAttributes(ngc40io.alarm_source_65_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_65_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_65_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_66_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_66_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_66_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_67_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_67_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_67_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_68_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_68_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_68_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_69_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_69_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_69_path as Configurable,
  ],
  Source71To75FailureAlarmIO: [
    updateBooleanAttributes(ngc40io.alarm_source_70_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_70_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_70_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_71_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_71_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_71_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_72_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_72_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_72_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_73_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_73_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_73_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_74_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_74_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_74_path as Configurable,
  ],
  Source76To80FailureAlarmIO: [
    updateBooleanAttributes(ngc40io.alarm_source_75_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_75_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_75_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_76_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_76_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_76_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_77_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_77_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_77_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_78_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_78_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_78_path as Configurable,
    updateBooleanAttributes(ngc40io.alarm_source_79_failure_mask as Configurable, enableAttributes),
    updateBooleanAttributes(ngc40io.alarm_source_79_failure_latch as Configurable, latchAttributes),
    // ngc40io.alarm_source_79_path as Configurable,
  ],
  TemperatureSensor1AlarmsIO: [
    updateBooleanAttributes(ngc40io.temperature_0_high_latch as Configurable, latchAttributes),
    updateBooleanAttributes(ngc40io.temperature_0_low_latch as Configurable, latchAttributes),
    updateBooleanAttributes(ngc40io.temperature_0_failure_latch as Configurable, latchAttributes),
  ],
  TemperatureSensor2AlarmsIO: [
    updateBooleanAttributes(ngc40io.temperature_1_high_latch as Configurable, latchAttributes),
    updateBooleanAttributes(ngc40io.temperature_1_low_latch as Configurable, latchAttributes),
    updateBooleanAttributes(ngc40io.temperature_1_failure_latch as Configurable, latchAttributes),
  ],
  TemperatureSensor3AlarmsIO: [
    updateBooleanAttributes(ngc40io.temperature_2_high_latch as Configurable, latchAttributes),
    updateBooleanAttributes(ngc40io.temperature_2_low_latch as Configurable, latchAttributes),
    updateBooleanAttributes(ngc40io.temperature_2_failure_latch as Configurable, latchAttributes),
  ],
  DigitalInputAlarmIO: [updateBooleanAttributes(ngc40io.digital_input_0_alarm_latch as Configurable, latchAttributes)],
  DeviceInformationIO: [
    ngc40io.device_tag as Configurable,
    {
      article: 'device_type',
      label: 'Device type',
      unit: 'None',
      type: 'constant',
      disabled: true,
      group: 'Misc Settings\\Device Information',
    } as Configurable,
    {
      article: 'device_firmware_version',
      label: 'Firmware version',
      unit: 'None',
      type: 'constant',
      disabled: true,
      group: 'Misc Settings\\Device Information',
    } as Configurable,
    {
      article: 'device_serial',
      label: 'Serial number',
      unit: 'None',
      type: 'constant',
      disabled: true,
      group: 'Misc Settings\\Device Information',
    } as Configurable,
  ],
};
