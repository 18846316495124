import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { Configurable, Context } from '@app//models/configurator-models/form-fields';

export const elexant40x0 = {
  alarm_0_mode: {
    article: 'alarm_0_mode',
    defaultValue: 'NORMAL',
    group: 'Regs/Config/ControllerSetup/AlarmOutputMode',
    label: 'Alarm output mode',
    options: [
      { key: 0, value: 'NORMAL', displayText: 'NORMAL' },
      { key: 1, value: 'TOGGLE', displayText: 'TOGGLE' },
      { key: 2, value: 'FLASH', displayText: 'FLASH' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  alarm_0_toggle_time: {
    article: 'alarm_0_toggle_time',
    defaultValue: '60s',
    group: 'Regs/Config/ControllerSetup/AlarmOutputToggleTime',
    label: 'Alarm output toggle time',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 240;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  device_broadcast_timeout: {
    article: 'device_broadcast_timeout',
    defaultValue: '1m',
    group: 'Regs/Config/ControllerSetup/LoadShedBroadcastTimeout',
    label: 'Load shed broadcast timeout',
    type: 'range',
    unit: 'm',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 10;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  device_reset_mask: {
    article: 'device_reset_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/DeviceReset',
    label: 'Device reset',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  device_tag: {
    article: 'device_tag',
    defaultValue: 'NGC10-{serial number}',
    group: 'Regs/Config/ControllerSetup/Tag',
    label: 'Tag',
    type: 'string16',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.max = 40;
        this.min = 1;
        control.setValidators([Validators.maxLength(this.max), Validators.minLength(this.min)]);
      };
    },
  } as Configurable,
  device_use_time_reset: {
    article: 'device_use_time_reset',
    defaultValue: null,
    group: 'Regs/Config/Maintenance/ClearNumberOfHoursInUse',
    label: 'Clear number of hours in use',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  digital_input_source_0_mode: {
    article: 'digital_input_source_0_mode',
    defaultValue: 'NOT_USED',
    group: 'Regs/Config/ControllerSetup/DigitalInputConfiguration',
    label: 'Digital input configuration',
    options: [
      { key: 0, value: 'NOT_USED', displayText: 'NOT USED' },
      { key: 1, value: 'FORCE_ON', displayText: 'FORCE ON' },
      { key: 2, value: 'FORCE_OFF', displayText: 'FORCE OFF' },
      { key: 3, value: 'ALARM', displayText: 'ALARM' },
      { key: 4, value: 'HOA', displayText: 'HOA' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  digital_input_source_0_polarity: {
    article: 'digital_input_source_0_polarity',
    defaultValue: 'ACTIVE_WHEN_OPEN',
    group: 'Regs/Config/ControllerSetup/DigitalInputPolarity',
    label: 'Digital input polarity',
    options: [
      { key: 0, value: 'ACTIVE_WHEN_OPEN', displayText: 'ACTIVE WHEN OPEN' },
      { key: 1, value: 'ACTIVE_WHEN_CLOSED', displayText: 'ACTIVE WHEN CLOSED' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  eth_0_configured_default_gateway: {
    article: 'eth_0_configured_default_gateway',
    defaultValue: '192.168.1.1',
    group: 'Regs/Config/CommunicationParameters/EthPort/StaticDefaultGateway',
    label: 'Static default gateway',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  eth_0_configured_ip_address: {
    article: 'eth_0_configured_ip_address',
    defaultValue: '192.168.1.100',
    group: 'Regs/Config/CommunicationParameters/EthPort/StaticIPAddress',
    label: 'Static ip address',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  eth_0_configured_net_mask: {
    article: 'eth_0_configured_net_mask',
    defaultValue: '255.255.255.0',
    group: 'Regs/Config/CommunicationParameters/EthPort/StaticNetMask',
    label: 'Static net mask',
    type: 'custom',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  eth_0_modbus_tcp_port: {
    article: 'eth_0_modbus_tcp_port',
    defaultValue: 502,
    group: 'Regs/Config/CommunicationParameters/EthPort/ModbusTCPPort',
    label: 'Modbus tcp port',
    type: 'int',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 65535;
        return control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  eth_0_use_dhcp: {
    article: 'eth_0_use_dhcp',
    defaultValue: null,
    group: 'Regs/Config/Communication/UseDHCP',
    label: 'Use dhcp',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_auto_cycle_enabled: {
    article: 'htc_0_auto_cycle_enabled',
    defaultValue: false,
    group: 'Regs/Config/ControlOptions/AutoCycleEnabled',
    label: 'Auto cycle enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_auto_cycle_interval: {
    article: 'htc_0_auto_cycle_interval',
    defaultValue: '8h',
    group: 'Regs/Config/ControllerSetup/AutoCycleInterval',
    label: 'Auto cycle interval',
    type: 'range',
    unit: 'h',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 750;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_balanced_load: {
    article: 'htc_0_balanced_load',
    defaultValue: true,
    group: 'Regs/Config/Miscellaneous/BalancedLoad',
    label: 'Balanced load',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_circuit_breaker_0_current_rating: {
    article: 'htc_0_circuit_breaker_0_current_rating',
    defaultValue: '30.0A',
    group: 'Regs/Config/ACParameters/CircuitBreakerCurrentRating',
    label: 'Circuit breaker current rating',
    type: 'range',
    unit: 'A',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1.0;
        this.max = 100.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_circuit_breaker_0_limiting_latch: {
    article: 'htc_0_circuit_breaker_0_limiting_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/CircuitBreakerLimiting',
    label: 'Circuit breaker limiting Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_circuit_breaker_0_limiting_mask: {
    article: 'htc_0_circuit_breaker_0_limiting_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/CircuitBreakerLimiting',
    label: 'Circuit breaker limiting Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_circuit_breaker_0_type: {
    article: 'htc_0_circuit_breaker_0_type',
    defaultValue: 'NEMA',
    group: 'Regs/Config/ACParameters/CircuitBreakerType',
    label: 'Circuit breaker type',
    options: [
      { key: 0, value: 'NEMA', displayText: 'NEMA' },
      { key: 1, value: 'IEC_B', displayText: 'IEC B' },
      { key: 2, value: 'IEC_C', displayText: 'IEC C' },
      { key: 3, value: 'IEC_D', displayText: 'IEC D' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_contactor_cycle_count_limit_exceeded_mask: {
    article: 'htc_0_contactor_cycle_count_limit_exceeded_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/ContactorCycleCount',
    label: 'Contactor cycle count Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_contactor_cycle_count_reset: {
    article: 'htc_0_contactor_cycle_count_reset',
    defaultValue: null,
    group: 'Regs/Config/Maintenance/ClearContactorCycleCount',
    label: 'Clear contactor cycle count',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_contactor_cycle_count_setpoint: {
    article: 'htc_0_contactor_cycle_count_setpoint',
    defaultValue: 1000000,
    group: 'Regs/Config/ACParameters/ContactorCycleCountAlarmLimitSetpoint',
    label: 'Contactor cycle count alarm limit setpoint',
    type: 'int',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 1000000;
        return control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_contactor_polarity_under_ssr_active: {
    article: 'htc_0_contactor_polarity_under_ssr_active',
    defaultValue: false,
    group: 'Regs/Config/ControlOptions/ContactorPolarityUnderSSR',
    label: 'Contactor polarity under ssr',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_control_temperature_failure_latch: {
    article: 'htc_0_control_temperature_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/ControlTemperatureFailure',
    label: 'Control temperature failure Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_control_temperature_failure_mask: {
    article: 'htc_0_control_temperature_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/ControlTemperatureFailure',
    label: 'Control temperature failure Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_control_temperature_high_alarm_filter: {
    article: 'htc_0_control_temperature_high_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/TemperatureSetup/ControlTemperatureHighAlarmFilter',
    label: 'Control temperature high alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 59940;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_control_temperature_high_alarm_setpoint: {
    article: 'htc_0_control_temperature_high_alarm_setpoint',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSetup/ControlTemperatureHighAlarmSetpoint',
    label: 'Control temperature high alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -200.0;
        let max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -328.0;
          max = 1292.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_control_temperature_high_latch: {
    article: 'htc_0_control_temperature_high_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/ControlTemperatureHigh',
    label: 'Control temperature high Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_control_temperature_high_mask: {
    article: 'htc_0_control_temperature_high_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/ControlTemperatureHigh',
    label: 'Control temperature high Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_control_temperature_low_alarm_filter: {
    article: 'htc_0_control_temperature_low_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/TemperatureSetup/ControlTemperatureLowAlarmFilter',
    label: 'Control temperature low alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 59940;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_control_temperature_low_alarm_setpoint: {
    article: 'htc_0_control_temperature_low_alarm_setpoint',
    defaultValue: '-10.0°C',
    group: 'Regs/Config/TemperatureSetup/ControlTemperatureLowAlarmSetpoint',
    label: 'Control temperature low alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -200.0;
        let max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -328.0;
          max = 1292.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_control_temperature_low_latch: {
    article: 'htc_0_control_temperature_low_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/ControlTemperatureLow',
    label: 'Control temperature low Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_control_temperature_low_mask: {
    article: 'htc_0_control_temperature_low_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/ControlTemperatureLow',
    label: 'Control temperature low Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_control_temperature_min_max_values_reset: {
    article: 'htc_0_control_temperature_min_max_values_reset',
    defaultValue: null,
    group: 'Regs/Config/Maintenance/ClearControlMinMaxTemperatures',
    label: 'Clear control min max temperatures',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_control_temperature_setpoint: {
    article: 'htc_0_control_temperature_setpoint',
    defaultValue: '10.0°C',
    group: 'Regs/Config/ControlParameters/ControlTemperatureSetpoint',
    label: 'Control temperature setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -200.0;
        let max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -328.0;
          max = 1292.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_current_turns_ratio: {
    article: 'htc_0_current_turns_ratio',
    defaultValue: 1.0,
    group: 'Regs/Config/ACParameters/CurrentTurnsRatio',
    label: 'Current turns ratio',
    type: 'range',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.1;
        this.max = 10.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_deadband: {
    article: 'htc_0_deadband',
    defaultValue: '3.0Δ°C',
    group: 'Regs/Config/ControlParameters/Deadband',
    label: 'Deadband',
    type: 'range',
    unit: 'Δ°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = 1.0;
        let max = 50.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = 1.8;
          max = 90.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_energize: {
    article: 'htc_0_energize',
    defaultValue: '0s',
    group: 'Regs/Config/ControlParameters/TestTracingTime',
    label: 'Test tracing time',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 300;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_energy_consumption_0_reset: {
    article: 'htc_0_energy_consumption_0_reset',
    defaultValue: null,
    group: 'Regs/Config/Maintenance/ClearPowerAccumulator/elem',
    label: 'Energy consumption 0 reset',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_energy_consumption_1_reset: {
    article: 'htc_0_energy_consumption_1_reset',
    defaultValue: null,
    group: 'Regs/Config/Maintenance/ClearPowerAccumulator/elem',
    label: 'Energy consumption 1 reset',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_energy_consumption_2_reset: {
    article: 'htc_0_energy_consumption_2_reset',
    defaultValue: null,
    group: 'Regs/Config/Maintenance/ClearPowerAccumulator/elem',
    label: 'Energy consumption 2 reset',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_energy_consumption_all_phases_reset: {
    article: 'htc_0_energy_consumption_all_phases_reset',
    defaultValue: null,
    group: 'Regs/Config/Maintenance/ClearPowerAccumulatorAllPhases',
    label: 'Clear power accumulator all phases',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_ground_fault_current_high_alarm_filter: {
    article: 'htc_0_ground_fault_current_high_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/ACParameters/HighGFCurrentAlarmFilter',
    label: 'High gf current alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 28;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_ground_fault_current_high_alarm_setpoint: {
    article: 'htc_0_ground_fault_current_high_alarm_setpoint',
    defaultValue: '20mA',
    group: 'Regs/Config/ACParameters/HighGFCurrentAlarmSetpoint',
    label: 'High gf current alarm setpoint',
    type: 'range',
    unit: 'mA',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 10;
        this.max = 500;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_ground_fault_current_high_latch: {
    article: 'htc_0_ground_fault_current_high_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/HighGroundFaultCurrent',
    label: 'High ground fault current Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_ground_fault_current_high_mask: {
    article: 'htc_0_ground_fault_current_high_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/HighGroundFaultCurrent',
    label: 'High ground fault current Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_ground_fault_current_highest_instantaneous_reset: {
    article: 'htc_0_ground_fault_current_highest_instantaneous_reset',
    defaultValue: null,
    group: 'Regs/Config/Maintenance/ClearMaxInstantaneousGroundFault',
    label: 'Clear max instantaneous ground fault',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_ground_fault_current_trip_setpoint: {
    article: 'htc_0_ground_fault_current_trip_setpoint',
    defaultValue: '30mA',
    group: 'Regs/Config/ACParameters/GroundFaultTripCurrentSetpoint',
    label: 'Ground fault trip current setpoint',
    type: 'range',
    unit: 'mA',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 10;
        this.max = 500;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_ground_fault_current_zero_offset: {
    article: 'htc_0_ground_fault_current_zero_offset',
    defaultValue: '3mA',
    group: 'Regs/Config/ACParameters/GroundFaultZeroOffset',
    label: 'Ground fault zero offset',
    type: 'range',
    unit: 'mA',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 100;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_ground_fault_trip_mask: {
    article: 'htc_0_ground_fault_trip_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/GroundFaultTrip',
    label: 'Ground fault trip Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_ground_fault_turns_ratio: {
    article: 'htc_0_ground_fault_turns_ratio',
    defaultValue: 1.0,
    group: 'Regs/Config/ACParameters/GroundFaultTurnsRatio',
    label: 'Ground fault turns ratio',
    type: 'range',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.1;
        this.max = 10.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_heater_configuration: {
    article: 'htc_0_heater_configuration',
    defaultValue: 'THREE_PHASE_WYE',
    group: 'Regs/Config/ACParameters/HeaterConfiguration',
    label: 'Heater configuration',
    options: [
      { key: 1, value: 'THREE_PHASE_WYE', displayText: 'THREE PHASE WYE' },
      { key: 2, value: 'THREE_PHASE_DELTA', displayText: 'THREE PHASE DELTA' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_heater_time_limit_exceeded_mask: {
    article: 'htc_0_heater_time_limit_exceeded_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/HeaterTime',
    label: 'Heater time Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_heater_time_reset: {
    article: 'htc_0_heater_time_reset',
    defaultValue: null,
    group: 'Regs/Config/Maintenance/ClearHeaterTime',
    label: 'Clear heater time',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_heater_time_setpoint: {
    article: 'htc_0_heater_time_setpoint',
    defaultValue: '250000h',
    group: 'Regs/Config/ACParameters/HeaterOnTimeAlarmSetpoint',
    label: 'Heater on time alarm setpoint',
    type: 'range',
    unit: 'h',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 250000;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_high_limit_cutout_latch: {
    article: 'htc_0_high_limit_cutout_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/HighLimitCutout',
    label: 'High limit cutout Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_high_limit_cutout_mask: {
    article: 'htc_0_high_limit_cutout_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/HighLimitCutout',
    label: 'High limit cutout Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_high_limit_cutout_setpoint: {
    article: 'htc_0_high_limit_cutout_setpoint',
    defaultValue: '700.0°C',
    group: 'Regs/Config/TemperatureSetup/HighLimitCutoutSetpoint',
    label: 'High limit cutout setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -200.0;
        let max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -328.0;
          max = 1292.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_line_current_0_high_alarm_filter: {
    article: 'htc_0_line_current_0_high_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/ACParameters/HighLineCurrentAlarmFilter/elem',
    label: 'Line current 0 high alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 28;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_line_current_0_high_alarm_setpoint: {
    article: 'htc_0_line_current_0_high_alarm_setpoint',
    defaultValue: '30.0A',
    group: 'Regs/Config/ACParameters/HighLineCurrentAlarmSetpoint/elem',
    label: 'Line current 0 high alarm setpoint',
    type: 'range',
    unit: 'A',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.1;
        this.max = 63.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_line_current_0_high_latch: {
    article: 'htc_0_line_current_0_high_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/HighLineCurrent/elem',
    label: 'Line current 0 high latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_line_current_0_high_mask: {
    article: 'htc_0_line_current_0_high_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/HighLineCurrent/elem',
    label: 'Line current 0 high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_line_current_0_low_alarm_filter: {
    article: 'htc_0_line_current_0_low_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/ACParameters/LowLineCurrentAlarmFilter/elem',
    label: 'Line current 0 low alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 28;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_line_current_0_low_alarm_setpoint: {
    article: 'htc_0_line_current_0_low_alarm_setpoint',
    defaultValue: '1.0A',
    group: 'Regs/Config/ACParameters/LowLineCurrentAlarmSetpoint/elem',
    label: 'Line current 0 low alarm setpoint',
    type: 'range',
    unit: 'A',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.1;
        this.max = 63.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_line_current_0_low_latch: {
    article: 'htc_0_line_current_0_low_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/LowLineCurrent/elem',
    label: 'Line current 0 low latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_line_current_0_low_mask: {
    article: 'htc_0_line_current_0_low_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/LowLineCurrent/elem',
    label: 'Line current 0 low mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_line_current_0_maximum: {
    article: 'htc_0_line_current_0_maximum',
    defaultValue: '60.0A',
    group: 'Regs/Config/ACParameters/MaximumCurrentLimitSetpoint',
    label: 'Maximum current limit setpoint',
    type: 'range',
    unit: 'A',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1.0;
        this.max = 100.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_line_current_1_high_alarm_filter: {
    article: 'htc_0_line_current_1_high_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/ACParameters/HighLineCurrentAlarmFilter/elem',
    label: 'Line current 1 high alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 28;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_line_current_1_high_alarm_setpoint: {
    article: 'htc_0_line_current_1_high_alarm_setpoint',
    defaultValue: '30.0A',
    group: 'Regs/Config/ACParameters/HighLineCurrentAlarmSetpoint/elem',
    label: 'Line current 1 high alarm setpoint',
    type: 'range',
    unit: 'A',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.1;
        this.max = 63.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_line_current_1_high_latch: {
    article: 'htc_0_line_current_1_high_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/HighLineCurrent/elem',
    label: 'Line current 1 high latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_line_current_1_high_mask: {
    article: 'htc_0_line_current_1_high_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/HighLineCurrent/elem',
    label: 'Line current 1 high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_line_current_1_low_alarm_filter: {
    article: 'htc_0_line_current_1_low_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/ACParameters/LowLineCurrentAlarmFilter/elem',
    label: 'Line current 1 low alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 28;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_line_current_1_low_alarm_setpoint: {
    article: 'htc_0_line_current_1_low_alarm_setpoint',
    defaultValue: '1.0A',
    group: 'Regs/Config/ACParameters/LowLineCurrentAlarmSetpoint/elem',
    label: 'Line current 1 low alarm setpoint',
    type: 'range',
    unit: 'A',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.1;
        this.max = 63.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_line_current_1_low_latch: {
    article: 'htc_0_line_current_1_low_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/LowLineCurrent/elem',
    label: 'Line current 1 low latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_line_current_1_low_mask: {
    article: 'htc_0_line_current_1_low_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/LowLineCurrent/elem',
    label: 'Line current 1 low mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_line_current_2_high_alarm_filter: {
    article: 'htc_0_line_current_2_high_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/ACParameters/HighLineCurrentAlarmFilter/elem',
    label: 'Line current 2 high alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 28;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_line_current_2_high_alarm_setpoint: {
    article: 'htc_0_line_current_2_high_alarm_setpoint',
    defaultValue: '30.0A',
    group: 'Regs/Config/ACParameters/HighLineCurrentAlarmSetpoint/elem',
    label: 'Line current 2 high alarm setpoint',
    type: 'range',
    unit: 'A',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.1;
        this.max = 63.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_line_current_2_high_latch: {
    article: 'htc_0_line_current_2_high_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/HighLineCurrent/elem',
    label: 'Line current 2 high latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_line_current_2_high_mask: {
    article: 'htc_0_line_current_2_high_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/HighLineCurrent/elem',
    label: 'Line current 2 high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_line_current_2_low_alarm_filter: {
    article: 'htc_0_line_current_2_low_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/ACParameters/LowLineCurrentAlarmFilter/elem',
    label: 'Line current 2 low alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 28;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_line_current_2_low_alarm_setpoint: {
    article: 'htc_0_line_current_2_low_alarm_setpoint',
    defaultValue: '1.0A',
    group: 'Regs/Config/ACParameters/LowLineCurrentAlarmSetpoint/elem',
    label: 'Line current 2 low alarm setpoint',
    type: 'range',
    unit: 'A',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.1;
        this.max = 63.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_line_current_2_low_latch: {
    article: 'htc_0_line_current_2_low_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/LowLineCurrent/elem',
    label: 'Line current 2 low latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_line_current_2_low_mask: {
    article: 'htc_0_line_current_2_low_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/LowLineCurrent/elem',
    label: 'Line current 2 low mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_line_voltage_high_latch: {
    article: 'htc_0_line_voltage_high_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/HighVoltage',
    label: 'High voltage Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_line_voltage_high_mask: {
    article: 'htc_0_line_voltage_high_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/HighVoltage',
    label: 'High voltage Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_line_voltage_low_latch: {
    article: 'htc_0_line_voltage_low_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/LowVoltage',
    label: 'Low voltage Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_line_voltage_low_mask: {
    article: 'htc_0_line_voltage_low_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/LowVoltage',
    label: 'Low voltage Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_load_shed_enabled: {
    article: 'htc_0_load_shed_enabled',
    defaultValue: false,
    group: 'Regs/Config/LoadShedding/LoadSheddingEnabled',
    label: 'Load shedding enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_load_shed_fail_safe_enabled: {
    article: 'htc_0_load_shed_fail_safe_enabled',
    defaultValue: false,
    group: 'Regs/Config/LoadShedding/LoadSheddingFailSafeEnabled',
    label: 'Load shedding fail safe enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_low_limit_cutout_deadband: {
    article: 'htc_0_low_limit_cutout_deadband',
    defaultValue: '3.0Δ°C',
    group: 'Regs/Config/TemperatureSetup/LowLimitDeadband',
    label: 'Low limit deadband',
    type: 'range',
    unit: 'Δ°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = 3.0;
        let max = 200.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = 5.4;
          max = 360.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_low_limit_cutout_latch: {
    article: 'htc_0_low_limit_cutout_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/LowLimitCutout',
    label: 'Low limit cutout Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_low_limit_cutout_mask: {
    article: 'htc_0_low_limit_cutout_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/LowLimitCutout',
    label: 'Low limit cutout Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_low_limit_cutout_setpoint: {
    article: 'htc_0_low_limit_cutout_setpoint',
    defaultValue: '-70.0°C',
    group: 'Regs/Config/TemperatureSetup/LowLimitCutoutSetpoint',
    label: 'Low limit cutout setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -200.0;
        let max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -328.0;
          max = 1292.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_output_0_limiting_latch: {
    article: 'htc_0_output_0_limiting_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/OutputLimiting',
    label: 'Output limiting Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_output_0_limiting_mask: {
    article: 'htc_0_output_0_limiting_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/OutputLimiting',
    label: 'Output limiting Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_output_limit_mode: {
    article: 'htc_0_output_limit_mode',
    defaultValue: 'DISABLE',
    group: 'Regs/Config/ControlParameters/OutputLimitMode',
    label: 'Output limit mode',
    options: [
      { key: 0, value: 'DISABLE', displayText: 'DISABLE' },
      { key: 1, value: 'POWER', displayText: 'POWER' },
      { key: 2, value: 'CURRENT', displayText: 'CURRENT' },
      { key: 3, value: 'PERCENTAGE', displayText: 'PERCENTAGE' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_output_limit_percentage_maximum: {
    article: 'htc_0_output_limit_percentage_maximum',
    defaultValue: '10%',
    group: 'Regs/Config/ACParameters/MaximumOutputLimitPercentage',
    label: 'Maximum output limit percentage',
    type: 'range',
    unit: '%',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 2;
        this.max = 100;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_output_switch_type: {
    article: 'htc_0_output_switch_type',
    defaultValue: 'CONTACTOR',
    group: 'Regs/Config/ControlParameters/OutputSwitchType',
    label: 'Output switch type',
    options: [
      { key: 0, value: 'CONTACTOR', displayText: 'CONTACTOR' },
      { key: 1, value: 'SSR', displayText: 'SSR' },
      { key: 2, value: 'ANALOG_SSR', displayText: 'ANALOG SSR' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_pasc_min_ambient_temperature: {
    article: 'htc_0_pasc_min_ambient_temperature',
    defaultValue: '-40.0°C',
    group: 'Regs/Config/ControlParameters/PASCMinAmbientTemp',
    label: 'Pasc min ambient temp',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -73.0;
        let max = 51.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -99.4;
          max = 123.8;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_pasc_min_pipe_size: {
    article: 'htc_0_pasc_min_pipe_size',
    defaultValue: 'SIZE_0_POINT_5_INCH',
    group: 'Regs/Config/ControlParameters/PASCMinPipeSize',
    label: 'Pasc min pipe size',
    options: [
      { key: 0, value: 'SIZE_0_POINT_5_INCH', displayText: 'SIZE 0 POINT 5 INCH' },
      { key: 1, value: 'SIZE_1_POINT_0_INCH', displayText: 'SIZE 1 POINT 0 INCH' },
      { key: 2, value: 'SIZE_2_POINT_0_INCH', displayText: 'SIZE 2 POINT 0 INCH' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_pasc_power_adjust: {
    article: 'htc_0_pasc_power_adjust',
    defaultValue: '100%',
    group: 'Regs/Config/ControlParameters/PASCPowerAdjust',
    label: 'Pasc power adjust',
    type: 'range',
    unit: '%',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 10;
        this.max = 200;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_power_0_maximum_limit_setpoint: {
    article: 'htc_0_power_0_maximum_limit_setpoint',
    defaultValue: '45.000kW',
    group: 'Regs/Config/ACParameters/MaximumPowerLimitSetpoint',
    label: 'Maximum power limit setpoint',
    type: 'range',
    unit: 'W',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1000;
        this.max = 45000;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_proportional_band: {
    article: 'htc_0_proportional_band',
    defaultValue: '2.0Δ°C',
    group: 'Regs/Config/ControlParameters/ProportionalBand',
    label: 'Proportional band',
    type: 'range',
    unit: 'Δ°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = 1.0;
        let max = 50.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = 1.8;
          max = 90.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_ssr_rating: {
    article: 'htc_0_ssr_rating',
    defaultValue: '30.0A',
    group: 'Regs/Config/ACParameters/SSRRating',
    label: 'Ssr rating',
    type: 'range',
    unit: 'A',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 20.0;
        this.max = 100.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_switch_0_limiting_latch: {
    article: 'htc_0_switch_0_limiting_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/SwitchLimiting',
    label: 'Switch limiting Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  } as Configurable,
  htc_0_switch_0_limiting_mask: {
    article: 'htc_0_switch_0_limiting_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/SwitchLimiting',
    label: 'Switch limiting Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_switch_control_mode: {
    article: 'htc_0_switch_control_mode',
    defaultValue: 'DEADBAND',
    group: 'Regs/Config/ControlParameters/SwitchControlMode',
    label: 'Switch control mode',
    options: [
      { key: 0, value: 'DEADBAND', displayText: 'DEADBAND' },
      { key: 1, value: 'PASC', displayText: 'PASC' },
      { key: 2, value: 'PROPORTIONAL', displayText: 'PROPORTIONAL' },
      { key: 3, value: 'ALWAYS_ON', displayText: 'ALWAYS ON' },
      { key: 4, value: 'ALWAYS_OFF', displayText: 'ALWAYS OFF' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_temperature_control_mode: {
    article: 'htc_0_temperature_control_mode',
    defaultValue: 'AVERAGE',
    group: 'Regs/Config/TemperatureSetup/TSControlMode',
    label: 'Ts control mode',
    options: [
      { key: 1, value: 'AVERAGE', displayText: 'AVERAGE' },
      { key: 2, value: 'LOWEST', displayText: 'LOWEST' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_temperature_fail_mode: {
    article: 'htc_0_temperature_fail_mode',
    defaultValue: 'FAIL_OFF',
    group: 'Regs/Config/TemperatureSetup/TSFailMode',
    label: 'Ts fail mode',
    options: [
      { key: 0, value: 'FAIL_OFF', displayText: 'FAIL OFF' },
      { key: 1, value: 'FAIL_ON', displayText: 'FAIL ON' },
      { key: 2, value: 'FIXED_PERCENTAGE', displayText: 'FIXED PERCENTAGE' },
      { key: 3, value: 'LAST_PERCENTAGE', displayText: 'LAST PERCENTAGE' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_temperature_fail_mode_percentage: {
    article: 'htc_0_temperature_fail_mode_percentage',
    defaultValue: '50%',
    group: 'Regs/Config/TemperatureSetup/TSFailModePercentage',
    label: 'Ts fail mode percentage',
    type: 'range',
    unit: '%',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 99;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_test_gfi: {
    article: 'htc_0_test_gfi',
    defaultValue: false,
    group: 'Regs/Config/ControlOptions/TestGFI',
    label: 'Test gfi',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_trace_current_0_highest_instantaneous_reset: {
    article: 'htc_0_trace_current_0_highest_instantaneous_reset',
    defaultValue: null,
    group: 'Regs/Config/Maintenance/ClearMaxInstantaneousTraceCurrent/elem',
    label: 'Trace current 0 highest instantaneous reset',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_trace_current_1_highest_instantaneous_reset: {
    article: 'htc_0_trace_current_1_highest_instantaneous_reset',
    defaultValue: null,
    group: 'Regs/Config/Maintenance/ClearMaxInstantaneousTraceCurrent/elem',
    label: 'Trace current 1 highest instantaneous reset',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_trace_current_2_highest_instantaneous_reset: {
    article: 'htc_0_trace_current_2_highest_instantaneous_reset',
    defaultValue: null,
    group: 'Regs/Config/Maintenance/ClearMaxInstantaneousTraceCurrent/elem',
    label: 'Trace current 2 highest instantaneous reset',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_tracing_resistance_0_high_alarm_filter: {
    article: 'htc_0_tracing_resistance_0_high_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/ACParameters/HighTraceResistanceAlarmFilter/elem',
    label: 'Tracing resistance 0 high alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 28;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_0_high_deviation: {
    article: 'htc_0_tracing_resistance_0_high_deviation',
    defaultValue: '50%',
    group: 'Regs/Config/ACParameters/HighTraceResistanceDeviation/elem',
    label: 'Tracing resistance 0 high deviation',
    type: 'range',
    unit: '%',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 250;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_0_high_latch: {
    article: 'htc_0_tracing_resistance_0_high_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/HighTracingResistance/elem',
    label: 'Tracing resistance 0 high latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_tracing_resistance_0_high_mask: {
    article: 'htc_0_tracing_resistance_0_high_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/HighTracingResistance/elem',
    label: 'Tracing resistance 0 high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_tracing_resistance_0_low_alarm_filter: {
    article: 'htc_0_tracing_resistance_0_low_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/ACParameters/LowTraceResistanceAlarmFilter/elem',
    label: 'Tracing resistance 0 low alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 28;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_0_low_deviation: {
    article: 'htc_0_tracing_resistance_0_low_deviation',
    defaultValue: '50%',
    group: 'Regs/Config/ACParameters/LowTraceResistanceDeviation/elem',
    label: 'Tracing resistance 0 low deviation',
    type: 'range',
    unit: '%',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 100;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_0_low_latch: {
    article: 'htc_0_tracing_resistance_0_low_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/LowTracingResistance/elem',
    label: 'Tracing resistance 0 low latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_tracing_resistance_0_low_mask: {
    article: 'htc_0_tracing_resistance_0_low_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/LowTracingResistance/elem',
    label: 'Tracing resistance 0 low mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_tracing_resistance_0_nominal: {
    article: 'htc_0_tracing_resistance_0_nominal',
    defaultValue: '6.00Ω',
    group: 'Regs/Config/ACParameters/NominalTraceResistance/elem',
    label: 'Tracing resistance 0 nominal',
    type: 'range',
    unit: 'Ω',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1.0;
        this.max = 3000.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_1_high_alarm_filter: {
    article: 'htc_0_tracing_resistance_1_high_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/ACParameters/HighTraceResistanceAlarmFilter/elem',
    label: 'Tracing resistance 1 high alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 28;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_1_high_deviation: {
    article: 'htc_0_tracing_resistance_1_high_deviation',
    defaultValue: '50%',
    group: 'Regs/Config/ACParameters/HighTraceResistanceDeviation/elem',
    label: 'Tracing resistance 1 high deviation',
    type: 'range',
    unit: '%',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 250;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_1_high_latch: {
    article: 'htc_0_tracing_resistance_1_high_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/HighTracingResistance/elem',
    label: 'Tracing resistance 1 high latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_tracing_resistance_1_high_mask: {
    article: 'htc_0_tracing_resistance_1_high_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/HighTracingResistance/elem',
    label: 'Tracing resistance 1 high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_tracing_resistance_1_low_alarm_filter: {
    article: 'htc_0_tracing_resistance_1_low_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/ACParameters/LowTraceResistanceAlarmFilter/elem',
    label: 'Tracing resistance 1 low alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 28;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_1_low_deviation: {
    article: 'htc_0_tracing_resistance_1_low_deviation',
    defaultValue: '50%',
    group: 'Regs/Config/ACParameters/LowTraceResistanceDeviation/elem',
    label: 'Tracing resistance 1 low deviation',
    type: 'range',
    unit: '%',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 100;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_1_low_latch: {
    article: 'htc_0_tracing_resistance_1_low_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/LowTracingResistance/elem',
    label: 'Tracing resistance 1 low latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_tracing_resistance_1_low_mask: {
    article: 'htc_0_tracing_resistance_1_low_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/LowTracingResistance/elem',
    label: 'Tracing resistance 1 low mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_tracing_resistance_1_nominal: {
    article: 'htc_0_tracing_resistance_1_nominal',
    defaultValue: '6.00Ω',
    group: 'Regs/Config/ACParameters/NominalTraceResistance/elem',
    label: 'Tracing resistance 1 nominal',
    type: 'range',
    unit: 'Ω',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1.0;
        this.max = 3000.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_2_high_alarm_filter: {
    article: 'htc_0_tracing_resistance_2_high_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/ACParameters/HighTraceResistanceAlarmFilter/elem',
    label: 'Tracing resistance 2 high alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 28;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_2_high_deviation: {
    article: 'htc_0_tracing_resistance_2_high_deviation',
    defaultValue: '50%',
    group: 'Regs/Config/ACParameters/HighTraceResistanceDeviation/elem',
    label: 'Tracing resistance 2 high deviation',
    type: 'range',
    unit: '%',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 250;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_2_high_latch: {
    article: 'htc_0_tracing_resistance_2_high_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/HighTracingResistance/elem',
    label: 'Tracing resistance 2 high latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_tracing_resistance_2_high_mask: {
    article: 'htc_0_tracing_resistance_2_high_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/HighTracingResistance/elem',
    label: 'Tracing resistance 2 high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_tracing_resistance_2_low_alarm_filter: {
    article: 'htc_0_tracing_resistance_2_low_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/ACParameters/LowTraceResistanceAlarmFilter/elem',
    label: 'Tracing resistance 2 low alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 28;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_2_low_deviation: {
    article: 'htc_0_tracing_resistance_2_low_deviation',
    defaultValue: '50%',
    group: 'Regs/Config/ACParameters/LowTraceResistanceDeviation/elem',
    label: 'Tracing resistance 2 low deviation',
    type: 'range',
    unit: '%',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 100;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_tracing_resistance_2_low_latch: {
    article: 'htc_0_tracing_resistance_2_low_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/LowTracingResistance/elem',
    label: 'Tracing resistance 2 low latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_tracing_resistance_2_low_mask: {
    article: 'htc_0_tracing_resistance_2_low_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/LowTracingResistance/elem',
    label: 'Tracing resistance 2 low mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_tracing_resistance_2_nominal: {
    article: 'htc_0_tracing_resistance_2_nominal',
    defaultValue: '6.00Ω',
    group: 'Regs/Config/ACParameters/NominalTraceResistance/elem',
    label: 'Tracing resistance 2 nominal',
    type: 'range',
    unit: 'Ω',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1.0;
        this.max = 3000.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_voltage_high_alarm_filter: {
    article: 'htc_0_voltage_high_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/ACParameters/HighVoltageAlarmFilter',
    label: 'High voltage alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 28;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_voltage_high_alarm_setpoint: {
    article: 'htc_0_voltage_high_alarm_setpoint',
    defaultValue: '270.0V',
    group: 'Regs/Config/ACParameters/HighVoltageAlarmSetpoint',
    label: 'High voltage alarm setpoint',
    type: 'range',
    unit: 'V',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 80.0;
        this.max = 300.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_voltage_low_alarm_filter: {
    article: 'htc_0_voltage_low_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/ACParameters/LowVoltageAlarmFilter',
    label: 'Low voltage alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 28;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_voltage_low_alarm_setpoint: {
    article: 'htc_0_voltage_low_alarm_setpoint',
    defaultValue: '90.0V',
    group: 'Regs/Config/ACParameters/LowVoltageAlarmSetpoint',
    label: 'Low voltage alarm setpoint',
    type: 'range',
    unit: 'V',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 80.0;
        this.max = 300.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  htc_0_voltage_measurement_configuration: {
    article: 'htc_0_voltage_measurement_configuration',
    defaultValue: 'LINE_TO_NEUTRAL',
    group: 'Regs/Config/ACParameters/VoltageMeasurementConfig',
    label: 'Voltage measurement config',
    options: [
      { key: 0, value: 'LINE_TO_NEUTRAL', displayText: 'LINE TO NEUTRAL' },
      { key: 1, value: 'LINE_TO_LINE', displayText: 'LINE TO LINE' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_voltage_min_max_values_reset: {
    article: 'htc_0_voltage_min_max_values_reset',
    defaultValue: null,
    group: 'Regs/Config/Maintenance/ClearMinMaxVoltage',
    label: 'Clear min max voltage',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  htc_0_voltage_turns_ratio: {
    article: 'htc_0_voltage_turns_ratio',
    defaultValue: 1.0,
    group: 'Regs/Config/ACParameters/VoltageTurnsRatio',
    label: 'Voltage turns ratio',
    type: 'range',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.1;
        this.max = 10.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  limiter_0_contactor_cycle_count_reset: {
    article: 'limiter_0_contactor_cycle_count_reset',
    defaultValue: null,
    group: 'Regs/Config/Maintenance/ClearLimiterCycleCount',
    label: 'Clear limiter cycle count',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  limiter_0_cutout_setpoint: {
    article: 'limiter_0_cutout_setpoint',
    defaultValue: '100.0°C',
    group: 'Regs/Config/ControlParameters/LimiterCutoutTemperatureSetpoint',
    label: 'Limiter cutout temperature setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = 50.0;
        let max = 600.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = 122.0;
          max = 1112.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  limiter_0_force_trip: {
    article: 'limiter_0_force_trip',
    defaultValue: null,
    group: 'Regs/Config/LimiterBits/TripLimiter',
    label: 'Trip limiter',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  limiter_0_intelligent_limiting_active: {
    article: 'limiter_0_intelligent_limiting_active',
    defaultValue: null,
    group: 'Regs/Config/LimiterBits/IntelligentLimiting',
    label: 'Intelligent limiting',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  limiter_0_reset: {
    article: 'limiter_0_reset',
    defaultValue: null,
    group: 'Regs/Config/LimiterBits/ResetTrippedLimiter',
    label: 'Reset tripped limiter',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  limiter_0_tag: {
    article: 'limiter_0_tag',
    defaultValue: 'NGC10 Limiter',
    group: 'Regs/Config/TemperatureSetup/LimiterTag',
    label: 'Limiter tag',
    type: 'string16',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.max = 40;
        this.min = 1;
        control.setValidators([Validators.maxLength(this.max), Validators.minLength(this.min)]);
      };
    },
  } as Configurable,
  limiter_0_temperature_sensor_failure_latch: {
    article: 'limiter_0_temperature_sensor_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/LimiterTemperatureSensorFailure',
    label: 'Limiter temperature sensor failure Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  limiter_0_temperature_sensor_failure_mask: {
    article: 'limiter_0_temperature_sensor_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/LimiterTemperatureSensorFailure',
    label: 'Limiter temperature sensor failure Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  limiter_0_temperature_sensor_min_max_values_reset: {
    article: 'limiter_0_temperature_sensor_min_max_values_reset',
    defaultValue: null,
    group: 'Regs/Config/Maintenance/ClearLimiterTemperatureSensorMinMaxTemperature',
    label: 'Clear limiter temperature sensor min max temperature',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  limiter_0_trip_latch: {
    article: 'limiter_0_trip_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/LimiterTripped',
    label: 'Limiter tripped Latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  limiter_0_trip_mask: {
    article: 'limiter_0_trip_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/LimiterTripped',
    label: 'Limiter tripped Mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  serial_0_baud_rate: {
    article: 'serial_0_baud_rate',
    defaultValue: 'BAUD_RATE_9600',
    group: 'Regs/Config/CommunicationParameters/SerPort/BaudRate',
    label: 'Baud rate',
    options: [
      { key: 0, value: 'BAUD_RATE_9600', displayText: 'BAUD RATE 9600' },
      { key: 1, value: 'BAUD_RATE_12900', displayText: 'BAUD RATE 12900' },
      { key: 2, value: 'BAUD_RATE_38400', displayText: 'BAUD RATE 38400' },
      { key: 3, value: 'BAUD_RATE_57600', displayText: 'BAUD RATE 57600' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  serial_0_modbus_address: {
    article: 'serial_0_modbus_address',
    defaultValue: 1,
    group: 'Regs/Config/CommunicationParameters/SerPort/ModbusAddress',
    label: 'Modbus address',
    type: 'int',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 247;
        return control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  serial_0_parity: {
    article: 'serial_0_parity',
    defaultValue: 'NONE',
    group: 'Regs/Config/CommunicationParameters/SerPort/Parity',
    label: 'Parity',
    options: [
      { key: 0, value: 'NONE', displayText: 'NONE' },
      { key: 1, value: 'ODD', displayText: 'ODD' },
      { key: 2, value: 'EVEN', displayText: 'EVEN' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  serial_0_stop_bits: {
    article: 'serial_0_stop_bits',
    defaultValue: 1,
    group: 'Regs/Config/CommunicationParameters/SerPort/StopBits',
    label: 'Stop bits',
    type: 'int',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 1;
        this.max = 2;
        return control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  serial_0_tx_delay: {
    article: 'serial_0_tx_delay',
    defaultValue: '20ms',
    group: 'Regs/Config/CommunicationParameters/SerPort/TxDelay',
    label: 'Tx delay',
    type: 'range',
    unit: 'ms',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 999;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_0_calibrated_high_value: {
    article: 'temperature_0_calibrated_high_value',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensor_20mA_Temperature/elem',
    label: 'Temperature 0 calibrated high value',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -270.0;
        let max = 1800.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -454.0;
          max = 3272.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_0_calibrated_low_value: {
    article: 'temperature_0_calibrated_low_value',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensor_4mA_Temperature/elem',
    label: 'Temperature 0 calibrated low value',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -270.0;
        let max = 1800.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -454.0;
          max = 3272.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_0_control_enabled: {
    article: 'temperature_0_control_enabled',
    defaultValue: false,
    group: 'Regs/Config/ControlOptions/UseTemperatureSensorForControl/elem',
    label: 'Temperature 0 control enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_0_failure_latch: {
    article: 'temperature_0_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/TemperatureSensorFailure/elem',
    label: 'Temperature 0 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_0_failure_mask: {
    article: 'temperature_0_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/TemperatureSensorFailure/elem',
    label: 'Temperature 0 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_0_high_alarm_filter: {
    article: 'temperature_0_high_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorHighAlarmFilter/elem',
    label: 'Temperature 0 high alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 59940;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_0_high_alarm_setpoint: {
    article: 'temperature_0_high_alarm_setpoint',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorHighAlarmSetpoint/elem',
    label: 'Temperature 0 high alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -200.0;
        let max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -328.0;
          max = 1292.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_0_high_cutout_enabled: {
    article: 'temperature_0_high_cutout_enabled',
    defaultValue: false,
    group: 'Regs/Config/ControlOptions/UseTemperatureSensorForHighLimitCutout/elem',
    label: 'Temperature 0 high cutout enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_0_high_latch: {
    article: 'temperature_0_high_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/TemperatureSensorHigh/elem',
    label: 'Temperature 0 high latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_0_high_mask: {
    article: 'temperature_0_high_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/TemperatureSensorHigh/elem',
    label: 'Temperature 0 high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_0_lead_resistance: {
    article: 'temperature_0_lead_resistance',
    defaultValue: '0.00Ω',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorLeadResistance/elem',
    label: 'Temperature 0 lead resistance',
    type: 'range',
    unit: 'Ω',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.0;
        this.max = 20.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_0_low_alarm_filter: {
    article: 'temperature_0_low_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorLowAlarmFilter/elem',
    label: 'Temperature 0 low alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 59940;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_0_low_alarm_setpoint: {
    article: 'temperature_0_low_alarm_setpoint',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorLowAlarmSetpoint/elem',
    label: 'Temperature 0 low alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -200.0;
        let max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -328.0;
          max = 1292.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_0_low_cutout_enabled: {
    article: 'temperature_0_low_cutout_enabled',
    defaultValue: false,
    group: 'Regs/Config/ControlOptions/UseTemperatureSensorForLowLimitCutout/elem',
    label: 'Temperature 0 low cutout enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_0_low_latch: {
    article: 'temperature_0_low_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/TemperatureSensorLow/elem',
    label: 'Temperature 0 low latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_0_low_mask: {
    article: 'temperature_0_low_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/TemperatureSensorLow/elem',
    label: 'Temperature 0 low mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_0_min_max_values_reset: {
    article: 'temperature_0_min_max_values_reset',
    defaultValue: null,
    group: 'Regs/Config/Maintenance/ClearTemperatureSensorMinMaxTemperature/elem',
    label: 'Temperature 0 min max values reset',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_0_tag: {
    article: 'temperature_0_tag',
    defaultValue: 'NGC20 RTD{index}-{Serial Number}',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorTag/elem',
    label: 'Temperature 0 tag',
    type: 'string16',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.max = 40;
        this.min = 1;
        control.setValidators([Validators.maxLength(this.max), Validators.minLength(this.min)]);
      };
    },
  } as Configurable,
  temperature_0_type: {
    article: 'temperature_0_type',
    defaultValue: 'PT_100',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorType/elem',
    label: 'Temperature 0 type',
    options: [
      { key: 0, value: 'PT_100', displayText: 'PT 100' },
      { key: 1, value: 'NI_FE_100', displayText: 'NI FE 100' },
      { key: 2, value: 'NI_100', displayText: 'NI 100' },
      { key: 3, value: '4_TO_20_MILLIAMP_LOOP', displayText: '4 TO 20 MILLIAMP LOOP' },
      { key: 4, value: 'NOT_USED', displayText: 'NOT USED' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_1_calibrated_high_value: {
    article: 'temperature_1_calibrated_high_value',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensor_20mA_Temperature/elem',
    label: 'Temperature 1 calibrated high value',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -270.0;
        let max = 1800.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -454.0;
          max = 3272.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_1_calibrated_low_value: {
    article: 'temperature_1_calibrated_low_value',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensor_4mA_Temperature/elem',
    label: 'Temperature 1 calibrated low value',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -270.0;
        let max = 1800.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -454.0;
          max = 3272.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_1_control_enabled: {
    article: 'temperature_1_control_enabled',
    defaultValue: false,
    group: 'Regs/Config/ControlOptions/UseTemperatureSensorForControl/elem',
    label: 'Temperature 1 control enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_1_failure_latch: {
    article: 'temperature_1_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/TemperatureSensorFailure/elem',
    label: 'Temperature 1 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_1_failure_mask: {
    article: 'temperature_1_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/TemperatureSensorFailure/elem',
    label: 'Temperature 1 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_1_high_alarm_filter: {
    article: 'temperature_1_high_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorHighAlarmFilter/elem',
    label: 'Temperature 1 high alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 59940;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_1_high_alarm_setpoint: {
    article: 'temperature_1_high_alarm_setpoint',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorHighAlarmSetpoint/elem',
    label: 'Temperature 1 high alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -200.0;
        let max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -328.0;
          max = 1292.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_1_high_cutout_enabled: {
    article: 'temperature_1_high_cutout_enabled',
    defaultValue: false,
    group: 'Regs/Config/ControlOptions/UseTemperatureSensorForHighLimitCutout/elem',
    label: 'Temperature 1 high cutout enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_1_high_latch: {
    article: 'temperature_1_high_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/TemperatureSensorHigh/elem',
    label: 'Temperature 1 high latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_1_high_mask: {
    article: 'temperature_1_high_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/TemperatureSensorHigh/elem',
    label: 'Temperature 1 high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_1_lead_resistance: {
    article: 'temperature_1_lead_resistance',
    defaultValue: '0.00Ω',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorLeadResistance/elem',
    label: 'Temperature 1 lead resistance',
    type: 'range',
    unit: 'Ω',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.0;
        this.max = 20.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_1_low_alarm_filter: {
    article: 'temperature_1_low_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorLowAlarmFilter/elem',
    label: 'Temperature 1 low alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 59940;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_1_low_alarm_setpoint: {
    article: 'temperature_1_low_alarm_setpoint',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorLowAlarmSetpoint/elem',
    label: 'Temperature 1 low alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -200.0;
        let max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -328.0;
          max = 1292.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_1_low_cutout_enabled: {
    article: 'temperature_1_low_cutout_enabled',
    defaultValue: false,
    group: 'Regs/Config/ControlOptions/UseTemperatureSensorForLowLimitCutout/elem',
    label: 'Temperature 1 low cutout enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_1_low_latch: {
    article: 'temperature_1_low_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/TemperatureSensorLow/elem',
    label: 'Temperature 1 low latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_1_low_mask: {
    article: 'temperature_1_low_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/TemperatureSensorLow/elem',
    label: 'Temperature 1 low mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_1_min_max_values_reset: {
    article: 'temperature_1_min_max_values_reset',
    defaultValue: null,
    group: 'Regs/Config/Maintenance/ClearTemperatureSensorMinMaxTemperature/elem',
    label: 'Temperature 1 min max values reset',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_1_tag: {
    article: 'temperature_1_tag',
    defaultValue: 'NGC20 RTD{index}-{Serial Number}',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorTag/elem',
    label: 'Temperature 1 tag',
    type: 'string16',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.min = 1;
        this.max = 40;
        control.setValidators([Validators.maxLength(this.max), Validators.minLength(this.min)]);
      };
    },
  } as Configurable,
  temperature_1_type: {
    article: 'temperature_1_type',
    defaultValue: 'PT_100',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorType/elem',
    label: 'Temperature 1 type',
    options: [
      { key: 0, value: 'PT_100', displayText: 'PT 100' },
      { key: 1, value: 'NI_FE_100', displayText: 'NI FE 100' },
      { key: 2, value: 'NI_100', displayText: 'NI 100' },
      { key: 3, value: '4_TO_20_MILLIAMP_LOOP', displayText: '4 TO 20 MILLIAMP LOOP' },
      { key: 4, value: 'NOT_USED', displayText: 'NOT USED' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_2_calibrated_high_value: {
    article: 'temperature_2_calibrated_high_value',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensor_20mA_Temperature/elem',
    label: 'Temperature 2 calibrated high value',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -270.0;
        let max = 1800.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -454.0;
          max = 3272.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_2_calibrated_low_value: {
    article: 'temperature_2_calibrated_low_value',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensor_4mA_Temperature/elem',
    label: 'Temperature 2 calibrated low value',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -270.0;
        let max = 1800.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -454.0;
          max = 3272.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_2_control_enabled: {
    article: 'temperature_2_control_enabled',
    defaultValue: false,
    group: 'Regs/Config/ControlOptions/UseTemperatureSensorForControl/elem',
    label: 'Temperature 2 control enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_2_failure_latch: {
    article: 'temperature_2_failure_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/TemperatureSensorFailure/elem',
    label: 'Temperature 2 failure latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_2_failure_mask: {
    article: 'temperature_2_failure_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/TemperatureSensorFailure/elem',
    label: 'Temperature 2 failure mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_2_high_alarm_filter: {
    article: 'temperature_2_high_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorHighAlarmFilter/elem',
    label: 'Temperature 2 high alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 59940;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_2_high_alarm_setpoint: {
    article: 'temperature_2_high_alarm_setpoint',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorHighAlarmSetpoint/elem',
    label: 'Temperature 2 high alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -200.0;
        let max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -328.0;
          max = 1292.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_2_high_cutout_enabled: {
    article: 'temperature_2_high_cutout_enabled',
    defaultValue: false,
    group: 'Regs/Config/ControlOptions/UseTemperatureSensorForHighLimitCutout/elem',
    label: 'Temperature 2 high cutout enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_2_high_latch: {
    article: 'temperature_2_high_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/TemperatureSensorHigh/elem',
    label: 'Temperature 2 high latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_2_high_mask: {
    article: 'temperature_2_high_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/TemperatureSensorHigh/elem',
    label: 'Temperature 2 high mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_2_lead_resistance: {
    article: 'temperature_2_lead_resistance',
    defaultValue: '0.00Ω',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorLeadResistance/elem',
    label: 'Temperature 2 lead resistance',
    type: 'range',
    unit: 'Ω',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0.0;
        this.max = 20.0;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_2_low_alarm_filter: {
    article: 'temperature_2_low_alarm_filter',
    defaultValue: '0s',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorLowAlarmFilter/elem',
    label: 'Temperature 2 low alarm filter',
    type: 'range',
    unit: 's',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        this.min = 0;
        this.max = 59940;

        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_2_low_alarm_setpoint: {
    article: 'temperature_2_low_alarm_setpoint',
    defaultValue: '100.0°C',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorLowAlarmSetpoint/elem',
    label: 'Temperature 2 low alarm setpoint',
    type: 'range',
    unit: '°C',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {
        let min = -200.0;
        let max = 700.0;
        if (ctx?.temperaturePreferenceUnit === 'Fahrenheit') {
          min = -328.0;
          max = 1292.0;
        }
        this.min = min;
        this.max = max;
        control.setValidators([Validators.required, Validators.min(this.min), Validators.max(this.max)]);
      };
    },
  } as Configurable,
  temperature_2_low_cutout_enabled: {
    article: 'temperature_2_low_cutout_enabled',
    defaultValue: false,
    group: 'Regs/Config/ControlOptions/UseTemperatureSensorForLowLimitCutout/elem',
    label: 'Temperature 2 low cutout enabled',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_2_low_latch: {
    article: 'temperature_2_low_latch',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Latch/TemperatureSensorLow/elem',
    label: 'Temperature 2 low latch',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_2_low_mask: {
    article: 'temperature_2_low_mask',
    defaultValue: true,
    group: 'Regs/Config/Alarm/Mask/TemperatureSensorLow/elem',
    label: 'Temperature 2 low mask',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_2_min_max_values_reset: {
    article: 'temperature_2_min_max_values_reset',
    defaultValue: null,
    group: 'Regs/Config/Maintenance/ClearTemperatureSensorMinMaxTemperature/elem',
    label: 'Temperature 2 min max values reset',
    type: 'bit',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
  temperature_2_tag: {
    article: 'temperature_2_tag',
    defaultValue: 'NGC20 RTD{index}-{Serial Number}',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorTag/elem',
    label: 'Temperature 2 tag',
    type: 'string16',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl): any | null => {
        this.max = 40;
        this.min = 1;
        control.setValidators([Validators.maxLength(this.max), Validators.minLength(this.min)]);
      };
    },
  } as Configurable,
  temperature_2_type: {
    article: 'temperature_2_type',
    defaultValue: 'PT_100',
    group: 'Regs/Config/TemperatureSetup/TemperatureSensorType/elem',
    label: 'Temperature 2 type',
    options: [
      { key: 0, value: 'PT_100', displayText: 'PT 100' },
      { key: 1, value: 'NI_FE_100', displayText: 'NI FE 100' },
      { key: 2, value: 'NI_100', displayText: 'NI 100' },
      { key: 3, value: '4_TO_20_MILLIAMP_LOOP', displayText: '4 TO 20 MILLIAMP LOOP' },
      { key: 4, value: 'NOT_USED', displayText: 'NOT USED' },
    ],
    type: 'choice',
    unit: '',
    validator(ctx?: Context) {
      return (control: AbstractControl) => {};
    },
  },
};
